import { FC } from 'react';
import styled from 'styled-components';
import { effectiveMobileMaxWidth } from './Constants';
import { ContestCountIndicator } from './ContestCountIndicator';

export type SeriesState = 'active' | 'complete';

export interface SeriesCardInfo {
  /// The name of the series, e.g. "Apollo x Centre"
  name: string;
  /// The caption string to display under the name, e.g. "a sneaker design competition"
  caption: string;
  /// Whether the little badge at the top says "Active" or "Complete"
  state: SeriesState;
  /// The info text that includes the date string plus additional info like prize details.
  infoText: string;
  /// The URL path to the background image
  backgroundImagePath: string;
  /// The number of contests in the series
  contestsCount: number;
  /// The number of contests in the series that have already completed
  contestsCompleted: number;
  /// The URL path to the primary icon, e.g. the Apollo icon
  primaryIconPath: string;
  /// An optional path to a secondary icon
  secondaryIconPath?: string;
  /// The background color hex string to show while the background image is loading
  backgroundColor: string;
}

export const SeriesCard: FC<SeriesCardInfo> = (props) => {
  const badgeText = props.state === 'active' ? 'Active' : 'Complete';
  return (
    <Container {...props}>
      <TopSection>
        <BadgeAndPartnersContainer>
          <StyledBadge {...props}>
            <h2>{badgeText}</h2>
          </StyledBadge>
          <PartnerIconsContainer>
            <img src={props.primaryIconPath} alt="Primary partner" />
            {props.secondaryIconPath && <img src={props.secondaryIconPath} alt="Secondary partner" />}
          </PartnerIconsContainer>
        </BadgeAndPartnersContainer>

        <NameAndCaption>
          <h1>{props.name}</h1>
          <h2>{props.caption}</h2>
        </NameAndCaption>
      </TopSection>
      <BottomSection>
        <h3>{props.infoText}</h3>
        <ContestCountIndicator {...props} />
      </BottomSection>
    </Container>
  );
};

// Styled components

interface ContainerProps {
  backgroundImagePath: string;
  backgroundColor: string;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  align-items: stretch;
  gap: 0;
  border-radius: 12px;
  overflow: hidden;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0.4) 100%), url(${(props) => props.backgroundImagePath}),
    ${(props) => props.backgroundColor};
  background-size: cover;

  width: 378px;
  height: 489px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    width: 292px;
    height: 416px;
  }
`;

// The section containing the background image, title, subtitle, etc
const TopSection = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  gap: 24px;

  padding: 32px 24px 16px 24px;
`;

// The section containing dates/prizes and the current contest indicator
const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  padding: 24px;

  background-color: #1f2020;

  h3 {
    margin: 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #ffffff;
  }
`;

const BadgeAndPartnersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
`;

const PartnerIconsContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 0;

  img {
    width: 48px;
    height: 48px;
  }

  img:first-of-type {
    z-index: 1;
    margin-left: -8px;
  }
`;

// Badge

interface StyledBadgeProps {
  state: SeriesState;
}

const StyledBadge = styled.div<StyledBadgeProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 10px;

  background-color: ${(props) => (props.state === 'active' ? '#0185E3' : '#ECFDF3')};
  border-radius: 100px;

  h2 {
    margin: 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: ${(props) => (props.state === 'active' ? '#ffffff' : '#027A48')};
  }
`;

const NameAndCaption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;

  h1 {
    margin: 0;
    font-weight: 800;
    font-size: 34px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #ffffff;
  }

  h2 {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 165%;
    color: #ffffff;
  }
`;
