import { FC, PropsWithChildren, useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { Formik, Form, Field } from 'formik';
import { useWeb3React } from '@web3-react/core';
import { useNavigate, useParams } from 'react-router-dom';
import ImageUploading, { ErrorsType, ImageListType, ImageType } from 'react-images-uploading';
import { PageInfoBanner } from '../PageInfoBanner';
import { effectiveMaxWidth, effectiveMobileMaxWidth, padding } from '../../shared/src/ui/Constants';
import { allCountries } from '../../helpers/countries';
import { DesktopProfileCardPreview, MobileProfileCardPreview } from '../ProfileCardPreview';
import { effectiveCreatorType, submitNominationForm } from '../../Api/submitNominationForm';
import { LabeledField, Pixels, textFieldCSS } from './LabeledField';
import { SocialLinks } from './SocialLinks';
import { useNominationFormDetails, initialFormValues, allCreatorTypes, FormDetails } from '../../hooks/useNominationFormDetails';
import { AppContext } from '../../hooks/context';
import { setProfileModal } from '../../hooks/state';
import { Account } from '../../Api/blockchain';
import { useAccountHasMinimumNominationBalance } from '../../hooks/useAccountHasMinimumBalance';
import { CallToActionBox } from '../CallToActionBox';
import { toast } from 'react-toastify';
import { ContextSpecificCTA } from '../ContextSpecificCTA';
import { useSeriesByApplyPath } from '../../hooks/useSeriesByApplyPath';

// Components

interface Props {
  editExistingNomination?: boolean;
  refreshQueuePosition(): void;
}

export const ApplyToContest: FC<Props> = ({ editExistingNomination, refreshQueuePosition }) => {
  document.title = 'Apply as a Creator - Apollo DAO Creator Contest';
  const isEditing = editExistingNomination ?? false;
  const { path } = useParams();
  const series = useSeriesByApplyPath(isEditing, path);

  const { account, active, library } = useWeb3React();
  const { dispatch } = useContext(AppContext);
  const presentModal = (name: string, address: string, profileURL?: string) => {
    dispatch(
      setProfileModal({
        creatorName: name,
        creatorProfilePicURL: profileURL,
        creatorAddress: address,
        type: 'nomination',
      })
    );
  };
  const navigate = useNavigate();
  const onSubmitForm = submitNominationForm({
    account,
    active,
    web3: library,
    navigate,
    isEditingProfile: isEditing,
    series,
    presentModal,
    refreshQueuePosition,
  });

  if (isEditing === false && series == null) {
    // Return early if we don't have a valid contest and we're not simply editing a profile
    return null;
  }

  const seriesName = series?.name ?? 'The Series';
  return (
    <>
      <PageInfoBanner
        topText={seriesName}
        titleText="Apply as a Creator"
        cta={<ContextSpecificCTA context="applying" series={series ?? undefined} />}
        caption={
          <>
            Join the upcoming Creators Contest, share your passion
            <br />
            and projects, and win funding to pursue what you love.
          </>
        }
      />

      <Formik initialValues={initialFormValues} onSubmit={onSubmitForm}>
        {({ isSubmitting, values, setFieldValue }) => (
          <FormContents values={values} isSubmitting={isSubmitting} account={account} isEditing={isEditing} setFieldValue={setFieldValue} />
        )}
      </Formik>
    </>
  );
};

interface FormContentsProps {
  values: FormDetails;
  isSubmitting: boolean;
  account: Account;
  isEditing?: boolean;
  setFieldValue(field: keyof FormDetails, value: any, shouldValidate?: boolean | undefined): void;
}

const FormContents: FC<FormContentsProps> = ({ values, isSubmitting, account, isEditing, setFieldValue }) => {
  useNominationFormDetails();
  const submitButtonDisabled = isSubmitting || (!isEditing && (!values.ageCheck || !values.engagementCheck || !values.termsCheck));
  const headerTopMargin: Pixels = isEditing ? '0px' : '80px';
  const [images, setImages] = useState<ImageType[]>([]);

  const onImageChange = (value: ImageListType) => {
    setImages(value);
    if (value.length > 0) {
      setFieldValue('profileImage', value[0]);
    }
  };

  return (
    <ImageUploading
      value={images}
      onChange={onImageChange}
      onError={showToastOnImageError}
      acceptType={['jpg', 'jpeg', 'png']}
      maxFileSize={2 * 1024 * 1024} // 2MB
    >
      {({ imageList, onImageUpload, isDragging, dragProps }) => (
        <BottomContainer>
          <CreatorDetailsForm>
            {!isEditing && <CallToActionBox context="applying" />}

            <GatedByWalletBalance account={account} isEditing={isEditing}>
              {(disabled) => (
                <DisabledFormSection disabled={disabled}>
                  <SectionHeader title="Build Your Profile" marginTop={headerTopMargin} subtitle={<BuildProfileSubtitle />} />

                  <LabeledField
                    label="Email"
                    captionNote="Note: your email address will not be shared on your profile"
                    type="email"
                    name="emailAddress"
                    placeholder="you@example.com"
                    disabled={disabled}
                  />

                  <ProfilePicUploadBox {...{ imageList, onImageUpload, isDragging, dragProps }} />

                  <MobileProfileCardPreview
                    name={values.name}
                    dataURL={imageList.length > 0 ? imageList[0].dataURL : undefined}
                    profilePictureURL={values.profilePictureURL}
                    creatorType={effectiveCreatorType(values)}
                  />

                  <LabeledField label="Full name" name="name" placeholder="Salvador Dali" disabled={disabled} />

                  <LabeledField
                    label="Country"
                    captionNote="Note: your country will not be shared publicly"
                    as="select"
                    name="country"
                    disabled={disabled}
                  >
                    <option disabled>Country</option>
                    {allCountries.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </LabeledField>

                  <CreatorTypes values={values} disabled={disabled} />

                  <LabeledField
                    label="Creator statement"
                    placeholder="Enter a creator statement..."
                    captionNote="Add one or two paragraphs about you as a creator"
                    as="textarea"
                    name="creatorStatement"
                    minHeight="168px"
                    disabled={disabled}
                  />

                  <LabeledField
                    label="How will you use the funds if you win?"
                    placeholder="If I win the funds will be used to..."
                    captionNote="Add one or two sentences about how you will use the prize funds"
                    as="textarea"
                    name="fundsUsagePlan"
                    minHeight="97px"
                    disabled={disabled}
                  />

                  <LabeledField
                    type="url"
                    label="Add a YouTube video (optional)"
                    labelCaption={
                      <>
                        Share a video talking about your work. Bonus points if you discuss Apollo and your involvement in the community.
                        <br />
                        <br />
                        You can complete this application without adding a YouTube video (as you may need time to create the video), but the
                        video is <i>required</i> to be entered into the contest.
                      </>
                    }
                    placeholder="https://youtu.be/dQw4w9WgXcQ"
                    name="youTubeVideoURL"
                    disabled={disabled}
                  />

                  <SectionHeader
                    title="Socials & Links"
                    subtitle="Provide any links to your website or social channels that demonstrate your work."
                    marginTop="80px"
                  />

                  <SocialLinks disabled={disabled} />

                  {!isEditing && (
                    <>
                      <SectionHeader
                        title="Agreement"
                        subtitle="Review the conditions required to submit an application."
                        marginTop="80px"
                      />
                      <AgreementCheckbox name="ageCheck" disabled={disabled}>
                        I am over 18-years-old, or have the express permission of my parent or gardian to participate.
                      </AgreementCheckbox>
                      <AgreementCheckbox name="engagementCheck" disabled={disabled}>
                        I agree to engage with the Apollo community in Discord and Telegram and promote the contest on my socials during the
                        contest.
                      </AgreementCheckbox>
                      <AgreementCheckbox name="termsCheck" disabled={disabled}>
                        I agree to the{' '}
                        <a href="https://www.apollocrypto.org/legal" target="_blank" rel="noreferrer">
                          Terms of Service
                        </a>
                      </AgreementCheckbox>
                    </>
                  )}
                </DisabledFormSection>
              )}
            </GatedByWalletBalance>

            <SubmitButton type="submit" disabled={submitButtonDisabled}>
              {isEditing ? 'Update Profile' : 'Submit Application'}
            </SubmitButton>
          </CreatorDetailsForm>

          <Spacer />

          <DesktopProfileCardPreview
            name={values.name}
            dataURL={imageList.length > 0 ? imageList[0].dataURL : undefined}
            profilePictureURL={values.profilePictureURL}
            creatorType={effectiveCreatorType(values)}
          />
        </BottomContainer>
      )}
    </ImageUploading>
  );
};

const BuildProfileSubtitle: FC = () => (
  <>
    The information you enter into your profile is the most important factor in your chance to win. It’s vital that you collect as much
    information as possible and fill out everything you can. Incomplete profiles won’t do well in the contest, so please take the time to
    produce a great profile. You can find{' '}
    <a
      href="https://docs.apollocrypto.org/apollo-docs/creator-contest/for-creators/how-to-apply-as-a-creator"
      target="_blank"
      rel="noreferrer"
    >
      help here
    </a>
    .
  </>
);

interface SectionHeaderProps {
  title: string;
  subtitle: string | JSX.Element;
  marginTop?: Pixels;
}

export const SectionHeader: FC<SectionHeaderProps> = ({ title, subtitle, ...props }) => (
  <StyledSectionHeader {...props}>
    <SectionHeaderTitle>{title}</SectionHeaderTitle>
    <SectionHeaderSubtitle>{subtitle}</SectionHeaderSubtitle>
    <SectionHeaderDivider />
  </StyledSectionHeader>
);

export const showToastOnImageError = (errors: ErrorsType): void => {
  if (errors?.acceptType) {
    toast.error('Unaccepted file type. Must be JPEG or PNG.');
  } else if (errors?.maxFileSize) {
    toast.error('Selected file is too large.');
  } else if (errors?.maxNumber) {
    toast.error('Too many files selected. Select only one file.');
  } else {
    toast.error('Invalid file');
  }
};

// Taken from react-images-uploading
interface DraggingProps {
  onDrop: (e: any) => void;
  onDragEnter: (e: any) => void;
  onDragLeave: (e: any) => void;
  onDragOver: (e: any) => void;
  onDragStart: (e: any) => void;
}

interface ProfilePicUploadBoxProps {
  imageList: ImageListType;
  onImageUpload(): void;
  isDragging: boolean;
  dragProps: DraggingProps;
}

const ProfilePicUploadBox: FC<ProfilePicUploadBoxProps> = ({ imageList, onImageUpload, isDragging, dragProps }) => {
  const buttonTitle = (imageList: ImageListType): string => {
    const defaultText = 'Choose photo';
    if (imageList.length > 0) {
      return imageList[0].file?.name ?? defaultText;
    } else {
      return defaultText;
    }
  };

  return (
    <StyledProfilePicUploadBox isDragging={isDragging} {...dragProps}>
      <h2>Profile Pic</h2>
      <p>Max file size: 2MB | Accepted: JPG or PNG</p>
      <ChoosePhotoButton type="button" isDragging={isDragging} onClick={onImageUpload}>
        {buttonTitle(imageList)}
      </ChoosePhotoButton>
    </StyledProfilePicUploadBox>
  );
};

interface CreatorTypesProps {
  values: FormDetails;
  disabled?: boolean;
}

const CreatorTypes: FC<CreatorTypesProps> = ({ values, disabled }) => (
  <>
    <LabeledField label="What type of creator are you?" as="select" name="creatorType" disabled={disabled}>
      <option disabled>Creator Type</option>
      {allCreatorTypes.map((creatorType) => (
        <option key={creatorType} value={creatorType}>
          {creatorType}
        </option>
      ))}
    </LabeledField>
    <RadioCustomTextField isShown={values.creatorType === 'Add custom text'} />
  </>
);

interface RadioCustomProps {
  isShown: boolean;
}

const TRANSITION_DURATION = 200;

const RadioCustomTextField: FC<RadioCustomProps> = ({ isShown }) => {
  const nodeRef = useRef(null);

  return (
    <CSSTransition nodeRef={nodeRef} in={isShown} timeout={TRANSITION_DURATION} classNames="custom" unmountOnExit>
      <StyledRadioCustomTextField innerRef={nodeRef} name="customCreatorType" placeholder="Custom" />
    </CSSTransition>
  );
};

interface AgreementCheckboxProps {
  name: string;
  disabled?: boolean;
}

const AgreementCheckbox: FC<PropsWithChildren<AgreementCheckboxProps>> = ({ name, disabled, children }) => (
  <StyledCheckbox>
    <Field type="checkbox" name={name} disabled={disabled} />
    <p>{children}</p>
  </StyledCheckbox>
);

interface GatedByWalletBalanceProps {
  account: Account;
  isEditing?: boolean;
  children: (disabled: boolean) => JSX.Element;
}

const GatedByWalletBalance: FC<GatedByWalletBalanceProps> = ({ account, isEditing, children }) => {
  const hasMinimumBalance = useAccountHasMinimumNominationBalance(account);
  const isFormDisabled = !isEditing && hasMinimumBalance === false;
  return children(isFormDisabled);
};

// Styled components

const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0;

  align-items: flex-start;
  margin: auto;
  max-width: ${effectiveMaxWidth()}px;
  padding: 85px ${padding}px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    flex-direction: column;
  }
`;

const Spacer = styled.div`
  max-width: 164px;
  min-width: 24px;
  flex-grow: 2;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    display: none;
  }
`;

const CreatorDetailsForm = styled(Form)`
  display: flex;
  flex-direction: column;
  max-width: 588px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    max-width: unset;
  }
`;

interface DisabledFormSectionProps {
  disabled?: boolean;
}

const DisabledFormSection = styled.div<DisabledFormSectionProps>`
  display: flex;
  flex-direction: column;
  ${(props) => (props.disabled ? 'opacity: 0.2;' : '')}
`;

const StyledSectionHeader = styled.div<Pick<SectionHeaderProps, 'marginTop'>>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  ${(props) => (props.marginTop ? `margin-top: ${props.marginTop}` : '')}
`;

const SectionHeaderTitle = styled.h1`
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #002106;
  margin: 0px 0px 16px 0px;
`;

const SectionHeaderSubtitle = styled.h2`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #72796f;
  margin: 0px 0px 32px 0px;

  a {
    color: inherit;
  }
`;

const SectionHeaderDivider = styled.div`
  background-color: #e1e5e8;
  height: 1px;
`;

const StyledField = styled(Field)`
  ${textFieldCSS}
`;

const StyledRadioCustomTextField = styled(StyledField)`
  margin-top: 16px;

  &.custom-enter {
    opacity: 0;
  }
  &.custom-enter-active {
    opacity: 1;
    transition: opacity ${TRANSITION_DURATION}ms;
  }
  &.custom-exit {
    opacity: 1;
  }
  &.custom-exit-active {
    opacity: 0;
    transition: opacity ${TRANSITION_DURATION}ms;
  }
`;

const StyledCheckbox = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-top: 40px;

  input[type='checkbox'] {
    flex-shrink: 0;
  }

  p {
    flex-shrink: 2;
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #344054;
  }

  a {
    color: #22bd46;
  }
`;

export const SubmitButton = styled.button`
  margin-top: 80px;
  width: 219px;
  height: 60px;
  border-radius: 3px;
  background-color: #68ff85;
  border: none;

  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #002106;
  transition: opacity 120ms ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.2;
    cursor: auto;
  }
`;

interface StyledProfilePicUploadBoxProps {
  isDragging: boolean;
}

const StyledProfilePicUploadBox = styled.div<StyledProfilePicUploadBoxProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 20px;
  margin-top: 32px;
  max-width: calc(100vw - ${padding}px * 2);

  background-color: #ffffff;
  border: 2px solid #d5e8d0;
  border-radius: 8px;
  opacity: ${(props) => (props.isDragging ? '0.6' : '1.0')};

  h2 {
    margin: 0;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    color: #002106;
    pointer-events: none;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #0f1f10;
    pointer-events: none;
  }
`;

interface ChoosePhotoButtonProps {
  isDragging: boolean;
}

const ChoosePhotoButton = styled.button<ChoosePhotoButtonProps>`
  pointer-events: ${(props) => (props.isDragging ? 'none' : 'initial')};
  max-width: calc(100% - 28px);

  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #002106;
  padding: 8px 14px;
  background-color: #68ff85;
  border-style: none;
  border-radius: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover,
  &:focus {
    opacity: 0.85;
    cursor: pointer;
  }
`;
