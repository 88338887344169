import { FC } from 'react';
import styled from 'styled-components';
import { effectiveMobileMaxWidth } from '../../shared/src/ui/Constants';
import { QueueInfo } from '../../hooks/useQueuePosition';
import { MobileNavBar } from './MobileNavBar';
import { NavBar } from './NavBar';

interface Props {
  queueInfo: QueueInfo;
}

export const DesktopOrMobileNavBar: FC<Props> = ({ queueInfo }) => (
  <>
    <ConditionalDesktopNavBar>
      <NavBar queueInfo={queueInfo} />
    </ConditionalDesktopNavBar>
    <ConditionalMobileNavBar>
      <MobileNavBar queueInfo={queueInfo} />
    </ConditionalMobileNavBar>
  </>
);

const ConditionalDesktopNavBar = styled.div`
  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    display: none;
  }
`;

const ConditionalMobileNavBar = styled.div`
  display: none;
  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    display: initial;
  }
`;
