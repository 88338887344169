import { FC, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { ShareContext, ShareURLs, shareURLsForContext } from '../../helpers/socialURLs';
import { Pixels } from '../ApplyToContest/LabeledField';
import { ReportModal } from './ReportModal';

import shareIcon from '../../svg/share.svg';
import shareMoreIcon from '../../svg/share-more.svg';
import copyLinkSVG from '../../svg/copy-link.svg';
import twitterSVG from '../../svg/text-fields/twitter.svg';
import facebookSVG from '../../svg/text-fields/facebook.svg';
import redditSVG from '../../svg/text-fields/reddit.svg';
import linkedInSVG from '../../svg/text-fields/linkedin.svg';
import { DropdownMenu, DropdownMenuContainer, DropdownMenuItem } from '../DropdownMenu';
import { DropdownInfo, useDropdownMenu } from '../../hooks/useDropdownMenu';

interface Props {
  context: ShareContext;
  rightAlignMenuOnDesktop?: boolean;
}

export const ShareButton: FC<Props> = ({ context, rightAlignMenuOnDesktop }) => {
  const primaryMenu = useDropdownMenu();
  const secondaryMenu = useDropdownMenu();
  const [isReportModalShown, setIsReportModalShown] = useState(false);
  const shareURLs = shareURLsForContext(context);

  return (
    <>
      {context.type === 'profile' && (
        <ReportModal
          creatorName={context.creatorName}
          creatorAddress={context.creatorAddress}
          isShown={isReportModalShown}
          shouldClose={() => setIsReportModalShown(false)}
        />
      )}

      <ShareContainer>
        <DropdownMenuContainer>
          <SharePrimaryButton ref={primaryMenu.buttonRef} onClick={() => primaryMenu.setIsDropdownShown(true)}>
            <ShareImage src={shareIcon} alt="Share" />
            Share
          </SharePrimaryButton>

          <ShareItemsMenu
            forwardRef={primaryMenu.menuRef}
            shareURLs={shareURLs}
            rightAlignOnDesktop={rightAlignMenuOnDesktop}
            isShown={primaryMenu.isDropdownShown}
            dismiss={() => primaryMenu.setIsDropdownShown(false)}
          />
        </DropdownMenuContainer>

        <SecondarySection
          context={context}
          secondaryMenu={secondaryMenu}
          rightAlignOnDesktop={rightAlignMenuOnDesktop}
          setIsReportModalShown={setIsReportModalShown}
        />
      </ShareContainer>
    </>
  );
};

interface ShareItemsMenuProps {
  forwardRef?: React.MutableRefObject<any>;
  shareURLs: ShareURLs;
  rightAlignOnDesktop?: boolean;
  dismiss(): void;
  isShown: boolean;
}

const ShareItemsMenu: FC<ShareItemsMenuProps> = ({ forwardRef, shareURLs, dismiss, rightAlignOnDesktop, ...props }) => {
  const rightAlignment: Pixels | undefined = rightAlignOnDesktop ? '-49px' : undefined;
  return (
    <DropdownMenu ref={forwardRef} rightAlignment={rightAlignment} unsetRightAlignmentOnMobile {...props}>
      <DropdownMenuItem imageSource={copyLinkSVG} text="Copy Link" dismiss={dismiss} onClick={() => copyLinkAndToast(shareURLs)} />
      <DropdownMenuItem imageSource={twitterSVG} text="Share on Twitter" url={shareURLs.twitterURL} dismiss={dismiss} />
      <DropdownMenuItem imageSource={facebookSVG} text="Share on Facebook" url={shareURLs.facebookURL} dismiss={dismiss} />
      <DropdownMenuItem imageSource={redditSVG} text="Share on Reddit" url={shareURLs.redditURL} dismiss={dismiss} />
      <DropdownMenuItem imageSource={linkedInSVG} text="Share on LinkedIn" url={shareURLs.linkedInURL} dismiss={dismiss} />
    </DropdownMenu>
  );
};

interface ReportMenuProps {
  forwardRef?: React.MutableRefObject<any>;
  rightAlignOnDesktop?: boolean;
  dismiss(): void;
  isShown: boolean;
  showReportMenu(): void;
}

const ReportMenu: FC<ReportMenuProps> = ({ forwardRef, dismiss, rightAlignOnDesktop, showReportMenu, ...props }) => {
  const rightAlignment: Pixels | undefined = rightAlignOnDesktop ? '0px' : undefined;
  return (
    <DropdownMenu ref={forwardRef} rightAlignment={rightAlignment} unsetRightAlignmentOnMobile {...props}>
      <DropdownMenuItem text="Report" dismiss={dismiss} onClick={showReportMenu} />
    </DropdownMenu>
  );
};

interface SecondaryProps {
  context: ShareContext;
  secondaryMenu: DropdownInfo;
  rightAlignOnDesktop?: boolean;
  setIsReportModalShown(isShown: boolean): void;
}

const SecondarySection: FC<SecondaryProps> = ({ context, secondaryMenu, rightAlignOnDesktop, setIsReportModalShown }) => {
  if (context.type === 'contest') {
    // Don't show the secondary button on the contest page
    return null;
  }

  return (
    <>
      <ShareDivider />

      <DropdownMenuContainer>
        <ShareSecondaryButton
          ref={secondaryMenu.buttonRef}
          onClick={createOnClickFunctionForSecondaryButton(context, secondaryMenu.setIsDropdownShown)}
        >
          <img src={shareMoreIcon} alt="More options" />
        </ShareSecondaryButton>

        <ReportMenu
          forwardRef={secondaryMenu.menuRef}
          rightAlignOnDesktop={rightAlignOnDesktop}
          isShown={secondaryMenu.isDropdownShown}
          dismiss={() => secondaryMenu.setIsDropdownShown(false)}
          showReportMenu={() => setIsReportModalShown(true)}
        />
      </DropdownMenuContainer>
    </>
  );
};

// Helper functions

const createOnClickFunctionForSecondaryButton = (context: ShareContext, setIsSecondaryDropdownShown: (isShown: boolean) => void) => () => {
  switch (context.type) {
    case 'profile':
      setIsSecondaryDropdownShown(true);
      break;
    case 'contest':
      break;
  }
};

const copyLinkAndToast = (shareURLs: ShareURLs) => {
  navigator.clipboard.writeText(shareURLs.copyLinkURL);
  toast.success('Copied Link');
};

// Styled components

const ShareContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border: 1px solid #e1e5e8;
  border-radius: 4px;
`;

const SharePrimaryButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  background: none;
  font-weight: 500;
  font-size: 15px;
  color: #002106;
  padding-left: 0px;
  padding-right: 12px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  &:hover,
  &:focus {
    background-color: #f2f4f7;
    cursor: pointer;
  }
`;

const ShareSecondaryButton = styled.button`
  border: none;
  background: none;
  padding: 8px 12px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  &:hover,
  &:focus {
    background-color: #f2f4f7;
    cursor: pointer;
  }
`;

const ShareImage = styled.img`
  padding: 0px;
  margin: 8px 12px;
`;

const ShareDivider = styled.div`
  background: #e1e5e8;
  width: 1px;
`;
