import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { PastContest, PastContestant } from '../../shared/src/PastContest';
import { effectiveMobileMaxWidth } from '../../shared/src/ui/Constants';
import { AnalyticEvents, clickedIPFSEvent, clickedTXEvent, trackEvent } from '../../hooks/analytics';

const etherscanBaseURL = process.env.REACT_APP_ETHERSCAN_BASE_URL;

// Function components

export const PastContestRow: FC<PastContest> = (summary) => {
  const navigate = useNavigate();
  return (
    <Container onClick={() => navigate(`/contests/${summary.contestNumber}`)}>
      <TitleBox {...summary} />
      <StatsContainer {...summary} />
    </Container>
  );
};

// This set contains the list of contest numbers that have an associated POAP
// image stored in the public folder. Update this list as new images are added.
const contestsWithImages = new Set([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]);

const TitleBox: FC<PastContest> = ({ name, contestNumber }) => {
  const imageSrc = contestsWithImages.has(contestNumber) ? `/poap/contest-${contestNumber}.png` : null;
  const title = name ?? `Contest #${contestNumber}`;
  return (
    <StyledTitleBox>
      {imageSrc != null && <TitleBoxImage src={imageSrc} alt="POAP" />}
      <TitleBoxLabelStack>
        <h1>{title}</h1>
        <h2>Complete</h2>
      </TitleBoxLabelStack>
    </StyledTitleBox>
  );
};

const StatsContainer: FC<PastContest> = ({ cycleId, votersCount, votePoints, summaryIPFSPath, completeCycleTxHash, contestants }) => {
  const votersTitle = votePoints != null ? 'Vote Points' : 'Voters';
  const count = votePoints ?? votersCount;
  return (
    <StyledStatsContainer>
      <SingleStatBox label="Ended" value={contestEndedDateString(cycleId)} />
      <SingleStatBox label={votersTitle} value={count.toLocaleString()} minWidth="50px" />
      {summaryIPFSPath && <SingleStatBox {...votingSummaryStats(summaryIPFSPath)} />}
      <SingleStatBox {...contestCycleStats(completeCycleTxHash)} />
      <SingleStatBox label="Winner" value={getWinnerName(contestants)} caption={winnerVoteCountString(contestants)} width="150px" />
    </StyledStatsContainer>
  );
};

type Pixels = `${number}px`;

interface StatProps {
  label: string;
  value: string;
  action?: StatAction;
  caption?: string;
  width?: Pixels;
  minWidth?: Pixels;
}

interface StatAction {
  valueURL: string;
  analyticEvent: AnalyticEvents;
}

export const SingleStatBox: FC<StatProps> = ({ label, caption, width, minWidth, ...rest }) => (
  <StyledSingleStatBox width={width} minWidth={minWidth}>
    <h3>{label}</h3>
    <ValueLabelOrLink {...rest} />
    <p>{caption ?? ''}</p>
  </StyledSingleStatBox>
);

type ValueLabelOrLinkProps = Pick<StatProps, 'value' | 'action'>;

export const ValueLabelOrLink: FC<ValueLabelOrLinkProps> = ({ value, action }) => {
  if (action != null) {
    return (
      <a href={action.valueURL} onClick={() => trackEvent(action.analyticEvent)} target="_blank" rel="noreferrer">
        {value}
      </a>
    );
  } else {
    return <h4>{value}</h4>;
  }
};

// Helper functions

export const votingSummaryStats = (summaryIPFSPath: string): StatProps => {
  return {
    label: 'Voting Summary',
    value: summaryIPFSPath,
    caption: 'IPFS',
    action: {
      valueURL: `https://ipfs.io/ipfs/${summaryIPFSPath}`,
      analyticEvent: clickedIPFSEvent(),
    },
  };
};

export const contestCycleStats = (completeCycleTxHash: string): StatProps => {
  return {
    label: 'Contest Cycle',
    value: completeCycleTxHash,
    caption: 'Ethereum',
    action: {
      valueURL: `${etherscanBaseURL}/tx/${completeCycleTxHash}`,
      analyticEvent: clickedTXEvent(),
    },
  };
};

export const contestEndedDateString = (cycleId: number): string => {
  const date = new Date(cycleId * 1000);
  return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
};

export const getWinnerName = (contestants: PastContestant[]): string => (contestants.length > 0 ? contestants[0].name : 'Unknown');

export const winnerVoteCountString = (contestants: PastContestant[]): string => {
  const votes = contestants.length > 0 && contestants[0].votePoints > 0 ? contestants[0].votePoints : 0;
  return votes == 1 ? '1 point' : `${votes.toLocaleString()} points`;
};

// Styled components

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px;

  background: #ffffff;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    // Hide the whole cell on mobile
    display: none;
  }
`;

const StyledTitleBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const TitleBoxImage = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 50%;
`;

const TitleBoxLabelStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #002106;
    margin: 0;
  }

  h2 {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    padding: 2px 8px;
    color: #344054;
    background-color: #f2f4f7;
    border-radius: 16px;
  }
`;

// The container of all stats for a past contest
const StyledStatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 44px;
  margin-right: 16px;
`;

interface StyledSingleStatBoxProps {
  width?: Pixels;
  minWidth?: Pixels;
}

// A box containing a vertical stack of labels describing a single stat
const StyledSingleStatBox = styled.div<StyledSingleStatBoxProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.minWidth && `min-width: ${props.minWidth};`}

  h3 {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.4);
    margin: 0;
  }

  h4,
  a {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    max-width: 150px;
    color: #002106;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.4);
    margin: 0;
  }
`;
