import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { ContestsResponse, ContestWithCreators } from '../shared/src/ContestSummary';
import { APIResponse } from '../shared/src/APIResponse';
import { WithId } from '../shared/src/WithId';

const apiBaseURL = process.env.REACT_APP_BASE_URL;

export type ContestsResponseWithId = Omit<ContestsResponse, 'activeContest'> & {
  activeContest?: WithId<ContestWithCreators>;
};

/// Fetch the current state of contests, both active and in the past
export const useContests = (prefetched?: ContestsResponseWithId): ContestsResponseWithId | null => {
  const [response, setResponse] = useState<ContestsResponseWithId | null>(prefetched ?? null);
  useEffect(() => {
    if (prefetched == null) {
      // Only fetch the response if it is not already prefetched
      fetchContestsResponse().then(setResponse);
    }
  }, []);
  return response;
};

const fetchContestsResponse = async (): Promise<ContestsResponseWithId | null> => {
  try {
    const res: AxiosResponse<APIResponse<ContestsResponseWithId>, any> = await axios.get(`${apiBaseURL}/contests/summary`);
    return res.data.data;
  } catch (err) {
    console.error(`Error occurred while fetching contests: ${err}`);
    return null;
  }
};
