export type ShareContextType = 'contest' | 'profile' | 'nomination';

export interface ContestShareContext {
  type: 'contest';
  copyLinkURL: string;
}

export interface ProfileShareContext {
  type: 'profile' | 'nomination';
  copyLinkURL: string;
  creatorAddress: string;
  creatorName: string;
}

export type ShareContext = ContestShareContext | ProfileShareContext;

export interface ShareURLs {
  copyLinkURL: string;
  twitterURL: string;
  facebookURL: string;
  redditURL: string;
  linkedInURL: string;
}

export const shareURLsForContext = (context: ShareContext): ShareURLs => {
  switch (context.type) {
    case 'contest':
      return {
        copyLinkURL: context.copyLinkURL,
        twitterURL: twitterShareURLForContest(),
        facebookURL: facebookShareURLForContest(),
        redditURL: redditShareURLForContest(),
        linkedInURL: linkedInShareURLForContest(),
      };
    case 'profile':
      return {
        copyLinkURL: context.copyLinkURL,
        twitterURL: twitterShareURLForProfile(context),
        facebookURL: facebookShareURLForProfile(context),
        redditURL: redditShareURLForProfile(context),
        linkedInURL: linkedInShareURLForProfile(context),
      };
    case 'nomination':
      return {
        copyLinkURL: context.copyLinkURL,
        twitterURL: twitterShareURLForNomination(context),
        facebookURL: facebookShareURLForProfile(context),
        redditURL: redditShareURLForNomination(context),
        linkedInURL: linkedInShareURLForProfile(context),
      };
  }
};

const baseURL = process.env.REACT_APP_SITE_BASE_URL;

// Sharing from Profile

const twitterShareURLForProfile = ({ creatorAddress, creatorName }: ProfileShareContext): string => {
  const fullURL = `${baseURL}/profile/${creatorAddress}`;
  const tweetText = `Hey everyone, check out ${creatorName}. Consider voting for them to win funds to realize their dreams. ${fullURL}`;
  return twitterURL(tweetText);
};

const redditShareURLForProfile = ({ creatorAddress, creatorName }: ProfileShareContext): string => {
  const fullURL = `${baseURL}/profile/${creatorAddress}`;
  const titleText = `Check out ${creatorName}. Consider voting for them to win funds to realize their dreams.`;
  return redditURL(fullURL, titleText);
};

const facebookShareURLForProfile = ({ creatorAddress }: ProfileShareContext): string => {
  const fullURL = `${baseURL}/profile/${creatorAddress}`;
  return facebookURL(fullURL);
};

const linkedInShareURLForProfile = ({ creatorAddress }: ProfileShareContext): string => {
  const fullURL = `${baseURL}/profile/${creatorAddress}`;
  return linkedInURL(fullURL);
};

// Sharing from Nomination

const twitterShareURLForNomination = ({ creatorAddress }: ProfileShareContext): string => {
  const fullURL = `${baseURL}/profile/${creatorAddress}`;
  const tweetText = `Hey everyone, check out my Apollo profile. Consider voting for me to win funds to realize their dreams. ${fullURL}`;
  return twitterURL(tweetText);
};

const redditShareURLForNomination = ({ creatorAddress }: ProfileShareContext): string => {
  const fullURL = `${baseURL}/profile/${creatorAddress}`;
  const titleText = `Check me out! Consider voting for them to win funds to realize their dreams.`;
  return redditURL(fullURL, titleText);
};

// Sharing from contest

const twitterShareURLForContest = (): string => {
  const tweetText = `Check out the nominees for the current contest on Apollo! ${baseURL}`;
  return twitterURL(tweetText);
};

const facebookShareURLForContest = (): string => facebookURL(baseURL as string);

const redditShareURLForContest = (): string => {
  const titleText = 'Check out the nominees for the current contest on Apollo!';
  return redditURL(baseURL as string, titleText);
};

const linkedInShareURLForContest = (): string => linkedInURL(baseURL as string);

// Helpers

const twitterURL = (text: string): string => `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`;
const facebookURL = (url: string) => `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
const redditURL = (url: string, title: string) =>
  `https://www.reddit.com/submit?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`;
const linkedInURL = (url: string) => `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;
