import { FC } from 'react';

interface Props {
  colorHex: string;
}

export const WinnerStar: FC<Props> = ({ colorHex }) => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.0532 1.52566C20.2564 0.824782 21.7435 0.824781 22.9468 1.52566L27.4239 4.13353C27.7246 4.30868 28.0475 4.44245 28.384 4.53122L33.3939 5.85297C34.7403 6.20819 35.7918 7.25974 36.147 8.60615L37.4688 13.616C37.5576 13.9525 37.6913 14.2754 37.8665 14.5761L40.4743 19.0532C41.1752 20.2564 41.1752 21.7435 40.4743 22.9468L37.8665 27.4239C37.6913 27.7246 37.5575 28.0475 37.4688 28.384L36.147 33.3939C35.7918 34.7403 34.7403 35.7918 33.3939 36.147L28.384 37.4688C28.0475 37.5576 27.7246 37.6913 27.4239 37.8665L22.9468 40.4743C21.7436 41.1752 20.2564 41.1752 19.0532 40.4743L14.5761 37.8665C14.2754 37.6913 13.9525 37.5575 13.616 37.4688L8.60615 36.147C7.25974 35.7918 6.20819 34.7403 5.85297 33.3939L4.53122 28.384C4.44245 28.0475 4.30868 27.7246 4.13353 27.4239L1.52566 22.9468C0.824782 21.7436 0.824781 20.2564 1.52566 19.0532L4.13353 14.5761C4.30868 14.2754 4.44245 13.9525 4.53122 13.616L5.85297 8.60615C6.20819 7.25974 7.25974 6.20819 8.60615 5.85297L13.616 4.53122C13.9525 4.44245 14.2754 4.30868 14.5761 4.13353L19.0532 1.52566Z"
      stroke={colorHex}
    />
  </svg>
);
