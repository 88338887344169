import Web3 from 'web3';

/// The "domain" of the typed data, which differentiates it from a payload
/// with an identical "message"
export interface EIP712Domain {
  name?: string;
  version?: string;
  chainId?: number;
  verifyingContract?: string;
  salt?: string;
}

type SignerType = 'string' | 'address' | 'uint256';

/// Used to indicate the names and types of data included in the message
export interface EIP712SignerType<T> {
  name: keyof T;
  type: SignerType;
}

/// The full payload meeting the EIP-712 typed data specification
export interface EIP712TypedDataV4<T> {
  domain: EIP712Domain;
  types: { [key: string]: EIP712SignerType<any>[] };
  primaryType: string;
  message: T;
}

export type Signature = string;
type Account = string;

/// The chain ID declared by the frontend or backend.
const chainId = (): number => {
  const frontend = process.env.REACT_APP_CHAIN_ID;
  const backend = process.env.CHAIN_ID;
  if (frontend != null) {
    return parseInt(frontend);
  } else if (backend != null) {
    return parseInt(backend);
  } else {
    throw new Error('Failed to determine chain Id');
  }
};

export const apolloTypedDataDomain: EIP712Domain = {
  name: 'Apollo DAO',
  version: '1',
  chainId: chainId(),
};

/// Sign an arbitrary message using EIP-712
export const signMessage = async <T>(
  signer: Account,
  message: T,
  types: EIP712SignerType<T>[],
  primaryType: string,
  web3: Web3
): Promise<Signature> => {
  const typedData: EIP712TypedDataV4<T> = {
    types: {
      EIP712Domain: domainTypes,
    },
    domain: apolloTypedDataDomain,
    primaryType,
    message,
  };
  typedData.types[primaryType] = types;

  const jsonPayload = JSON.stringify(typedData);
  const provider = web3.currentProvider as Provider;
  const signature = await provider.request({
    method: 'eth_signTypedData_v4',
    params: [signer, jsonPayload],
  });

  return signature;
};

// MARK: - Private helpers

interface RequestArguments {
  method: string;
  params?: unknown[] | unknown;
}

interface Provider {
  request(args: RequestArguments): Promise<string>;
}

const domainTypes: EIP712SignerType<EIP712Domain>[] = [
  { name: 'name', type: 'string' },
  { name: 'version', type: 'string' },
  { name: 'chainId', type: 'uint256' },
];
