import axios, { AxiosResponse } from 'axios';
import { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { HeaderLabelStack } from './HeaderLabelStack';
import { effectiveMaxWidth, effectiveMobileMaxWidth, padding } from '../shared/src/ui/Constants';
import { determineVotePoints, VoteRecord } from '../shared/src/VoteRecord';
import { toast } from 'react-toastify';
import { clickedIPFSEvent, trackEvent } from '../hooks/analytics';
import { WithId } from '../shared/src/WithId';
import { APIResponse } from '../shared/src/APIResponse';
import { VoteContext } from './Voting/VoteContextProvider';
import avatarSVG from '../svg/avatar.svg';

const baseURL = process.env.REACT_APP_BASE_URL;

/// Used in table width calculations
const numberOfColumns = 4;

interface Props {
  /// An optional cycle ID. When provided, fetch the votes for this cycle
  /// instead of the current cycle.
  cycleId?: number;
}

export const VotesSection: FC<Props> = ({ cycleId }) => {
  const [votes, setVotes] = useState<WithId<VoteRecord>[]>([]);
  const { didUpdateVotes } = useContext(VoteContext);

  useEffect(() => {
    fetchVotes(cycleId).then(setVotes);
  }, [didUpdateVotes]);

  return (
    <Content>
      <HeaderLabelStack title="Votes" subtitle={subtitleText(votes)} />
      <TableContainer>
        <VotesTable>
          <VoteHeader />
          <tbody>
            {votes.map((record, index) => (
              <VoteRow key={record._id} record={record} index={index} />
            ))}
          </tbody>
        </VotesTable>
      </TableContainer>
    </Content>
  );
};

const VoteHeader: FC = () => (
  <thead>
    <tr>
      <th>Voter</th>
      <th>Transaction</th>
      <th>Placed</th>
      <th>Vote Points</th>
    </tr>
  </thead>
);

interface VoteRowProps {
  index: number;
  record: VoteRecord;
}

const VoteRow: FC<VoteRowProps> = ({ index, record }) => {
  const ipfsURL = `https://ipfs.io/ipfs/${record.ipfsPath}`;

  return (
    <StyledRow index={index}>
      <VoterLabel>
        <StyledVoterCell>
          <img src={avatarSVG} alt="Avatar" />
          <p>{voterStringFromRecord(record)}</p>
        </StyledVoterCell>
      </VoterLabel>
      <TransactionCell>
        <TransactionLabel>
          <a href={ipfsURL} onClick={() => trackEvent(clickedIPFSEvent())} target="_blank" rel="noreferrer">
            {record.ipfsPath}
          </a>
        </TransactionLabel>
      </TransactionCell>
      <PlacedAndPointsLabel>{formattedDate(record.voteDate)}</PlacedAndPointsLabel>
      <PlacedAndPointsLabel>{determineVotePoints(record)}</PlacedAndPointsLabel>
    </StyledRow>
  );
};

// Helper functions

const subtitleText = (votes: VoteRecord[]) => {
  const totalPoints = votes.reduce((result, vote) => result + determineVotePoints(vote), 0);
  switch (totalPoints) {
    case 1:
      return '1 vote point submitted';
    default:
      return `${totalPoints} vote points submitted`;
  }
};

const formattedDate = (timestamp: number): string => {
  const date = new Date(timestamp * 1000);
  const dateString = date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  const timeString = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
  return `${dateString} at ${timeString}`;
};

const fetchVotes = async (cycleId?: number): Promise<WithId<VoteRecord>[]> => {
  try {
    let url = `${baseURL}/votes/`;
    if (cycleId != null) {
      url += `?cycleId=${cycleId}`;
    }

    const res: AxiosResponse<APIResponse<WithId<VoteRecord>[]>, any> = await axios.get(url);
    return res.data.data;
  } catch (err) {
    console.error(`Error occurred fetching votes: ${err}`);
    toast.error('Failed to fetch votes');
    return [];
  }
};

const voterStringFromRecord = (voteRecord: VoteRecord): string => {
  switch (voteRecord.type) {
    case 'wallet':
    case undefined:
      return voteRecord.voter;
    case 'email':
      return voteRecord.voterEmail;
    default:
      // This should not happen
      return 'Unknown voter';
  }
};

// Styled components

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: auto;
  max-width: ${effectiveMaxWidth()}px;
  padding: 80px ${padding}px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    padding: 16px ${padding}px;
  }
`;

const TableContainer = styled.div`
  padding: 40px;
  margin-top: 24px;
  width: 100%;
  background-color: #ffffff;

  border: 1px solid #e1e5e8;
  border-radius: 8px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    padding: 16px;
    display: block;
    overflow-x: scroll;
  }
`;

const VotesTable = styled.table`
  background-color: #ffffff;
  display: table;
  table-layout: fixed;
  text-align: left;
  line-height: 30px;
  width: 100%;
  border-collapse: collapse;

  // Gap between header and body rows
  tbody:before {
    line-height: 10px;
    content: '-';
    color: transparent; /* to hide text */
    display: block;
  }

  th,
  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th {
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #999999;
  }

  td {
    padding-right: 20px;
  }

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    width: unset;

    tbody:before {
      line-height: 8px;
    }

    th,
    td {
      max-width: ${100 / numberOfColumns}vw;
      min-width: 100px;
    }

    td {
      font-size: 12px;
    }
  }
`;

interface StyledRowProps {
  index: number;
}

const StyledRow = styled.tr<StyledRowProps>`
  padding: 8px 12px;
  height: 41px;
  background-color: ${(props) => (props.index % 2 == 0 ? '#F7F7F7' : '#FFFFFF')};
`;

const StyledVoterCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  & > p {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -0.5px;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const VoterLabel = styled.td`
  padding-left: 12px;
`;

const TransactionCell = styled.td``;

const TransactionLabel = styled.p`
  padding: 4px 8px;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 999px;

  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.5px;
  font-feature-settings: 'tnum' on, 'lnum' on, 'calt' off;
  color: rgba(0, 0, 0, 0.5);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & > a {
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    font-size: 12px;
  }
`;

const PlacedAndPointsLabel = styled.td`
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
`;
