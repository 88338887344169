import { useEffect, useState } from 'react';
import { fetchCurrentCycleId } from '../../Api/blockchain';
import { fetchVoteForCycle } from '../../Api/fetchVoteForCurrentCycle';
import { VoteRecord, WalletVoteRecord } from '../../shared/src/VoteRecord';

interface CurrentCycleVoteRecordResult {
  currentCycleId: number | null;
  voteRecord: VoteRecord | null;
  reloadVoteRecord(): void;
}

export const useCurrentCycleVoteRecord = (account: string | null): CurrentCycleVoteRecordResult => {
  const [voteRecord, setVoteRecord] = useState<VoteRecord | null>(null);
  const [currentCycleId, setCurrentCycleId] = useState<number | null>(null);
  const [shouldReload, setShouldReload] = useState(false);

  useEffect(() => {
    const walletVoteRecord = voteRecord as Partial<WalletVoteRecord> | null;
    if (account != null && account !== walletVoteRecord?.voter) {
      fetchCurrentCycleId()
        .then((cycleId) => {
          setCurrentCycleId(cycleId);
          return fetchVoteForCycle(account, cycleId);
        })
        .then(setVoteRecord)
        .catch(() => setCurrentCycleId(null));
    }
  }, [account, shouldReload]);

  return {
    currentCycleId,
    voteRecord,
    reloadVoteRecord: () => setShouldReload(!shouldReload), // flip this bool to cause the effect to fire again.
  };
};
