import { useState } from 'react';
import { useClickOutside } from './useClickOutside';

export interface DropdownInfo {
  isDropdownShown: boolean;
  setIsDropdownShown: React.Dispatch<React.SetStateAction<boolean>>;
  buttonRef: React.MutableRefObject<any>;
  menuRef: React.MutableRefObject<any>;
}

/// A React hook for managing the state of a dropdown menu
export const useDropdownMenu = (): DropdownInfo => {
  const [isDropdownShown, setIsDropdownShown] = useState(false);
  const refs = useClickOutside(() => setIsDropdownShown(false));
  return {
    isDropdownShown,
    setIsDropdownShown,
    ...refs,
  };
};
