import React, { FC } from 'react';
import styled from 'styled-components';

type Props = React.ComponentPropsWithoutRef<typeof StyledVoteButton>;

export const VoteButton: FC<Props> = (props) => <StyledVoteButton {...props}>Cast Vote</StyledVoteButton>;

const StyledVoteButton = styled.button`
  flex-shrink: 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #002106;
  padding: 8px 14px;
  background-color: #68ff85;
  border-style: none;
  border-radius: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover,
  &:focus {
    opacity: 0.85;
    cursor: pointer;
  }
`;
