import { useEffect, useState } from 'react';
import { Account, accountHasMinimumNominationBalance } from '../Api/blockchain';

/// A React hook used to check if the provided account has the minimum balance of $APOLLO
/// required to submit an application
export const useAccountHasMinimumNominationBalance = (account: Account): boolean => {
  const [hasMinimumBalance, setHasMinimumBalance] = useState(false);

  useEffect(() => {
    if (account != null) {
      accountHasMinimumNominationBalance(account)
        .then(setHasMinimumBalance)
        .catch((err) => {
          setHasMinimumBalance(false);
          console.error(`Error checking balance requirements: ${err}`);
        });
    } else {
      setHasMinimumBalance(false);
    }
  }, [account]);

  return hasMinimumBalance;
};
