import { useWeb3React } from '@web3-react/core';
import axios from 'axios';
import { Formik, Form, FormikErrors } from 'formik';
import { FC } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Web3 from 'web3';
import { RemoveContestantPayload } from '../shared/src/Contestant';
import { AdminEnqueuePayload, AdminSignedMessage } from '../shared/src/Queue';
import { effectiveMaxWidth, padding } from '../shared/src/ui/Constants';
import { signAdminMessage } from '../helpers/signer';
import { SectionHeader, SubmitButton } from './ApplyToContest/ApplyToContest';
import { LabeledField } from './ApplyToContest/LabeledField';

const apiBaseURL = process.env.REACT_APP_BASE_URL;

interface RemoveContestantForm {
  contestant: string;
  contestId: string;
}

interface EnqueueContestantForm {
  contestant: string;
  seriesId: string;
}

const initialRemoveContestantFormValues: RemoveContestantForm = {
  contestant: '',
  contestId: '',
};

const initialEnqueueContestantFormValues: EnqueueContestantForm = {
  contestant: '',
  seriesId: '',
};

export const Admin: FC = () => {
  return (
    <Container>
      <GatedByConnectWallet>
        {(address, library) => (
          <>
            <Formik
              initialValues={initialRemoveContestantFormValues}
              validate={validateRemoveContestantForm}
              validateOnMount
              onSubmit={onSubmitRemoveContestant(address, library as Web3)}
            >
              {({ isSubmitting, isValid }) => (
                <FormContainer>
                  <SectionHeader title="Remove a contestant" subtitle="Remove a contestant from the current contest." marginTop="60px" />

                  <LabeledField
                    name="contestant"
                    label="Contestant address"
                    placeholder="(e.g. 0xADe61B...)"
                    captionNote="Enter the address of the contestant to remove."
                  />

                  <LabeledField name="contestId" label="Contest ID" captionNote="Enter the ID of the contest in the database" />

                  <SubmitButton type="submit" disabled={isSubmitting || !isValid}>
                    Submit
                  </SubmitButton>
                </FormContainer>
              )}
            </Formik>

            <EnqueueContestantForm address={address} web3={library as Web3} />
          </>
        )}
      </GatedByConnectWallet>
    </Container>
  );
};

interface EnqueueContestantFormProps {
  address: string;
  web3: Web3;
}

const EnqueueContestantForm: FC<EnqueueContestantFormProps> = ({ address, web3 }) => (
  <Formik
    initialValues={initialEnqueueContestantFormValues}
    validate={validateEnqueueContestantForm}
    validateOnMount
    onSubmit={onSubmitEnqueueContestant(address, web3)}
  >
    {({ isSubmitting, isValid }) => (
      <FormContainer>
        <SectionHeader title="Enqueue a contestant" subtitle="Add a profile to the contestant queue" marginTop="60px" />

        <LabeledField
          name="contestant"
          label="Contestant address"
          placeholder="(e.g. 0xADe61B...)"
          captionNote="Enter the address of the contestant to enqueue."
        />

        <LabeledField name="seriesId" label="Series ID" captionNote="Enter the ID of the series in the database" />

        <SubmitButton type="submit" disabled={isSubmitting || !isValid}>
          Submit
        </SubmitButton>
      </FormContainer>
    )}
  </Formik>
);

interface GatedByConnectWalletProps {
  children: (address: string, library: Web3) => JSX.Element;
}

const GatedByConnectWallet: FC<GatedByConnectWalletProps> = ({ children }) => {
  const { active, account, library } = useWeb3React();
  if (active && account != null) {
    return children(account, library);
  } else {
    return <>Connect a wallet to proceed</>;
  }
};

const validateRemoveContestantForm = async (values: RemoveContestantForm): Promise<FormikErrors<RemoveContestantForm>> => {
  const errors: FormikErrors<RemoveContestantForm> = {};
  if (values.contestant.length <= 0) {
    errors.contestant = 'Contestant field must not be empty';
  }
  return errors;
};

const validateEnqueueContestantForm = async (values: EnqueueContestantForm): Promise<FormikErrors<EnqueueContestantForm>> => {
  const errors: FormikErrors<EnqueueContestantForm> = {};
  if (values.contestant.length <= 0) {
    errors.contestant = 'Contestant field must not be empty';
  }
  return errors;
};

const onSubmitRemoveContestant =
  (address: string, web3: Web3) =>
  async (values: RemoveContestantForm): Promise<any> => {
    try {
      const message: AdminSignedMessage = { address };
      const signature = await signAdminMessage(message, web3);
      const payload: RemoveContestantPayload = {
        message,
        signature,
      };
      const url = `${apiBaseURL}/contestants/${values.contestant}`;
      const response = await axios.delete(url, { data: payload });
      console.log(`Removed contestant successfully with response:\n${JSON.stringify(response.data.data)}`);
      toast.success('Success! Check the JS console for more info.');
    } catch (err) {
      toast.error('Failed to remove contestant. Check the JS console and/or server logs for more details.');
      console.error(`Error submitting admin form: ${err}`);
    }
  };

const onSubmitEnqueueContestant =
  (address: string, web3: Web3) =>
  async (values: EnqueueContestantForm): Promise<any> => {
    try {
      const message: AdminSignedMessage = { address };
      const signature = await signAdminMessage(message, web3);
      const payload: AdminEnqueuePayload = {
        message,
        signature,
        profileAddress: values.contestant,
        seriesId: values.seriesId,
      };
      const url = `${apiBaseURL}/queue/admin-enqueue`;
      const response = await axios.post(url, payload);
      console.log(`Enqueued contestant successfully with response:\n${JSON.stringify(response.data.data)}`);
      toast.success('Success! Check the JS console for more info.');
    } catch (err) {
      toast.error('Failed to enqueue contestant. Check the JS console and/or server logs for more details.');
      console.error(`Error submitting admin form: ${err}`);
    }
  };

// MARK: - Styled components

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin: auto;
  max-width: ${effectiveMaxWidth()}px;
  padding: 64px ${padding}px;
`;

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 588px;

  button {
    margin-top: 40px;
  }
`;
