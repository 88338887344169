import { sanitize } from 'dompurify';
import { FC } from 'react';
import styled from 'styled-components';
import { effectiveMobileMaxWidth } from '../shared/src/ui/Constants';
import { Pixels } from './ApplyToContest/LabeledField';

export const DropdownMenuContainer = styled.span`
  position: relative;
  display: inline-block;

  button {
    width: 100%;
    height: 100%;
  }
`;

interface DropdownMenuItemProps {
  /// The text content to show inside of the button
  text: string;
  /// The SVG contents of the icon image
  imageSource?: string;
  /// A URL to open when this button is clicked (optional)
  url?: string;
  /// A block to call when this button is clicked (optional)
  onClick?: () => void;
  /// A block called to indicate that the dropdown menu should be dismissed
  dismiss(): void;
}

export const DropdownMenuItem: FC<DropdownMenuItemProps> = ({ imageSource, text, url, onClick, dismiss }) => {
  const clickHandler = () => {
    if (url) {
      window.open(sanitize(url), '_blank');
    }
    if (onClick) {
      onClick();
    }
    dismiss();
  };

  return (
    <StyledDropdownMenuItem onClick={clickHandler}>
      {imageSource && <img src={imageSource} alt={text} />}
      <p>{text}</p>
    </StyledDropdownMenuItem>
  );
};

// Styled components

export interface DropdownMenuProps {
  isShown: boolean;
  rightAlignment?: Pixels;
  unsetRightAlignmentOnMobile?: boolean;
}

export const DropdownMenu = styled.div<DropdownMenuProps>`
  ${(props) => (props.isShown ? 'visibility: visible;' : 'visibility: hidden;')}

  position: absolute;
  top: calc(100% + 10px);
  ${(props) => props.rightAlignment && `right: ${props.rightAlignment};`} // offset to account for secondary button

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1px;
  width: 224px;
  background-color: #e1e5e8;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 2;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    ${(props) => (props.unsetRightAlignmentOnMobile ? 'right: unset;' : '')}
  }
`;

const StyledDropdownMenuItem = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background-color: #ffffff;
  border: none;
  padding: 12px;

  img {
    width: 24px;
    height: 24px;
    color: #002106;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #002106;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
  }

  &:hover {
    background-color: #f2f4f7;
    cursor: pointer;
  }
`;
