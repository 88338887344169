import styled from 'styled-components';
import { FC } from 'react';

export interface CancelConfirmButtonStackProps {
  confirmText?: string;
  /// An optional field used to disable the confirm button
  confirmButtonDisabled?: boolean;
  shouldClose(): void;
  onConfirm(): void;
}

export const CancelConfirmButtonStack: FC<CancelConfirmButtonStackProps> = ({
  confirmText,
  confirmButtonDisabled,
  shouldClose,
  onConfirm,
}) => (
  <ButtonContainer>
    <Button onClick={shouldClose}>Cancel</Button>
    <Button
      onClick={() => {
        if (!confirmButtonDisabled) {
          onConfirm();
          shouldClose();
        }
      }}
      emphasized
      disabled={confirmButtonDisabled}
    >
      {confirmText ?? 'Confirm'}
    </Button>
  </ButtonContainer>
);

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 10px;
  width: 100%;

  * {
    flex-grow: 1;
  }

  @media screen and (max-width: 360px) {
    flex-direction: column-reverse;
  }
`;

interface ButtonProps {
  emphasized?: boolean;
  disabled?: boolean;
}

const Button = styled.button<ButtonProps>`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  border-radius: 3px;
  height: 60px;

  color: ${(props) => (props.emphasized ? '#002106' : '#526350')};
  background-color: ${(props) => (props.emphasized ? '#68FF85' : 'transparent')};
  border: ${(props) => (props.emphasized ? 'none' : '1px solid #E1E5E8')};

  ${(props) => (props.disabled ? 'opacity: 0.65' : '')};

  &:hover {
    ${(props) => {
      if (!props.disabled) {
        return `
          cursor: pointer;
          opacity: 0.75;
        `;
      }
    }}
  }
`;
