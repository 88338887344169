import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PastContest } from '../../shared/src/PastContest';
import { contestTimeRangeString } from '../AllContests/ActiveContestRow';
import { ContextSpecificCTA } from '../ContextSpecificCTA';
import { HeaderLabelStack } from '../HeaderLabelStack';
import { StyledContestantsSection, contestantsHeaderSubtitle } from '../NominatedCreatorsSection';
import { Nominees } from '../Nominees';
import { PageInfoBanner } from '../PageInfoBanner';
import { VotesSection } from '../VotesSection';
import { PastContestCTA } from './PastContestCTA';

const baseURL = process.env.REACT_APP_BASE_URL;

export const PastContestDetails: FC = () => {
  const pastContest = usePastContest();
  if (pastContest != null) {
    return <_PastContestDetails {...pastContest} />;
  } else {
    return null;
  }
};

const _PastContestDetails: FC<PastContest> = (contest) => {
  const timeString = contestTimeRangeString(contest.cycleStartDate, contest.cycleId);
  const title = contest.name ?? `Contest #${contest.contestNumber}`;
  return (
    <>
      <PageInfoBanner
        topText={title}
        titleText="We have a winner!"
        cta={<ContextSpecificCTA context="pastContest" pastContestCTA={<PastContestCTA {...contest} />} />}
        caption={timeString}
        share
      />
      <ContestantsSection {...contest} />
      <VotesSection cycleId={contest.cycleId} />
    </>
  );
};

const ContestantsSection: FC<PastContest> = (contest) => {
  const info = { context: 'past', contestants: contest.contestants } as const;
  return (
    <StyledContestantsSection style={{ marginTop: '80px' }}>
      <HeaderLabelStack title="Creators" subtitle={contestantsHeaderSubtitle(contest.contestants)} />
      <Nominees info={info} />
    </StyledContestantsSection>
  );
};

// Helper functions

const usePastContest = (): PastContest | null => {
  const { contestNumber } = useParams();
  const [pastContest, setPastContest] = useState<PastContest | null>(null);
  useEffect(() => {
    if (contestNumber == null) {
      setPastContest(null);
      return;
    }

    const _contestNumber = parseInt(contestNumber);
    if (isNaN(_contestNumber) || !isFinite(_contestNumber)) {
      setPastContest(null);
      return;
    }

    fetchPastContest(_contestNumber).then(setPastContest);
  }, [contestNumber]);
  return pastContest;
};

const fetchPastContest = async (contestNumber: number): Promise<PastContest | null> => {
  try {
    const response = await axios.get(`${baseURL}/contests/past/${contestNumber}`);
    return response.data.data;
  } catch (err) {
    console.error(`Error fetching past contest: ${err}`);
    toast.error('Failed to fetch past contest');
    return null;
  }
};
