import Web3 from 'web3';
import BN from 'bn.js';
import { Contest } from '../shared/src/Contest';
import daoABI from '../shared/src/abi/dao.json';
import tokenABI from '../shared/src/abi/token.json';
import { infuraHTTPEndpoint } from '../helpers/infura';

const web3 = new Web3(infuraHTTPEndpoint());
const tokenContract = new web3.eth.Contract(tokenABI as any, process.env.REACT_APP_APOLLO_ADDRESS);
const daoContract = new web3.eth.Contract(daoABI as any, process.env.REACT_APP_DAO_ADDRESS);

export type Account = string | null | undefined;

export const fetchCurrentCycleId = async (): Promise<number> => {
  const cycleEndString: string = await daoContract.methods.currentVotingCycleEnd().call();
  return parseInt(cycleEndString);
};

export const fetchCycleEndDate = async (): Promise<Date | null> => {
  try {
    const cycleId = await fetchCurrentCycleId();
    const cycleEndDate = new Date(cycleId * 1000);
    return cycleEndDate;
  } catch (err) {
    console.error(`Error fetching cycle end date: ${err}`);
    return null;
  }
};

export const fetchCurrentBalance = async (account: string): Promise<string> => {
  const balance = await tokenContract.methods.balanceOf(account).call();
  return balance;
};

export const fetchMinimumNominationBalance = async (): Promise<string> => {
  const minimumBalanceBase: string = await daoContract.methods.minimumNominationBalance().call();
  // divide by 10 ^ 9, the number of decimals for Apollo
  const minimumBalance = new BN(minimumBalanceBase).div(new BN('1000000000')).toString();
  return new Intl.NumberFormat().format(BigInt(minimumBalance));
};

export const minimumVoteBalanceStringFromContest = (contest: Contest): string => {
  // divide by 10 ^ 9, the number of decimals for Apollo
  const minimumBalance = new BN(contest.minBalanceToVote).div(new BN('1000000000')).toString();
  return new Intl.NumberFormat().format(BigInt(minimumBalance));
};

export const accountHasMinimumNominationBalance = async (account: string): Promise<boolean> => {
  const accountBalance = await tokenContract.methods.balanceOf(account).call();

  let minimumBalance: any;
  try {
    // In dev, this can throw if there's not an ETH/APOLLO pair configured in Uniswap
    minimumBalance = await daoContract.methods.minimumNominationBalance().call();
  } catch {
    minimumBalance = 0;
  }

  const accountBalanceNum = new BN(accountBalance);
  const minimumBalanceNum = new BN(minimumBalance);

  return accountBalanceNum.gte(minimumBalanceNum);
};
