import { useState, useEffect } from 'react';
import { fetchMinimumNominationBalance } from '../Api/blockchain';

/// Used to determine the minimum balance of Apollo Inu required to vote
export const useMinimumNominationBalance = (): string | null => {
  const [minimumBalance, setMinimumBalance] = useState<string | null>(null);
  useEffect(() => {
    fetchMinimumNominationBalance()
      .then(setMinimumBalance)
      .catch((err) => {
        console.error(`Error fetching minimum balance: ${err}`);
        setMinimumBalance('0');
      });
  }, []);
  return minimumBalance;
};
