import axios from 'axios';
import Web3 from 'web3';
import { toast } from 'react-toastify';
import { NavigateFunction } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { FormDetails } from '../hooks/useNominationFormDetails';
import { ProfilePayload, ProfileSignedMessage } from '../shared/src/ProfilePayload';
import { CreatorProfile } from '../shared/src/CreatorProfile';
import { signProfileMessage } from '../helpers/signer';
import { Account } from './blockchain';
import { isSuccessfulStatus } from '../helpers/http-status';
import { nominationFailureEvent, nominationSuccessEvent, trackEvent } from '../hooks/analytics';
import { allSocialURLFields } from '../new-components/ApplyToContest/SocialLinks';
import { ImageType } from 'react-images-uploading';
import { WithId } from '../shared/src/WithId';
import { Series } from '../shared/src/Series';

const baseURL = process.env.REACT_APP_BASE_URL;

type ErrorMessages = {
  [field in keyof CreatorProfile]?: string;
};

/// If the "Other" field is selected, then the value of the custom field is returned.
export const effectiveCreatorType = (values: FormDetails): string | undefined => {
  return values.creatorType !== 'Add custom text' ? values.creatorType : values.customCreatorType;
};

type PresentModal = (name: string, address: string, profileURL?: string) => void;

interface SubmitProps {
  account: Account;
  active: boolean;
  web3: Web3;
  navigate: NavigateFunction;
  isEditingProfile: boolean;
  series: WithId<Series> | null;
  presentModal: PresentModal;
  refreshQueuePosition(): void;
}

// Used to submit the nomination form to the blockchain and API
export const submitNominationForm =
  (props: SubmitProps) =>
  async (values: FormDetails, helpers: FormikHelpers<FormDetails>): Promise<any> => {
    if (!props.active || !props.account) {
      return formSubmissionFailed(helpers, 'You must connect a wallet');
    }

    const errorMessages: ErrorMessages = {
      name: 'Missing name',
      emailAddress: 'Missing email address',
      country: 'Missing country',
      creatorType: 'Missing creator type',
      creatorStatement: 'Missing creator statement',
      fundsUsagePlan: 'Missing plan for how to use funds',
    };

    const indexedValues = values as { [k in string]: string | boolean | ImageType | undefined };
    const creatorType = effectiveCreatorType(values);
    for (const [field, message] of Object.entries(errorMessages)) {
      if (field === 'country' && values.country === 'Country') {
        return formSubmissionFailed(helpers, message);
      } else if (field === 'creatorType' && creatorType === 'Creator Type') {
        return formSubmissionFailed(helpers, message);
      } else if (!indexedValues[field]) {
        return formSubmissionFailed(helpers, message);
      }
    }

    const socialURLs = socialURLsFromFormDetails(values);
    const profile: CreatorProfile = {
      // Required fields
      name: values.name,
      address: props.account,
      emailAddress: values.emailAddress,
      country: values.country,
      creatorType: creatorType ?? '',
      creatorStatement: values.creatorStatement,
      fundsUsagePlan: values.fundsUsagePlan,
      // Optional fields
      youTubeVideoURL: values.youTubeVideoURL,
      websiteURL: values.websiteURL,
      otherSocialURL: values.otherSocialURL,
      youTubeChannelURL: values.youTubeChannelURL,
      ...socialURLs,
    };

    try {
      const date = Math.floor(Date.now() / 1000);
      const message: ProfileSignedMessage = {
        address: props.account,
        name: values.name,
        date,
      };

      const signature = await signProfileMessage(message, props.web3);
      const apiPayload: ProfilePayload = {
        message,
        signature,
        profile,
        seriesId: props.series?._id,
      };

      const method = props.isEditingProfile ? axios.patch : axios.post;
      const url = props.isEditingProfile ? `${baseURL}/profiles` : `${baseURL}/queue`;

      // Attempt to create or update the profile, then upload a profile picture
      const apiPromise = method(url, apiPayload, { validateStatus: () => true }).then((response) => {
        if (isSuccessfulStatus(response.status)) {
          return attemptUploadProfilePicture(message, signature, values.profileImage);
        } else {
          throw { message: response.data.message };
        }
      });

      const defaultError = props.isEditingProfile ? 'Failed to edit profile' : 'Failed to submit application';
      toast.promise(apiPromise, {
        pending: props.isEditingProfile ? 'Updating profile' : 'Submitting application',
        error: {
          render: ({ data }) => (data as any)?.message ?? defaultError,
        },
      });

      apiPromise
        .then((result) => {
          trackEvent(nominationSuccessEvent());
          props.navigate('/');
          props.refreshQueuePosition();
          if (!props.isEditingProfile && props.account != null) {
            const pictureURL = result.profilePictureURL ?? values.profilePictureURL;
            props.presentModal(values.name, props.account, pictureURL);
          }
        })
        .catch((err) => {
          console.error(`Error while submitting application: ${err}`);
          formSubmissionFailed(helpers);
        });
    } catch (err) {
      console.error(`Error while nominating: ${err}`);
      formSubmissionFailed(helpers, 'Failed to submit nomination');
    }
  };

// Helpers functions

const formSubmissionFailed = (helpers: FormikHelpers<FormDetails>, message?: string): void => {
  if (message != null) {
    toast.error(message);
  }
  helpers.setSubmitting(false);
  trackEvent(nominationFailureEvent());
};

const socialURLsFromFormDetails = (values: FormDetails): Partial<CreatorProfile> => {
  const urls: Partial<CreatorProfile> = {};
  allSocialURLFields.forEach((field) => {
    let fieldValue = values[field.name].trim();

    // Remove leading '@' character per https://eastedgestudios.atlassian.net/browse/AD-215
    if (fieldValue.startsWith('@')) {
      fieldValue = fieldValue.slice(1);
    }

    if (fieldValue.length > 0 && field.baseURL != null) {
      urls[field.name] = `${field.baseURL}${fieldValue}`;
    }
  });
  return urls;
};

interface UploadResult {
  profilePictureURL?: string;
}

/// Attempt to upload the provided profile picture. This can fail silently.
const attemptUploadProfilePicture = async (
  message: ProfileSignedMessage,
  signature: string,
  picture?: ImageType
): Promise<UploadResult> => {
  try {
    if (picture != null) {
      return await attemptUploadProfilePictureThrowing(message, signature, picture);
    } else {
      return {};
    }
  } catch (err) {
    console.error(`Error uploading profile picture: ${err}`);
    return {};
  }
};

/// Attempt to upload a profile picture. This function can throw an error.
export const attemptUploadProfilePictureThrowing = async (
  message: ProfileSignedMessage,
  signature: string,
  picture: ImageType
): Promise<UploadResult> => {
  if (picture.file == null) {
    throw new Error('Attempted to upload picture with missing file');
  }

  const formData = new FormData();
  formData.append('message', JSON.stringify(message));
  formData.append('signature', signature);
  formData.append('picture', picture.file);

  const response = await axios.patch(`${baseURL}/profiles/picture`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
  return {
    profilePictureURL: response.data.data?.profilePictureURL,
  };
};
