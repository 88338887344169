import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { Series } from '../shared/src/Series';
import { WithId } from '../shared/src/WithId';
import { APIResponse } from '../shared/src/APIResponse';

const apiBaseURL = process.env.REACT_APP_BASE_URL;

export const useSeries = (): WithId<Series>[] => {
  const [series, setSeries] = useState<WithId<Series>[]>([]);
  useEffect(() => {
    fetchSeries().then(setSeries);
  }, []);
  return series;
};

const fetchSeries = async (): Promise<WithId<Series>[]> => {
  try {
    const res: AxiosResponse<APIResponse<WithId<Series>[]>, any> = await axios.get(`${apiBaseURL}/series`);
    return res.data.data;
  } catch (err) {
    console.error(`Error occurred when fetching series: ${err}`);
    return [];
  }
};
