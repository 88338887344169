import { Dispatch, FC, useEffect, createContext, useReducer, PropsWithChildren } from 'react';
import {
  getExternalLinkDefault,
  setExternalLinkDefault,
  getShouldShowInfoBannerDefault,
  setShouldShowInfoBannerDefault,
} from '../helpers/local-storage';
import { Action, DEFAULT_STATE, reducer, State } from './state';

type StateWithDispatch = {
  state: State;
  dispatch: Dispatch<Action>;
};

export const AppContext = createContext<StateWithDispatch>(undefined as any);

export const AppContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const initialState = DEFAULT_STATE;
  initialState.shouldShowLinkWarning = getExternalLinkDefault();
  initialState.shouldShowInfoBanner = getShouldShowInfoBannerDefault();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    setExternalLinkDefault(state.shouldShowLinkWarning);
  }, [state.shouldShowLinkWarning]);

  useEffect(() => {
    setShouldShowInfoBannerDefault(state.shouldShowInfoBanner);
  }, [state.shouldShowInfoBanner]);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};
