import axios from 'axios';
import { VoteRecord } from '../shared/src/VoteRecord';
import { fetchCurrentCycleId } from './blockchain';

const baseURL = process.env.REACT_APP_BASE_URL;

export const fetchVoteForCurrentCycle = async (address: string): Promise<VoteRecord | null> => {
  try {
    const cycleId = await fetchCurrentCycleId();
    return await fetchVoteForCycle(address, cycleId);
  } catch (err) {
    console.log(`error when fetching vote: ${err}`);
    return null;
  }
};

export const fetchVoteForCycle = async (address: string, cycleId: number): Promise<VoteRecord | null> => {
  try {
    const url = `${baseURL}/votes/vote-for-cycle?cycleId=${cycleId}&voter=${address}`;
    const res = await axios.get(url);
    return res.data;
  } catch (err) {
    console.log(`error when fetching vote: ${err}`);
    return null;
  }
};
