import { FC } from 'react';
import styled, { css } from 'styled-components';
const baseURL = process.env.REACT_APP_SITE_BASE_URL;

// Social icons
import otherSocialSVG from '../svg/text-fields/other.svg';
import twitterSVG from '../svg/text-fields/twitter.svg';
import facebookSVG from '../svg/text-fields/facebook.svg';
import redditSVG from '../svg/text-fields/reddit.svg';
import linkedInSVG from '../svg/text-fields/linkedin.svg';
import { toast } from 'react-toastify';
import { ShareContextType, shareURLsForContext } from '../helpers/socialURLs';
import { sanitize } from '../helpers/sanitize';

interface Props {
  type: ShareContextType;
  creatorName: string;
  creatorAddress: string;
}

export const ShareCTAs: FC<Props> = (props) => {
  const shareURLs = shareURLsForContext({
    type: props.type,
    copyLinkURL: `${baseURL}/profile/${props.creatorAddress}`,
    creatorAddress: props.creatorAddress,
    creatorName: props.creatorName,
  });

  return (
    <Container>
      <ShareCTAButton
        imageSource={otherSocialSVG}
        description="Other"
        onClick={() => {
          navigator.clipboard.writeText(sanitize(shareURLs.copyLinkURL));
          toast.success('Copied Link');
        }}
      />
      <ShareCTAAnchor imageSource={twitterSVG} description="Twitter" url={shareURLs.twitterURL} />
      <ShareCTAAnchor imageSource={facebookSVG} description="Facebook" url={shareURLs.facebookURL} />
      <ShareCTAAnchor imageSource={redditSVG} description="Reddit" url={shareURLs.redditURL} />
      <ShareCTAAnchor imageSource={linkedInSVG} description="LinkedIn" url={shareURLs.linkedInURL} />
    </Container>
  );
};

interface ShareCTAAnchorProps {
  imageSource: string;
  description: string;
  url: string;
}

const ShareCTAAnchor: FC<ShareCTAAnchorProps> = ({ imageSource, description, url }) => (
  <StyledShareCTAAnchor href={sanitize(url)} target="_blank" rel="noreferrer">
    <img src={imageSource} alt={description} />
  </StyledShareCTAAnchor>
);

interface ShareCTAButtonProps {
  imageSource: string;
  description: string;
  onClick(): void;
}

const ShareCTAButton: FC<ShareCTAButtonProps> = ({ imageSource, description, onClick }) => (
  <StyledShareCTAButton onClick={onClick}>
    <img src={imageSource} alt={description} />
  </StyledShareCTAButton>
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 8px;
`;

const shareCTAStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: transparent;
  transition: background-color 100ms linear;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const StyledShareCTAAnchor = styled.a`
  ${shareCTAStyles}
`;

const StyledShareCTAButton = styled.button`
  ${shareCTAStyles}
`;
