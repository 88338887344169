import { FC } from 'react';
import styled from 'styled-components';
import { ProfileCard, ProfileCardProps } from './ProfileCard';
import { effectiveMobileMaxWidth } from '../shared/src/ui/Constants';

type Props = Omit<ProfileCardProps, 'onClickProfileLink' | 'onClickVote' | 'profilePictureURL'> & {
  /// The on-disk data URL of the profile picture
  dataURL?: string;
  /// The existing profile picture URL for this user, if one exists
  profilePictureURL?: string;
};

export const DesktopProfileCardPreview: FC<Props> = (props) => (
  <StyledDesktopProfileCardPreview>
    <ProfileCardPreview {...props} />
  </StyledDesktopProfileCardPreview>
);

export const MobileProfileCardPreview: FC<Props> = (props) => (
  <StyledMobileProfileCardPreview>
    <ProfileCardPreview {...props} />
  </StyledMobileProfileCardPreview>
);

const ProfileCardPreview: FC<Props> = ({ dataURL, profilePictureURL, ...props }) => (
  <Container>
    <h1>What your Profile card will look like</h1>
    <ProfileCard {...props} profilePictureURL={dataURL ?? profilePictureURL} />
    <p>Fill out the form to see the card update</p>
  </Container>
);

// Styled components

const StyledDesktopProfileCardPreview = styled.div`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 40px;
  margin-top: 157px;
  max-width: 448px;
  min-width: 350px;
  flex-grow: 2;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    display: none;
  }
`;

const StyledMobileProfileCardPreview = styled.div`
  display: none;
  margin-top: 32px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    display: initial;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
  width: 100%;

  padding: 32px;
  border: 1px solid #e1e5e8;
  border-radius: 12px;

  h1 {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
  }

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    padding: 14px;
    min-width: unset;
  }
`;
