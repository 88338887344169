import { FC } from 'react';
import styled from 'styled-components';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { effectiveMobileMaxWidth } from './Constants';

interface Props {
  date: Date;
}

export const CountdownBox: FC<Props> = ({ date }) => (
  <Countdown date={date} renderer={(components) => <CountdownBoxComponents {...components} />} />
);

export const CountdownBoxComponents: FC<CountdownRenderProps> = ({ days, hours, minutes }) => {
  const padded = (num: number) => (num < 10 ? `0${num.toString()}` : num.toString());
  return (
    <StyledCountdownBox>
      <CountdownSegment>
        <h2>{padded(days)}</h2>
        <h3>Days</h3>
      </CountdownSegment>
      <CountdownSegment>
        <h2>{padded(hours)}</h2>
        <h3>Hours</h3>
      </CountdownSegment>
      <CountdownSegment>
        <h2>{padded(minutes)}</h2>
        <h3>Minutes</h3>
      </CountdownSegment>
    </StyledCountdownBox>
  );
};

const StyledCountdownBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 20px;

  padding: 20px 19px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.34);

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    padding: 18px 16px;
  }
`;

const CountdownSegment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 2px;

  & > h2 {
    margin: 0;
    font-weight: 400;
    font-size: 22px;
    line-height: 100%;
    font-feature-settings: 'zero' on, 'salt' on;
    color: #ffffff;
  }

  & > h3 {
    margin: 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #ffffff;
  }

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    & > h2 {
      font-size: 20px;
    }
    & > h3 {
      font-size: 12px;
      line-height: 14px;
    }
  }
`;
