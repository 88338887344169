export type VoteType = 'wallet' | 'email';

/// A record of a single vote in a contest
export interface BaseVoteRecord {
  /// The wallet address of the creator who received the vote
  nominee: string;
  /// The cycle ID of the contest
  cycleId: number;
  /// The path of the vote record in IPFS
  ipfsPath: string;
  /// The date that the user cast the vote
  voteDate: number;
  /// Whether the user voted for the first time in this contest
  isFirstTimeVoter: boolean;
  /// Whether the vote has been marked invalid due to some factor like cheating
  invalid?: boolean;
  /// The IP address of the machine the vote was cast from
  ipAddress?: string;
}

/// A vote that was cast using an Ethereum wallet address
export type WalletVoteRecord = BaseVoteRecord & {
  /// Email vote type
  type: 'wallet' | undefined; // This can be undefined because old votes don't have this field
  /// The wallet address of the voter
  voter: string;
  /// The voter's Apollo balance at the time they voted
  apolloBalance: number;
};

/// A vote that was cast using an email address
export type EmailVoteRecord = BaseVoteRecord & {
  /// Email vote type
  type: 'email';
  /// The email address of the voter
  voterEmail: string;
};

/// A record of a single vote in a contest
export type VoteRecord = WalletVoteRecord | EmailVoteRecord;

export type VoteRecordAndName = VoteRecord & {
  /// The name of the nominee that was voted for
  nomineeName: string;
};

export const determineVotePoints = (record: VoteRecord): number => {
  switch (record.type) {
    case 'wallet':
    case undefined:
      return 10;
    case 'email':
      return 1;
    default:
      // should not happen
      return 1;
  }
};
