import Web3 from 'web3';
import { VoteMessage } from '../shared/src/VotePayload';
import { ProfileSignedMessage } from '../shared/src/ProfilePayload';
import { AdminSignedMessage } from '../shared/src/Queue';
import { signMessage, Signature } from '../shared/src/EIP712Signer';
import { voteMessageTypeFields, profileMessageTypeFields, adminMessageTypeFields } from '../shared/src/SignerMessageTypes';

// Sign a vote message and return the signature
export const signVoteRequest = async (message: VoteMessage, web3: Web3): Promise<Signature> =>
  await signMessage(message.voter, message, voteMessageTypeFields, 'Vote', web3);

// Sign a "profile" message, which is used to enter the queue or edit a profile
export const signProfileMessage = async (message: ProfileSignedMessage, web3: Web3): Promise<Signature> =>
  await signMessage(message.address, message, profileMessageTypeFields, 'Profile', web3);

// Sign an admin message
export const signAdminMessage = async (message: AdminSignedMessage, web3: Web3): Promise<Signature> =>
  await signMessage(message.address, message, adminMessageTypeFields, 'Admin', web3);
