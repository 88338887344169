import { FC, useContext, useRef } from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { useNavigate } from 'react-router-dom';
import { effectiveMaxWidth, padding } from '../shared/src/ui/Constants';
import { AppContext } from '../hooks/context';
import xButtonSVG from '../svg/x-button.svg';
import { setShouldShowInfoBanner } from '../hooks/state';

const TRANSITION_DURATION = 300;

export const ContestInfoBanner: FC = () => {
  const nodeRef = useRef(null);
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();

  return (
    <CSSTransition nodeRef={nodeRef} in={state.shouldShowInfoBanner} timeout={TRANSITION_DURATION} classNames="banner" unmountOnExit>
      <Background ref={nodeRef}>
        <Content>
          <p>
            <b>Note:</b> You can now <a onClick={() => navigate('/apply')}>Apply as a Creator</a> or Vote for a Creator.{' '}
            <a
              href="https://docs.apollocrypto.org/docs/v/apollo-creator-voting-system/introduction/creator-contest-overview"
              target="_blank"
              rel="noreferrer"
            >
              Need help?
            </a>
          </p>
          <img src={xButtonSVG} alt="Close" onClick={() => dispatch(setShouldShowInfoBanner(false))} />
        </Content>
      </Background>
    </CSSTransition>
  );
};

// Styled components

const Background = styled.div`
  background-color: #d5e8d0;
  overflow: hidden;
  max-height: 120px;

  &.banner-exit {
    max-height: 120px;
    opacity: 1;
  }
  &.banner-exit-active {
    max-height: 0;
    opacity: 0;
    transition: max-height ${TRANSITION_DURATION}ms ease, opacity ${TRANSITION_DURATION}ms ease;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px; // on mobile

  height: 100%;
  margin: auto;
  max-width: ${effectiveMaxWidth()}px;
  padding: 12px ${padding}px;

  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #526350;

    a {
      color: inherit;
      text-decoration: underline;
      cursor: pointer;
    }

    b {
      font-weight: 500;
      color: #526350;
    }
  }

  img:hover {
    cursor: pointer;
  }
`;
