import { useEffect, useState } from 'react';
import { fetchCurrentBalance } from '../../Api/blockchain';

interface Result {
  balance: string | null;
}

export const useCurrentBalance = (account: string | null): Result => {
  const [balance, setBalance] = useState<string | null>(null);
  useEffect(() => {
    if (account != null) {
      fetchCurrentBalance(account)
        .then(setBalance)
        .catch(() => setBalance(null));
    } else {
      setBalance(null);
    }
  }, [account]);
  return { balance };
};
