import { FC } from 'react';
import styled from 'styled-components';

interface Props {
  reasonText: string;
  isShown: boolean;
}

export const DisabledButtonCallout: FC<Props> = ({ reasonText, isShown }) => (
  <OuterContainer isShown={isShown}>
    <InnerContainer>
      <Arrow />
      <p>{reasonText}</p>
    </InnerContainer>
  </OuterContainer>
);

interface OuterContainerProps {
  isShown: boolean;
}

// Used to position the callout absolutely within its parent
const OuterContainer = styled.div<OuterContainerProps>`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  pointer-events: none;

  opacity: ${({ isShown }) => (isShown ? '1' : '0')};
  top: ${({ isShown }) => (isShown ? 'calc(100% + 8px)' : 'calc(100% + 20px)')};
  transition: opacity 200ms ease, top 200ms ease;
`;

const InnerContainer = styled.div`
  position: relative;
  width: 320px;
  height: 42px;
  padding: 12px;
  background-color: #101828;
  border-radius: 8px;

  p {
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
  }
`;

const Arrow = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  top: -6px;
  left: calc(50% - 6px);

  background-color: #101828;
  transform: rotate(45deg);
  border-top-left-radius: 1px;
`;
