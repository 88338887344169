import { ChangeEvent, FC, useState } from 'react';
import styled from 'styled-components';
import arrowSVG from '../svg/arrow-right.svg';
import greenCheck from '../svg/green-check.svg';

interface Props {
  emailFieldRef: React.MutableRefObject<HTMLInputElement | null> | null;
  alreadyVoted: boolean;
  submitVote(): void;
  clearAlreadyVoted(): void;
}

export const EmailVoteModalContents: FC<Props> = ({ emailFieldRef, alreadyVoted, submitVote, clearAlreadyVoted }) => {
  const [buttonDisabled, setButtonDisabled] = useState(emailFieldRef?.current == null || emailFieldRef.current.value.length === 0);

  const onChange: React.ChangeEventHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setButtonDisabled(event.target.value.length === 0);
    alreadyVoted && clearAlreadyVoted();
  };

  return (
    <Container>
      <TopLabels>
        <h1>Vote without crypto</h1>
        <h2>
          To vote for a creator without owning APOLLO tokens, submit your email address below and a link will be sent to your inbox. The
          link will expire in 24 hours.
        </h2>
      </TopLabels>
      <BottomContainer>
        <EmailFieldAndCaptionContainer>
          <p>Send a login link to your email address</p>
          <EmailFieldContainer>
            <EmailTextField highlighted={alreadyVoted} ref={emailFieldRef} placeholder="you@example.com" onChange={onChange} />
            {alreadyVoted && <GreenCheck src={greenCheck} alt="Checkmark" />}
          </EmailFieldContainer>
          {alreadyVoted && <AlreadyVotedLabel>You have already voted! Good job.</AlreadyVotedLabel>}
        </EmailFieldAndCaptionContainer>
        <SendLinkButton disabled={buttonDisabled} onClick={submitVote}>
          <p>Send link</p>
          <img src={arrowSVG} alt="Arrow" />
        </SendLinkButton>
        <NoteLabel>
          Note: By clicking 'Send link' you agree to receive official email information from Apollo Crypto. Your email address will not be
          shared publicly or sold.
        </NoteLabel>
      </BottomContainer>
    </Container>
  );
};

// Styled components

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 32px;
  padding: 0px 40px;
  background-color: #ffffff;
`;

const TopLabels = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;

  & > h1 {
    margin: 0;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #002106;
  }

  & > h2 {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #72796f;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  padding: 24px;

  background: rgba(229, 238, 255, 0.4);
  border: 1px solid #e5eeff;
  border-radius: 8px;
`;

const EmailFieldAndCaptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 6px;

  & > p {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #002106;
  }
`;

interface EmailTextFieldProps {
  highlighted: boolean;
}

const EmailFieldContainer = styled.div`
  position: relative;
`;

const EmailTextField = styled.input<EmailTextFieldProps>`
  padding: 20px 16px;
  padding-right: ${(props) => (props.highlighted ? '46px' : '16px')};
  width: 100%;
  height: 100%;

  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;

  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
  transition: border-color 200ms ease;

  box-shadow: ${(props) =>
    props.highlighted
      ? '0px 1px 2px rgba(104, 255, 133, 0.05), 0px 0px 0px 4px rgba(104, 255, 133, 0.24)'
      : '0px 1px 2px rgba(16, 24, 40, 0.05)'};
  border-color: ${(props) => (props.highlighted ? '#00C648' : '#e1e5e8')};
  outline: ${(props) => (props.highlighted ? 'none' : 'initial')};

  ::placeholder {
    color: #667085;
  }

  &:focus {
    outline: none;
    border-color: ${(props) => (props.highlighted ? '#00C648' : '#0052ff')};
  }
`;

const AlreadyVotedLabel = styled.h3`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #00c648;
`;

const SendLinkButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;

  height: 48px;

  background: #0052ff;
  border: 1px solid #0052ff;
  border-radius: 3px;
  transition: opacity 200ms ease;

  & > p {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #ffffff;
  }

  &:hover,
  &:focus {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.6;
    cursor: initial;
  }
`;

const NoteLabel = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #526350;
`;

const GreenCheck = styled.img`
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;
