import { FC } from 'react';
import { LabeledField } from './LabeledField';
import { URLPrefixTextField } from './URLPrefixTextField';
import { CreatorProfileFormDetails } from '../../shared/src/CreatorProfile';

// Icons
import websiteSVG from '../../svg/text-fields/website.svg';
import twitterSVG from '../../svg/text-fields/twitter.svg';
import facebookSVG from '../../svg/text-fields/facebook.svg';
import instagramSVG from '../../svg/text-fields/instagram.svg';
import youTubeSVG from '../../svg/text-fields/youtube.svg';
import openSeaSVG from '../../svg/text-fields/opensea.svg';
import redditSVG from '../../svg/text-fields/reddit.svg';
import linkedInSVG from '../../svg/text-fields/linkedin.svg';
import tikTokSVG from '../../svg/text-fields/tiktok.svg';
import otherSocialSVG from '../../svg/text-fields/other.svg';

interface SocialFormField {
  label: string;
  baseURL?: string;
  placeholder: string;
  name: keyof CreatorProfileFormDetails;
  iconSource: string;
}

interface SocialLinksProps {
  disabled?: boolean;
}

export const allSocialURLFields: SocialFormField[] = [
  { label: 'Twitter', baseURL: 'https://twitter.com/', placeholder: 'username', name: 'twitterURL', iconSource: twitterSVG },
  { label: 'Facebook', baseURL: 'https://facebook.com/', placeholder: 'username', name: 'facebookURL', iconSource: facebookSVG },
  { label: 'Instagram', baseURL: 'https://instagram.com/', placeholder: 'username', name: 'instagramURL', iconSource: instagramSVG },
  { label: 'YouTube', placeholder: 'https://youtube.com/c/username', name: 'youTubeChannelURL', iconSource: youTubeSVG },
  { label: 'OpenSea', baseURL: 'https://opensea.io/', placeholder: 'username', name: 'openSeaURL', iconSource: openSeaSVG },
  { label: 'Reddit', baseURL: 'https://reddit.com/user/', placeholder: 'username', name: 'redditURL', iconSource: redditSVG },
  { label: 'LinkedIn', baseURL: 'https://linkedin.com/in/', placeholder: 'username', name: 'linkedInURL', iconSource: linkedInSVG },
  { label: 'TikTok', baseURL: 'https://tiktok.com/@', placeholder: 'username', name: 'tikTokURL', iconSource: tikTokSVG },
];

export const SocialLinks: FC<SocialLinksProps> = ({ disabled }) => (
  <>
    <LabeledField
      type="url"
      label="Website"
      placeholder="https://mywebsite.com/"
      name="websiteURL"
      iconSource={websiteSVG}
      disabled={disabled}
    />

    {allSocialURLFields.map((field) => {
      const { baseURL, ...rest } = field;
      if (baseURL != null) {
        return <URLPrefixTextField key={field.label} {...rest} baseURL={baseURL} disabled={disabled} marginTop="16px" />;
      } else {
        return <LabeledField key={field.label} type="url" {...rest} marginTop="16px" disabled={disabled} />;
      }
    })}

    <LabeledField
      type="url"
      label="Other"
      placeholder="https://other.com/username"
      name="otherSocialURL"
      iconSource={otherSocialSVG}
      marginTop="16px"
      disabled={disabled}
    />
  </>
);
