import { toast } from 'react-toastify';
import { CastVoteModal } from './CastVoteModal';
import { FC, useContext } from 'react';
import Web3 from 'web3';
import { signVoteRequest } from '../helpers/signer';
import { APIVotePayload, VoteMessage } from '../shared/src/VotePayload';
import axios from 'axios';
import { fetchCurrentCycleId } from '../Api/blockchain';
import { isSuccessfulStatus } from '../helpers/http-status';
import { trackEvent, voteCastFailureEvent, voteCastSuccessEvent } from '../hooks/analytics';
import { AppContext } from '../hooks/context';
import { Action, setProfileModal } from '../hooks/state';
import { VotePayload } from './Voting/VoteModalPresenter';

const baseURL = process.env.REACT_APP_BASE_URL;

export interface VoteModalProps {
  isShowingModal: boolean;
  votePayload: VotePayload | null;
  shouldClose(): void;
  didClose(): void;
  didUpdateVotes: () => void;
  web3: Web3;
}

export const VoteModal: FC<VoteModalProps> = ({ votePayload, isShowingModal, didUpdateVotes, web3, ...props }) => {
  const { dispatch } = useContext(AppContext);
  return (
    <CastVoteModal
      isShown={isShowingModal}
      profile={votePayload?.profile}
      {...props}
      onConfirm={() => {
        if (votePayload) {
          voteOffChain(votePayload, didUpdateVotes, dispatch, web3);
        }
      }}
    />
  );
};

const voteOffChain = async (payload: VotePayload, didUpdateVotes: () => void, dispatch: React.Dispatch<Action>, web3: Web3) => {
  try {
    const cycleId = await fetchCurrentCycleId();
    const currentTime = Math.floor(Date.now() / 1000);
    if (cycleId < currentTime) {
      toast.error('The current contest has ended');
      return;
    }

    const message: VoteMessage = {
      voter: payload.voter,
      nominee: payload.nominee,
      cycleId: payload.cycleId,
      apolloBalance: payload.apolloBalance,
      voteDate: Math.floor(Date.now() / 1000),
    };

    const signature = await signVoteRequest(message, web3);
    const apiPayload: APIVotePayload = {
      ipfsMessage: message,
      signature,
    };

    const apiPromise = axios.post(`${baseURL}/votes/voteOffchain`, apiPayload, { validateStatus: () => true }).then((response) => {
      if (!isSuccessfulStatus(response.status)) {
        throw { message: response.data.message };
      }
    });

    toast.promise(apiPromise, {
      pending: 'Submitting vote',
      success: 'Vote cast!',
      error: {
        render: ({ data }) => (data as any)?.message ?? 'Failed to cast vote',
      },
    });

    await apiPromise;
    didUpdateVotes();
    dispatch(
      setProfileModal({
        creatorProfilePicURL: payload.profile.profilePictureURL,
        creatorAddress: payload.nominee,
        creatorName: payload.profile.name,
        type: 'profile',
      })
    );
    trackEvent(voteCastSuccessEvent());
  } catch (err) {
    console.error(`Error submitting vote: ${err}`);
    trackEvent(voteCastFailureEvent());
  }
};
