import axios, { AxiosResponse } from 'axios';
import { FC, Dispatch, useContext, useEffect } from 'react';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { clickedContinueAfterWatchingVideoEvent, didWatchVideoToEndEvent, trackEvent, useGAModalViewTracking } from '../../hooks/analytics';
import { AppContext } from '../../hooks/context';
import { Action, setProfileModal } from '../../hooks/state';
import { useModal } from '../../hooks/useModal';
import { APIResponse } from '../../shared/src/APIResponse';
import { EmailVerifyPayload, EmailVoteResponse } from '../../shared/src/EmailVotePayload';
import { WatchToVoteContents } from '../../shared/src/ui/WatchToVoteContents';
import { Modal } from '../Modal';

const apiBaseURL = process.env.REACT_APP_BASE_URL as string;

/// "Crypto for the Creator Economy" video.
/// https://www.youtube.com/watch?v=Lh0e5jZHjDw
const VIDEO_ID = 'Lh0e5jZHjDw';

export const WatchToVoteModal: FC = () => {
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const payload = makeEmailVerifyPayload(searchParams);
  const modal = useModal<EmailVerifyPayload>();
  useGAModalViewTracking(modal.isShowingModal, 'watch-to-vote');

  // Show an error message if the URL params are invalid
  useEffect(() => {
    if (payload != null) {
      modal.showModal(payload);
    } else {
      toast.error('Something went wrong. Try the same link again, or try sending another email.');
    }
  }, []);

  // Submit the vote on click
  const onContinueClicked = () => {
    modal.shouldClose();
    // Fire analytics event after they have clicked "Continue"
    trackEvent(clickedContinueAfterWatchingVideoEvent());

    if (payload != null) {
      submitVote(payload, dispatch, navigate);
    } else {
      toast.error('Something went wrong. Try again.');
    }
  };

  // Fire analytics event when the video ends, but before they have clicked "Continue"
  const didWatchToEnd = () => trackEvent(didWatchVideoToEndEvent());

  return (
    <Modal isShown={modal.isShowingModal} contentWidth="560px" shouldClose={modal.shouldClose} didClose={modal.didClose}>
      <WatchToVoteContents youtubeVideoId={VIDEO_ID} onContinueClicked={onContinueClicked} didWatchVideoToEnd={didWatchToEnd} />
    </Modal>
  );
};

// Helpers

const makeEmailVerifyPayload = (searchParams: URLSearchParams): EmailVerifyPayload | null => {
  const voterEmail = searchParams.get('voterEmail');
  const code = searchParams.get('code');
  const cycleIdString = searchParams.get('cycleId');
  const voteForProfile = searchParams.get('voteForProfile');

  if (voterEmail == null || code == null || cycleIdString == null || voteForProfile == null) {
    return null;
  }

  const cycleId = parseInt(cycleIdString);
  if (isNaN(cycleId)) {
    return null;
  }

  return {
    voterEmail,
    code,
    cycleId,
    voteForProfile,
  };
};

const submitVote = (payload: EmailVerifyPayload, dispatch: Dispatch<Action>, navigate: NavigateFunction) => {
  const votePromise = verifyEmailCodeAndVote(payload);
  toast.promise(votePromise, {
    pending: 'Submitting vote',
    error: {
      render: ({ data }) => (data as any)?.message ?? 'Failed to cast vote',
    },
  });

  votePromise
    .then((response) => {
      // On success, navigate to the regular landing page
      navigate('/');

      // Show the vote confirmation modal
      dispatch(
        setProfileModal({
          creatorProfilePicURL: response.profile.profilePictureURL,
          creatorAddress: response.profile.address,
          creatorName: response.profile.name,
          type: 'profile',
        })
      );
    })
    .catch();
};

const verifyEmailCodeAndVote = async (payload: EmailVerifyPayload): Promise<EmailVoteResponse> => {
  const response: AxiosResponse<APIResponse<EmailVoteResponse>, any> = await axios.post(
    `${apiBaseURL}/votes/verify-email-code-and-vote`,
    payload,
    { validateStatus: () => true }
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data.data;
  } else {
    throw response.data;
  }
};
