import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { useField, FieldHookConfig } from 'formik';

export type Pixels = `${number}px`;

interface LabeledFieldProps {
  label: string;
  /// Text below the label but above the field
  labelCaption?: React.ReactNode;
  captionNote?: string;
  // Override the minimum height of the input
  minHeight?: Pixels;
  // Override the margin-top of the label
  marginTop?: Pixels;
  iconSource?: string;
  disabled?: boolean;
}

type LabeledFieldPropsExt<T> = LabeledFieldProps &
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
  FieldHookConfig<T>;

export const LabeledField: FC<LabeledFieldPropsExt<string>> = ({
  label,
  labelCaption,
  captionNote,
  iconSource,
  marginTop,
  disabled,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <>
      <TextFieldLabel marginTop={marginTop} htmlFor={props.id || props.name}>
        {label}
      </TextFieldLabel>
      {labelCaption && <CaptionNote style={{ marginBottom: '12px' }}>{labelCaption}</CaptionNote>}
      <TextFieldContainer>
        <TextField className="text-input" type={props.type ?? 'text'} {...field} {...props} iconSource={iconSource} disabled={disabled} />
        {iconSource && <TextFieldIcon src={iconSource} alt="Twitter" />}
      </TextFieldContainer>
      {captionNote && <CaptionNote>{captionNote}</CaptionNote>}
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </>
  );
};

export const labelCSS = css`
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
`;

export const textFieldCSS = css`
  padding: 10px 14px;

  border-width: 1px;
  border-style: solid;
  border-color: #d0d5dd;
  border-radius: 8px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
  transition: box-shadow 200ms ease, border-color 200ms ease;

  ::placeholder {
    color: #667085;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 4px rgba(104, 255, 133, 0.2);
    border-color: #68ff85;
  }
`;

interface TextFieldLabelProps {
  marginTop?: Pixels;
}

export const TextFieldLabel = styled.label<TextFieldLabelProps>`
  ${labelCSS}
  ${(props) => (props.marginTop ? `margin-top: ${props.marginTop}` : 'margin-top: 40px')}
`;

export const CaptionNote = styled.p`
  margin-top: 6px;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;

  i {
    font-style: italic;
  }
`;

const TextFieldContainer = styled.div`
  position: relative;
`;

const TextFieldIcon = styled.img`
  pointer-events: none;
  position: absolute;
  top: 12px;
  left: 14px;
`;

const TextField = styled.input<LabeledFieldPropsExt<string>>`
  ${textFieldCSS}
  width: 100%;
  height: 100%;
  ${(props) => (props.iconSource ? 'padding-left: 42px' : '')}
  ${(props) => (props.minHeight ? `min-height: ${props.minHeight}` : '')}
`;
