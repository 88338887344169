import { useWeb3React } from '@web3-react/core';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { ImageType } from 'react-images-uploading';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import { CreatorProfileResponseDetails } from '../shared/src/CreatorProfile';
import { ProfileSignedMessage } from '../shared/src/ProfilePayload';
import { attemptUploadProfilePictureThrowing } from '../Api/submitNominationForm';
import { signProfileMessage } from '../helpers/signer';

const apiBaseURL = process.env.REACT_APP_BASE_URL;

interface CreatorProfileContext {
  profile: CreatorProfileResponseDetails | null;
  updateProfilePicture(picture: ImageType): void;
}

export const useCreatorProfile = (address: string | null): CreatorProfileContext => {
  const [profile, setProfile] = useState<CreatorProfileResponseDetails | null>(null);
  const { library } = useWeb3React();

  const updateProfilePicture = (picture: ImageType): void => {
    if (profile == null) {
      toast.error('Error uploading image. Try again later.');
      return;
    }

    const web3 = library as Web3;
    const uploadPromise = attemptUpdatePictureAndFetchProfile(picture, profile, web3);
    toast.promise(uploadPromise, {
      pending: 'Updating profile',
      error: 'Error updating profile. Try again later.',
      success: 'Success! It may take a few minutes before you start seeing your new photo everywhere.',
    });

    uploadPromise.then(setProfile);
  };

  useEffect(() => {
    if (address) {
      fetchCreatorProfile(address).then(setProfile);
    } else {
      setProfile(null);
    }
  }, [address]);

  return {
    profile,
    updateProfilePicture,
  };
};

const fetchCreatorProfile = async (address: string): Promise<CreatorProfileResponseDetails | null> => {
  try {
    const response = await axios.get(`${apiBaseURL}/profiles/address/${address}`);
    return response.data.success === true ? response.data.data : null;
  } catch (err) {
    console.log(`error when fetching profile: ${err}`);
    return null;
  }
};

interface SignatureResult {
  message: ProfileSignedMessage;
  signature: string;
}

const attemptUpdatePictureAndFetchProfile = async (
  picture: ImageType,
  profile: CreatorProfileResponseDetails,
  web3: Web3
): Promise<CreatorProfileResponseDetails | null> => {
  try {
    const { message, signature } = await getSignatureForUploadingPicture(profile, web3);
    await attemptUploadProfilePictureThrowing(message, signature, picture);
    return await fetchCreatorProfile(profile.address);
  } catch (err) {
    console.error(`Failed to update picture: ${err}`);
    return null;
  }
};

const getSignatureForUploadingPicture = async (profile: CreatorProfileResponseDetails, web3: Web3): Promise<SignatureResult> => {
  const date = Math.floor(Date.now() / 1000);
  const message: ProfileSignedMessage = {
    address: profile.address,
    name: profile.name,
    date,
  };

  const signature = await signProfileMessage(message, web3);
  return { message, signature };
};
