import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { PrizeDetails } from '../shared/src/PrizeDetails';
import { APIResponse } from '../shared/src/APIResponse';

const apiBaseURL = process.env.REACT_APP_BASE_URL;

/// Fetch and return the prize details for the current contest
export const usePrizeDetails = (): PrizeDetails | null => {
  const [prizeDetails, setPrizeDetails] = useState<PrizeDetails | null>(null);
  useEffect(() => {
    fetchPrizeDetails().then(setPrizeDetails);
  }, []);
  return prizeDetails;
};

const fetchPrizeDetails = async (): Promise<PrizeDetails | null> => {
  try {
    const response: AxiosResponse<APIResponse<PrizeDetails>, any> = await axios.get(`${apiBaseURL}/prize`);
    return response.data.data;
  } catch (err) {
    console.error(`Error fetching prize details: ${err}`);
    return null;
  }
};
