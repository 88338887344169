import 'react-toastify/dist/ReactToastify.css';
import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import GlobalStyle from './shared/src/ui/GlobalStyles';
import { ToastContainer } from 'react-toastify';
import { Profile } from './new-components/Profile/Profile';
import { ApplyToContest } from './new-components/ApplyToContest/ApplyToContest';
import { AllContests } from './new-components/AllContests/AllContests';
import { ExternalLinkModal } from './new-components/ExternalLink';
import { ProfileModal } from './new-components/ProfileModal';
import { Admin } from './new-components/Admin';
import { ConnectWalletModal } from './new-components/Wallet/ConnectWalletModal';
import { DesktopOrMobileNavBar } from './new-components/NavBar/DesktopOrMobileNavBar';
import { PastContestDetails } from './new-components/Contest/PastContestDetails';
import { AppContextProvider } from './hooks/context';
import { useGAPageViewTracking } from './hooks/analytics';
import { useActivateStoredWallet } from './hooks/useActivateAndStoreWallet';
import { useQueuePosition } from './hooks/useQueuePosition';
import { LandingPage } from './new-components/LandingPage';
import { VoteContextProvider } from './new-components/Voting/VoteContextProvider';
import { VoteModalPresenter } from './new-components/Voting/VoteModalPresenter';
import { YouVotedModal } from './new-components/YouVotedModal';

const App: FC = () => {
  useActivateStoredWallet();
  useGAPageViewTracking();
  const queueInfo = useQueuePosition();

  return (
    <>
      <ToastContainer />
      <GlobalStyle />
      <AppContextProvider>
        <VoteContextProvider>
          <DesktopOrMobileNavBar queueInfo={queueInfo} />
          <ExternalLinkModal />
          <ProfileModal />
          <YouVotedModal />
          <ConnectWalletModal />
          <VoteModalPresenter />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/verify-email" element={<LandingPage verifyEmail />} />
            <Route path="/apply/:path" element={<ApplyToContest refreshQueuePosition={queueInfo.refreshQueuePosition} />} />
            <Route
              path="/edit-profile"
              element={<ApplyToContest refreshQueuePosition={queueInfo.refreshQueuePosition} editExistingNomination />}
            />
            <Route path="/profile/:address" element={<Profile queueInfo={queueInfo} />} />
            <Route path="/contests" element={<AllContests />} />
            <Route path="/contests/:contestNumber" element={<PastContestDetails />} />
            <Route path="/admin" element={<Admin />} />
          </Routes>
        </VoteContextProvider>
      </AppContextProvider>
    </>
  );
};

export default App;
