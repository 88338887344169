import BN from 'bn.js';
import { NonApolloPrize, USDPrizeOverride } from '../Contest';
import { PrizeDetails } from '../PrizeDetails';

export interface DisplayValues {
  totalApolloPrize: string;
  baseApolloPrize: string;
  maxApolloPrize: string;

  totalUSDPrize: string;
  baseUSDPrize: string;
  maxUSDPrize: string;

  prizeTiers: PrizeTier[];

  progressValue: number;
  progressMax: number;
}

export interface PrizeTier {
  /// e.g. "First Place"
  label: string;
  /// The prize display string, e.g. "$50"
  value: string;
}

export const isPrizeBonusLocked = (props: PrizeDetails | null): boolean => {
  if (props != null) {
    return props.totalVotesCount < props.minVotesForBonus;
  } else {
    return true;
  }
};

export const convertNullablePrizeDetailsToDisplayValues = (props: PrizeDetails | null): DisplayValues => {
  if (props != null) {
    return convertPrizeDetailsToDisplayValues(props);
  } else {
    return {
      totalApolloPrize: '-- APOLLO',
      baseApolloPrize: '-- APOLLO',
      maxApolloPrize: '-- APOLLO',

      totalUSDPrize: '$--',
      baseUSDPrize: '$--',
      maxUSDPrize: '$--',

      prizeTiers: [],

      progressValue: 0,
      progressMax: 100,
    };
  }
};

export const convertPrizeDetailsToDisplayValues = (props: PrizeDetails): DisplayValues => {
  if (props.usdPrizeOverrides && props.usdPrizeOverrides.length > 0) {
    return convertPrizeDetailsToDisplayValuesUsingUSDOverrides(props);
  } else {
    return convertPrizeDetailsToDisplayValuesUsingSmartContractCalculation(props);
  }
};

const convertPrizeDetailsToDisplayValuesUsingSmartContractCalculation = (props: PrizeDetails): DisplayValues => {
  const daoBalance = new BN(props.daoBalance);
  const baseAwardPerContestant = daoBalance.mul(new BN(props.awardPerContestantPerMille)).divn(1000);
  const baseAward = baseAwardPerContestant.muln(props.contestantsCount);
  const potentialBonusAward = props.totalVotesCount > 0 ? baseAward.muln(props.newVotersCount).divn(props.totalVotesCount) : new BN(0);
  const bonusAward = props.totalVotesCount > props.minVotesForBonus ? potentialBonusAward : new BN(0);
  let totalAward = baseAward.add(bonusAward);
  let maxAward = baseAward.muln(2);

  const maxAllowedAward = daoBalance.muln(props.maxAwardPercentageOfBalance).divn(100);
  if (totalAward.gt(maxAllowedAward)) {
    totalAward = maxAllowedAward;
  }
  if (maxAward.gt(maxAllowedAward)) {
    maxAward = maxAllowedAward;
  }

  return {
    totalApolloPrize: makeApolloDisplayString(totalAward),
    baseApolloPrize: makeApolloDisplayString(baseAward),
    maxApolloPrize: makeApolloDisplayString(maxAward),

    totalUSDPrize: makeUSDDisplayStringFromApollo(totalAward, props.usdPerApollo),
    baseUSDPrize: makeUSDDisplayStringFromApollo(baseAward, props.usdPerApollo),
    maxUSDPrize: makeUSDDisplayStringFromApollo(maxAward, props.usdPerApollo),

    prizeTiers: makeApolloPrizeTiers(totalAward, props),

    progressValue: props.newVotersCount,
    progressMax: props.totalVotesCount,
  };
};

export const convertPrizeDetailsToDisplayValuesUsingUSDOverrides = (props: PrizeDetails): DisplayValues => {
  const totalAwardInUSD = props.usdPrizeOverrides.reduce((result, override) => result + override.amount, 0);
  const totalAwardInApollo = apolloAmountFromUSD(totalAwardInUSD, props.usdPerApollo);
  const totalApolloDisplayString = totalAwardInApollo != null ? makeApolloDisplayString(totalAwardInApollo) : '-- APOLLO';
  const totalUSDDisplayString = makeUSDLocaleString(totalAwardInUSD);

  return {
    totalApolloPrize: totalApolloDisplayString,
    baseApolloPrize: '-- APOLLO',
    maxApolloPrize: '-- APOLLO',

    totalUSDPrize: totalUSDDisplayString,
    baseUSDPrize: '$--',
    maxUSDPrize: '$--',

    prizeTiers: makeUSDOverridePrizeTiers(props),

    progressValue: 0,
    progressMax: 100,
  };
};

// Helper functions

const makeApolloPrizeTiers = (totalAward: BN, details: PrizeDetails): PrizeTier[] => {
  const cashStringTransform = (awardPerMille: number): string | null => {
    if (awardPerMille <= 0) return null;
    const prize = totalAward.muln(awardPerMille).divn(1000);
    return makeUSDDisplayStringFromApollo(prize, details.usdPerApollo);
  };

  return makePrizeTiers(details.awardTiersPerMille, details.nonApolloPrizes, cashStringTransform);
};

const makeUSDOverridePrizeTiers = (details: PrizeDetails): PrizeTier[] => {
  const cashStringTransform = (override: USDPrizeOverride): string | null => {
    if (override.amount <= 0) return null;
    return makeUSDLocaleString(override.amount);
  };

  return makePrizeTiers(details.usdPrizeOverrides, details.nonApolloPrizes, cashStringTransform);
};

const makePrizeTiers = <T>(
  cashAwards: T[],
  nonApolloPrizes: NonApolloPrize[],
  cashStringTransform: (award: T) => string | null
): PrizeTier[] => {
  const tiers: PrizeTier[] = [];

  const prizeCount = Math.max(cashAwards.length, nonApolloPrizes.length);
  for (let index = 0; index < prizeCount; index++) {
    let prizeString = '';

    // Cash prize string
    if (cashAwards.length > index) {
      const transformed = cashStringTransform(cashAwards[index]);
      if (transformed != null && transformed.length > 0) {
        prizeString = transformed;
      }
    }

    // Non-APOLLO prize string
    if (nonApolloPrizes.length > index && nonApolloPrizes[index].text.length > 0) {
      const nonApolloPrizeString = nonApolloPrizes[index].text;
      prizeString = prizeString.length > 0 ? `${prizeString} + ${nonApolloPrizeString}` : nonApolloPrizeString;
    }

    if (prizeString.length > 0) {
      tiers.push({
        label: labelForIndex(index),
        value: prizeString,
      });
    }
  }

  return tiers;
};

const labelForIndex = (index: number): string => {
  switch (index) {
    case 0:
      return 'First Place';
    case 1:
      return 'Second Place';
    case 2:
      return 'Third Place';
    case 3:
      return 'Fourth Place';
    case 4:
      return 'Fifth Place';
    case 5:
      return 'Sixth Place';
    case 6:
      return 'Seventh Place';
    default:
      return `Position ${index + 1}`;
  }
};

const makeApolloDisplayString = (value: BN): string => {
  const decimals = new BN(1000000000); // APOLLO has 9 decimals
  const oneBillion = new BN(1000000000).mul(decimals);
  const oneMillion = new BN(1000000).mul(decimals);

  if (value.gte(oneBillion)) {
    const number = value.divRound(oneBillion.divn(100)).toNumber() / 100;
    return `${number.toFixed(1)}B APOLLO`;
  } else if (value.gte(oneMillion)) {
    const number = value.divRound(oneMillion.divn(100)).toNumber() / 100;
    return `${number.toFixed(1)}M APOLLO`;
  } else {
    const number = value.divRound(decimals.divn(100)).toNumber() / 100;
    return `${number.toFixed(1)} APOLLO`;
  }
};

const makeUSDDisplayStringFromApollo = (apollo: BN, usdPerApollo: number | null): string => {
  if (usdPerApollo == null) {
    return '$--';
  }

  // usd = apollo * usdcPerApollo
  const decimals = new BN(1000000000); // APOLLO has 9 decimals
  const paddingFactor = 1000000;
  const paddedConversion = new BN(usdPerApollo * paddingFactor);
  const usdWithDecimals = apollo.mul(paddedConversion).divn(paddingFactor);

  const usd = usdWithDecimals.divRound(decimals).toNumber();
  return makeUSDLocaleString(usd);
};

const apolloAmountFromUSD = (usd: number, usdPerApollo: number | null): BN | null => {
  if (usdPerApollo == null) {
    return null;
  }

  // apollo = usd / usdPerApollo
  const decimals = new BN(1000000000); // APOLLO has 9 decimals
  const paddingFactor = 1000000;
  const paddedConversion = new BN(usdPerApollo * paddingFactor);
  const apollo = new BN(usd * paddingFactor).mul(decimals).divRound(paddedConversion);
  return apollo;
};

const makeUSDLocaleString = (amount: number): string =>
  amount.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 });
