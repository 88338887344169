import { FC } from 'react';
import { Modal } from './Modal';
import styled from 'styled-components';
import { ProfilePic } from './ProfilePic';
import { useGAModalViewTracking } from '../hooks/analytics';
import { CancelConfirmButtonStack } from './CancelConfirmButtonStack';
import { CreatorProfileResponse } from '../shared/src/CreatorProfile';

interface Props {
  profile?: CreatorProfileResponse;
  isShown: boolean;
  shouldClose(): void;
  didClose(): void;
  onConfirm(): void;
}

export const CastVoteModal: FC<Props> = ({ profile, ...props }) => {
  useGAModalViewTracking(props.isShown, 'vote');
  return (
    <Modal contentWidth="520px" {...props}>
      <Content>
        <ProfilePic profilePictureURL={profile?.profilePictureURL} />
        <TextContent>
          <h1>You are voting for {profile?.name}</h1>
          <p>
            Once your vote is cast it can not be changed. Click <i>Confirm</i> to continue or <i>Cancel</i> to return.
          </p>
        </TextContent>
        <CancelConfirmButtonStack {...props} />
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 0px 40px;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  h1 {
    text-align: center;
    margin: 0;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #002106;
  }

  p {
    text-align: center;
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #526350;
    padding: 0px 25px;

    i {
      font-style: italic;
    }
  }
`;
