import { FC } from 'react';
import { CallToActionBox, CTAContext } from './CallToActionBox';
import { ContestantQueueInfoBox } from './ApplyToContest/ContestantQueueInfoBox';
import { WithId } from '../shared/src/WithId';
import { Series } from '../shared/src/Series';
import { Contest } from '../shared/src/Contest';

interface Props {
  context: CTAContext;
  pastContestCTA?: JSX.Element;
  series?: WithId<Series>;
  contest?: WithId<Contest>;
}

export const ContextSpecificCTA: FC<Props> = ({ context, pastContestCTA, series, contest }) => {
  if (context == 'applying') {
    return <ContestantQueueInfoBox context="applying" series={series} />;
  } else if (context == 'pastContest' && pastContestCTA != null) {
    return pastContestCTA;
  } else {
    return <CallToActionBox context={context} contest={contest} />;
  }
};
