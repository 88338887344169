import React, { FC } from 'react';
import styled from 'styled-components';
import { ProfilePic } from './ProfilePic';
import { effectiveMobileMaxWidth } from '../shared/src/ui/Constants';
import { VoteButton } from './VoteButton';
import { WinnerStar } from '../svg/WinnerStar';

export interface ProfileCardProps {
  name?: string;
  profilePictureURL?: string;
  creatorType?: string;
  votePoints?: number;
  hideVoteButton?: boolean;
  /// The rank this contestant achieved in the past contest
  pastContestRank?: number;
  onClickProfileLink?(): void;
  onClickVote?(): void;
}

export const ProfileCard: FC<ProfileCardProps> = ({
  name,
  profilePictureURL,
  creatorType,
  pastContestRank,
  onClickProfileLink,
  ...rest
}) => {
  const _name = name && name.length > 0 ? name : 'Full name';
  const _creatorType = creatorType && creatorType.length > 0 ? creatorType : 'Creator type';
  const borderColorHex = borderColorHexForRank(pastContestRank);

  return (
    <CardContainer onClick={onClickProfileLink} borderColorHex={borderColorHex}>
      {pastContestRank && <RankBadge pastContestRank={pastContestRank} borderColorHex={borderColorHex} />}
      <LeadingElementsContainer>
        <ProfileImage profilePictureURL={profilePictureURL} />
        <LabelContainer>
          <NameLabel>{_name}</NameLabel>
          <SkillLabel>{_creatorType}</SkillLabel>
        </LabelContainer>
        <ProfileLink onClick={(event) => profileLinkClickHandler(event, onClickProfileLink)}>View Profile</ProfileLink>
      </LeadingElementsContainer>

      <VoteButtonOrCount {...rest} />
    </CardContainer>
  );
};

interface RankBadgeProps {
  pastContestRank: number;
  borderColorHex: string;
}

const RankBadge: FC<RankBadgeProps> = ({ pastContestRank, borderColorHex }) => {
  const isRanked = pastContestRank <= 3;
  return (
    <StyledRankBadge isRanked={isRanked} borderColorHex={borderColorHex}>
      {isRanked && <StyledWinnerStar colorHex={borderColorHex} />}
      <RankLabelBox isRanked={isRanked} borderColorHex={borderColorHex}>
        <h2>{pastContestRank}</h2>
      </RankLabelBox>
    </StyledRankBadge>
  );
};

interface VoteButtonOrCountProps {
  votePoints?: number;
  hideVoteButton?: boolean;
  onClickVote?(): void;
}

const VoteButtonOrCount: FC<VoteButtonOrCountProps> = ({ votePoints, hideVoteButton, onClickVote }) => {
  const votes = votePoints ?? 0;
  const votesText = votes == 1 ? '1 vote point' : `${votes} vote points`;

  if (hideVoteButton) {
    return <NumberOfVotesLabel>{votesText}</NumberOfVotesLabel>;
  } else {
    return <VoteButton onClick={(event: React.UIEvent) => voteButtonClickHandler(event, onClickVote)} />;
  }
};

// Helper functions

const profileLinkClickHandler = (event: React.UIEvent, onClickProfileLink: (() => void) | undefined) => {
  event.stopPropagation();
  onClickProfileLink && onClickProfileLink();
};

const voteButtonClickHandler = (event: React.UIEvent, onClickVote: (() => void) | undefined) => {
  event.stopPropagation();
  onClickVote && onClickVote();
};

const borderColorHexForRank = (pastContestRank: number | undefined): string => {
  switch (pastContestRank) {
    case 1:
      return '#f9b833';
    case 2:
      return '#a1a9bf';
    case 3:
      return '#c9a0a4';
    default:
      return '#e1e5e8';
  }
};

// Styled components

interface PastContestProps {
  borderColorHex: string;
}

const CardContainer = styled.div<PastContestProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  padding: 32px 0px;
  background-color: #ffffff;
  border-radius: 32px;
  border: ${(props) => `1px solid ${props.borderColorHex}`};

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    flex-direction: row;
    gap: 8px;
    border-radius: 6px;
    padding: 16px;

    &:hover {
      cursor: pointer;
    }
  }
`;

/// This container groups the leading elements together: Profile pic,
/// both labels, and the profile link. This way, the parent flexbox can
/// use `justify-content: space-between;` on mobile layout.
const LeadingElementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    flex-direction: row;
    gap: 8px;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    align-items: flex-start;
  }
`;

const ProfileImage = styled(ProfilePic)`
  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    width: 40px;
    height: 40px;
  }
`;

const NameLabel = styled.h1`
  font-weight: 400;
  font-size: 22px;
  color: #002106;
  margin: 0;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    font-weight: 600;
    font-size: 14px;
  }
`;

const SkillLabel = styled.h2`
  font-weight: 400;
  font-size: 12px;
  color: #72796f;
  margin: 0;
`;

const ProfileLink = styled.a`
  font-weight: 400;
  font-size: 13px;
  color: #00c648;

  &:hover,
  &:focus {
    text-decoration: underline;
    cursor: pointer;
  }

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    display: none;
  }
`;

const NumberOfVotesLabel = styled.h2`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #002106;
  padding: 8px 14px;
  margin: 0;
`;

interface StyledRankBadgeProps {
  /// Whether the contestant is ranked in the top three
  isRanked: boolean;
  borderColorHex: string;
}

const StyledRankBadge = styled.div<StyledRankBadgeProps>`
  position: absolute;
  top: 24px;
  left: 24px;

  width: 42px;
  height: 42px;

  border: ${(props) => (!props.isRanked ? `1px solid ${props.borderColorHex}` : 'none')};
  border-radius: ${(props) => (!props.isRanked ? '50%' : '0')};

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    display: none;
  }
`;

const StyledWinnerStar = styled(WinnerStar)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

interface RankLabelProps {
  isRanked: boolean;
  borderColorHex: string;
}

const RankLabelBox = styled.div<RankLabelProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    margin: 0;
    font-size: 16px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on, 'salt' on;
    color: ${(props) => (props.isRanked ? props.borderColorHex : '#3e3e3e')};
  }
`;
