import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { effectiveMaxWidth, effectiveMobileMaxWidth, padding } from '../../shared/src/ui/Constants';
import apolloLogo from '../../svg/apollo.svg';
import HideOnMobile from '../HideOnMobile';
import { ConnectWalletOrProfileButton } from '../Wallet/ConnectWalletOrProfileButton';
import { QueueInfo } from '../../hooks/useQueuePosition';

interface Props {
  queueInfo: QueueInfo;
}

export const NavBar: FC<Props> = ({ queueInfo }) => {
  const navigate = useNavigate();
  // Only show edit button if user is queued
  const showEdit = queueInfo.response?.queuePosition != null;

  const applyOrEditRoute = showEdit ? '/edit-profile' : '/apply/main';
  const applyOrEditText = showEdit ? 'Edit Profile' : 'Apply';

  return (
    <>
      <Background>
        <Content>
          <Links>
            <Link to="/">
              <ApolloLogo />
            </Link>
            <StyledTextLink to="/">
              <PageTitle>Apollo Creator Contest</PageTitle>
              <MobilePageTitle>Apollo</MobilePageTitle>
            </StyledTextLink>

            <NavDivider />

            <HideOnMobile>
              <NavLink onClick={() => navigate('/')}>Vote</NavLink>
              <NavLink onClick={() => navigate(applyOrEditRoute)}>{applyOrEditText}</NavLink>
              <NavLink onClick={() => navigate('/contests')}>Contests</NavLink>
              <NavLink onClick={openExternalHelpPage}>Need Help?</NavLink>
            </HideOnMobile>
          </Links>

          <ConnectWalletOrProfileButton queueInfo={queueInfo} />
        </Content>
      </Background>
      <Divider />
    </>
  );
};

const openExternalHelpPage = () => window.open('https://www.apollocrypto.org/docs-creator-contest', '_blank');

const StyledTextLink = styled(Link)`
  text-decoration: none;
  color: #002106;
`;

export const ApolloLogo: FC = () => <StyledApolloLogo src={apolloLogo} alt="Logo" />;

const Background = styled.div`
  background-color: #ffffff;
`;

const Divider = styled.div`
  background-color: #e5e5e5;
  height: 1px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin: auto;
  max-width: ${effectiveMaxWidth()}px;
  padding: 8px ${padding}px;
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledApolloLogo = styled.img`
  width: 64px;
  height: 64px;
  margin-right: 16px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }
`;

const PageTitle = styled.h1`
  font-weight: 700;
  font-size: 15px;
  margin-right: 32px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    display: none;
  }
`;

const MobilePageTitle = styled(PageTitle)`
  display: none;
  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    display: initial;
  }
`;

const NavDivider = styled.div`
  width: 1px;
  height: 24px;
  background-color: #dde1e4;
  margin-right: 32px;
`;

interface NavLinkProps {
  highlighted?: boolean;
}

const NavLink = styled.a<NavLinkProps>`
  font-weight: 500;
  font-size: 15px;
  color: ${(props) => (props.highlighted ? '#00C648' : '#002106')};
  margin-right: 40px;
  &:hover,
  &:focus {
    text-decoration: underline;
    cursor: pointer;
  }
`;
