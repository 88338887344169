import { useEffect } from 'react';
import axios from 'axios';
import { useWeb3React } from '@web3-react/core';
import { ImageType } from 'react-images-uploading';
import { useFormikContext } from 'formik';
import { isSuccessfulStatus } from '../helpers/http-status';
import { CreatorProfileResponse, CreatorProfileFormDetails } from '../shared/src/CreatorProfile';

const apiBaseURL = process.env.REACT_APP_BASE_URL;

export type FormDetails = Required<CreatorProfileFormDetails> & {
  customCreatorType: string;
  profileImage?: ImageType;
  // If the profile already contains a profile picture URL, it will be set here.
  profilePictureURL?: string;
  ageCheck: boolean;
  engagementCheck: boolean;
  termsCheck: boolean;
};

export const initialFormValues: FormDetails = {
  name: '',
  emailAddress: '',
  country: 'Country', // default
  creatorType: 'Creator Type', // default
  creatorStatement: '',
  fundsUsagePlan: '',
  youTubeVideoURL: '',
  websiteURL: '',
  twitterURL: '',
  facebookURL: '',
  instagramURL: '',
  youTubeChannelURL: '',
  creatorImageURL: '',
  openSeaURL: '',
  redditURL: '',
  linkedInURL: '',
  tikTokURL: '',
  otherSocialURL: '',
  customCreatorType: '',
  ageCheck: false,
  engagementCheck: false,
  termsCheck: false,
};

export const allCreatorTypes = [
  'Add custom text',
  'Painting',
  'Drawing',
  'Sculpture',
  'Literature',
  'Architecture',
  'Cinema',
  'Music',
  'Theater',
  'Dance',
  'Decorative',
  'Calligraphy',
  'Photography',
  'Printmaking',
  'Fashion',
  'Ceramics',
  'Crafts',
  'Digital',
  'Content',
  'Multimedia',
  'Culinary',
];

export const useNominationFormDetails = (): void => {
  const { setValues } = useFormikContext<FormDetails>();
  const { account } = useWeb3React();

  useEffect(() => {
    if (account != null) {
      fetchProfile(account).then((fetched) => {
        if (fetched != null) {
          console.log(`merging fields...`);
          const merged = mergeDetails(initialFormValues, fetched);
          setValues(merged);
        }
      });
    }
  }, [account]);
};

const fetchProfile = async (account: string): Promise<CreatorProfileResponse | null> => {
  try {
    const response = await axios.get(`${apiBaseURL}/profiles/address/${account}`, { validateStatus: () => true });
    if (isSuccessfulStatus(response.status)) {
      return response.data.data;
    } else {
      return null;
    }
  } catch (err) {
    console.error(`Error fetching past nominee details: ${err}`);
    return null;
  }
};

const mergeDetails = (initial: FormDetails, pastDetails: CreatorProfileResponse): FormDetails => {
  const { address, _id, ..._pastDetails } = pastDetails;
  const result = { ...initial };

  if (!allCreatorTypes.includes(_pastDetails.creatorType)) {
    result.creatorType = 'Add custom text';
    result.customCreatorType = _pastDetails.creatorType;
    _pastDetails.creatorType = '';
  }

  let key: keyof typeof _pastDetails;
  for (key in _pastDetails) {
    const value = _pastDetails[key];
    if (value != null && value.length > 0) {
      result[key] = value;
    }
  }

  return result;
};
