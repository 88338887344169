import { FC, useRef, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { Modal } from '../Modal';
import { CreatorProfileResponse } from '../../shared/src/CreatorProfile';
import { EmailVotePayload } from '../../shared/src/EmailVotePayload';
import { toast } from 'react-toastify';
import { UseModalResult } from '../../hooks/useModal';
import { APIResponse } from '../../shared/src/APIResponse';
import { clickedSendLinkFromEmailModalEvent, trackEvent, useGAModalViewTracking } from '../../hooks/analytics';
import { EmailVoteModalContents } from '../../shared/src/ui/EmailVoteModalContents';

const apiBaseURL = process.env.REACT_APP_BASE_URL as string;

export type EmailVoteModalProps = Omit<UseModalResult<CreatorProfileResponse>, 'showModal'>;

export const EmailVoteModal: FC<EmailVoteModalProps> = ({ isShowingModal, value, shouldClose, didClose }) => {
  useGAModalViewTracking(isShowingModal, 'vote-with-email');
  const [alreadyVoted, setAlreadyVoted] = useState(false);

  const emailFieldRef = useRef<HTMLInputElement | null>(null);
  const submitVote = () => {
    const email = emailFieldRef.current?.value;
    if (value == null || email == null || email.length === 0) {
      console.error('The profile is null, or the email is null/empty');
      return;
    }

    // Track an event when the button is clicked
    trackEvent(clickedSendLinkFromEmailModalEvent());
    voteForProfileWithEmail(email, value).then((result) => {
      switch (result) {
        case 'success':
        case 'failure':
          shouldClose();
          break;
        case 'already-voted':
          setAlreadyVoted(true);
          break;
      }
    });
  };

  const modalDidClose = () => {
    if (emailFieldRef.current) {
      emailFieldRef.current.value = '';
    }
    setAlreadyVoted(false);
    didClose();
  };

  return (
    <Modal isShown={isShowingModal} contentWidth="500px" shouldClose={shouldClose} didClose={modalDidClose}>
      <EmailVoteModalContents
        emailFieldRef={emailFieldRef}
        alreadyVoted={alreadyVoted}
        submitVote={submitVote}
        clearAlreadyVoted={() => setAlreadyVoted(false)}
      />
    </Modal>
  );
};

// Helper functions

type VoteResult = 'success' | 'already-voted' | 'failure';

const voteForProfileWithEmail = async (email: string, profile: CreatorProfileResponse): Promise<VoteResult> => {
  try {
    const payload: EmailVotePayload = {
      email,
      profileId: profile._id,
    };
    const response: AxiosResponse<APIResponse<any>> = await axios.post(`${apiBaseURL}/votes/vote-with-email`, payload, {
      validateStatus: () => true,
    });

    if (response.status >= 200 && response.status < 300) {
      toast.success('Check your email to finish voting');
      return 'success';
    } else if (response.status === 409) {
      return 'already-voted';
    } else {
      toast.error(response.data.message ?? 'Failed to send confirmation email');
      return 'failure';
    }
  } catch (err) {
    console.error(`Error voting with email: ${err}`);
    toast.error('Failed to send confirmation email');
    return 'failure';
  }
};
