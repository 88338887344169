import { FieldHookConfig, FieldHelperProps, useField } from 'formik';
import { useEffect } from 'react';
import { FC, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Pixels, TextFieldLabel, CaptionNote } from './LabeledField';

interface Props {
  label: string;
  captionNote?: string;
  baseURL: string;
  placeholder: string;
  iconSource?: string;
  marginTop?: Pixels;
}

type PropsExt = Props & FieldHookConfig<string>;

export const URLPrefixTextField: FC<PropsExt> = ({ label, captionNote, baseURL, placeholder, iconSource, marginTop, ...props }) => {
  const fieldRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [field, , helpers] = useField(props);
  useTrimmedFieldValue(field.value, baseURL, helpers);

  return (
    <>
      <TextFieldLabel marginTop={marginTop} htmlFor={props.id || props.name}>
        {label}
      </TextFieldLabel>
      <Container onClick={() => fieldRef.current?.focus()} isFocused={isFocused}>
        {iconSource && <TextFieldIcon src={iconSource} alt={baseURL} />}
        <p>{baseURL}</p>
        <Divider />
        <TextField
          {...field}
          {...props}
          ref={fieldRef}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          placeholder={placeholder}
          size="1"
        />
      </Container>
      {captionNote && <CaptionNote>{captionNote}</CaptionNote>}
    </>
  );
};

// If the user tries to enter the full URL, remove the prefix
const useTrimmedFieldValue = (value: string, baseURL: string, helpers: FieldHelperProps<string>) => {
  const additionalBaseURL = baseURLWithWWW(baseURL);
  useEffect(() => {
    if (value.startsWith(baseURL)) {
      const trimmedString = value.substring(baseURL.length);
      helpers.setValue(trimmedString);
    } else if (value.startsWith(additionalBaseURL)) {
      const trimmedString = value.substring(additionalBaseURL.length);
      helpers.setValue(trimmedString);
    }
  }, [value]);
};

// If a base URL does not contain "www.", insert it and return it. e.g.
// "http://twitter.com" becomes "http://www.twitter.com"
const baseURLWithWWW = (baseURL: string): string => {
  const withoutWWW = 'https://';
  const withWWW = 'https://www.';
  if (baseURL.startsWith(withWWW)) {
    return baseURL;
  } else if (baseURL.startsWith(withoutWWW)) {
    return `${withWWW}${baseURL.substring(withoutWWW.length)}`;
  } else {
    return baseURL;
  }
};

const textCSS = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

interface ContainerProps {
  isFocused: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 0px;

  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  border-color: ${(props) => (props.isFocused ? '#68ff85' : '#d0d5dd')};

  background-color: #ffffff;
  ${(props) => (props.isFocused ? 'box-shadow: 0px 0px 0px 4px rgba(104, 255, 133, 0.2);' : '')}
  transition: box-shadow 200ms ease, border-color 200ms ease;

  p {
    ${textCSS}
    margin: 0;
    padding: 10px 14px 10px 9px;
    color: #d0d5dd;
    user-select: none;
  }
`;

const TextField = styled.input`
  ${textCSS}
  padding: 10px 14px;
  flex-grow: 1;
  color: #101828;
  border: none;
  background-color: transparent;

  ::placeholder {
    color: #667085;
  }

  &:focus {
    outline: none;
  }
`;

const Divider = styled.div`
  background-color: #d0d5dd;
  width: 1px;
`;

const TextFieldIcon = styled.img`
  pointer-events: none;
  margin-left: 14px;
`;
