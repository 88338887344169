import { useEffect, useState } from 'react';

export interface UseModalResult<T> {
  value: T | null;
  isShowingModal: boolean;
  showModal(value: T): void;
  shouldClose(): void;
  didClose(): void;
}

// A generic hook for controlling the display of a modal
export const useModal = <T>(): UseModalResult<T> => {
  const [value, setValue] = useState<T | null>(null);
  const [isShowingModal, setIsShowingModal] = useState(false);

  useEffect(() => {
    if (value != null && isShowingModal === false) {
      setIsShowingModal(true);
    }
  }, [value]);

  return {
    value,
    isShowingModal,
    showModal: (value) => setValue(value),
    shouldClose: () => setIsShowingModal(false),
    didClose: () => setValue(null),
  };
};

export interface UseModalWithoutValueResult {
  isShowingModal: boolean;
  showModal(): void;
  shouldClose(): void;
}

/// A simpler modal hook that does not rely on a `value` property
export const useModalWithoutValue = (): UseModalWithoutValueResult => {
  const [isShowingModal, setIsShowingModal] = useState(false);

  return {
    isShowingModal,
    showModal: () => setIsShowingModal(true),
    shouldClose: () => setIsShowingModal(false),
  };
};
