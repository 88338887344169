import { FC } from 'react';
import { useContests } from '../hooks/useContests';
import { AllContests } from './AllContests/AllContests';
import { CurrentContest } from './Contest/CurrentContest';
import { WatchToVoteModal } from './Voting/WatchToVoteModal';

interface Props {
  verifyEmail?: boolean;
}

/// The conditional site landing page, which shows either the current contest if one
/// is in happening, or the "All Contests" page.
export const LandingPage: FC<Props> = ({ verifyEmail }) => (
  <>
    {verifyEmail && <WatchToVoteModal />}
    <LandingPageContents />
  </>
);

export const LandingPageContents: FC = () => {
  const contests = useContests();

  if (contests == null) {
    return null;
  } else if (contests.activeContest == null) {
    return <AllContests contests={contests} />;
  } else {
    return <CurrentContest contest={contests.activeContest} />;
  }
};
