import { FC, useContext, useEffect } from 'react';
import { useGAModalViewTracking } from '../hooks/analytics';
import { AppContext } from '../hooks/context';
import { ProfileModal, setProfileModal } from '../hooks/state';
import { useModal } from '../hooks/useModal';
import { YouVotedModalContent } from '../shared/src/ui/YouVotedModalContent';
import { Modal } from './Modal';

export const YouVotedModal: FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const profile = state.externalLink == null && state.profileModal?.type === 'profile' ? state.profileModal : null;
  const modalProps = useModal<ProfileModal>();
  useGAModalViewTracking(modalProps.isShowingModal, 'you-voted');

  useEffect(() => {
    if (modalProps.value == null && profile != null) {
      modalProps.showModal(profile);
    }
  }, [modalProps, profile]);

  const didClose = () => {
    dispatch(setProfileModal(null));
    modalProps.didClose();
  };

  return (
    <Modal contentWidth="520px" isShown={modalProps.isShowingModal} shouldClose={modalProps.shouldClose} didClose={didClose}>
      {modalProps.value && (
        <YouVotedModalContent
          imageURL={modalProps.value.creatorProfilePicURL}
          contestantName={modalProps.value.creatorName}
          contestantAddress={modalProps.value.creatorAddress}
        />
      )}
    </Modal>
  );
};
