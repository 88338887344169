import React, { useEffect, useRef } from 'react';

interface Results {
  buttonRef: React.MutableRefObject<any>;
  menuRef: React.MutableRefObject<any>;
}

/// Hook that calls a callback function if the user clicks outside of the provided ref
export const useClickOutside = (callback: () => void): Results => {
  const buttonRef: React.MutableRefObject<any> = useRef(null);
  const menuRef: React.MutableRefObject<any> = useRef(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (!buttonRef.current || !menuRef.current) {
        return;
      }

      // Call the provided callback function if clicked outside
      if (!buttonRef.current.contains(event.target) && !menuRef.current.contains(event.target)) {
        callback();
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClick);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClick);
    };
  }, [buttonRef, menuRef]);

  return { buttonRef, menuRef };
};
