import { useWeb3React } from '@web3-react/core';
import { FC, useContext } from 'react';
import styled from 'styled-components';
import { effectiveMobileMaxWidth } from '../../shared/src/ui/Constants';
import { AppContext } from '../../hooks/context';
import { setShouldShowConnectWalletModal } from '../../hooks/state';
import { Account } from './ConnectWalletModal';

type FontSize = `${number}px` | `${number}%` | `${number}em` | `${number}rem`;

interface ButtonProps {
  fontSize?: FontSize;
}

export const ConnectWalletButton: FC<ButtonProps> = (props) => {
  const { active, account } = useWeb3React();
  const { dispatch } = useContext(AppContext);
  const showWalletModal = () => dispatch(setShouldShowConnectWalletModal(true));

  return (
    <ConnectButton type="button" onClick={showWalletModal} {...props}>
      {active && <GreenDot />}
      {getConnectWalletText(account, active)}
    </ConnectButton>
  );
};

// Helper functions

const getConnectWalletText = (account: Account, active: boolean): string =>
  active && account ? account.substring(0, 8) : 'Connect Wallet';

// Styled components

const ConnectButton = styled.button<ButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => props.fontSize ?? '15px'};
  color: #002106;
  padding: 12px 16px;
  background-color: white;
  border: 1px solid #e1e5e8;
  border-radius: 4px;

  &:hover {
    background-color: #f2f4f7;
    cursor: pointer;
  }

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    border-radius: 6px;
  }
`;

const GreenDot = styled.span`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #00d954;
`;
