import { FC, useState } from 'react';
import styled from 'styled-components';
import YouTube, { YouTubeProps } from 'react-youtube';
import arrowRightSVG from '../svg/button-arrow-right.svg';

interface Props {
  youtubeVideoId: string;
  onContinueClicked(): void;
  didWatchVideoToEnd(): void;
}

export const WatchToVoteContents: FC<Props> = ({ youtubeVideoId, onContinueClicked, didWatchVideoToEnd }) => {
  const [isContinueDisabled, setIsContinueDisabled] = useState(true);

  const videoOptions: YouTubeProps['opts'] = {
    height: '480',
    width: '270',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      controls: 0, // Don't show the player controls
      disablekb: 1, // Don't allow the user to control the video with keyboard
    },
  };

  const onPlayerEnd: YouTubeProps['onEnd'] = () => {
    setIsContinueDisabled(false);
    didWatchVideoToEnd();
  };

  return (
    <Container>
      <h1>Watch the video to cast your vote</h1>
      <h2>Watch this short clip about how Apollo works.</h2>
      <VideoContainer>
        <YouTube videoId={youtubeVideoId} opts={videoOptions} onEnd={onPlayerEnd} />
      </VideoContainer>
      <ContinueButton disabled={isContinueDisabled} onClick={() => onContinueClicked()}>
        <h1>Cast Vote</h1>
        <img src={arrowRightSVG} alt="Arrow" />
      </ContinueButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  padding: 0px 40px;
  background-color: #ffffff;

  & > h1 {
    margin: 0;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: flex-end;
    color: #002106;
  }

  & > h2 {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #72796f;
  }
`;

const VideoContainer = styled.div`
  aspect-ratio: 480 / 270;
  background-color: lightgray;
  margin-top: 16px;

  div,
  iframe {
    width: 100%;
    height: 100%;
  }
`;

const ContinueButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 12px 16px;

  background: #0052ff;
  border: 1px solid #0052ff;
  border-radius: 3px;
  transition: all 200ms ease-in-out;

  & > h1 {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #ffffff;
  }

  &:hover:not([disabled]) {
    opacity: 0.8;
    cursor: pointer;
  }

  &:disabled {
    background: rgba(0, 82, 255, 0.2);
    border: 1px solid rgba(0, 82, 255, 0.2);
  }
`;
