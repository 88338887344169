import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { PositionResponse } from '../../shared/src/Queue';
import { AppContext } from '../../hooks/context';
import { setShouldShowConnectWalletModal } from '../../hooks/state';
import { QueueInfo } from '../../hooks/useQueuePosition';
import walletSVG from '../../svg/wallet.svg';
import { ProfilePic } from '../ProfilePic';

interface Props {
  queueInfo: QueueInfo;
}

/// The container for all possible wallet buttons in the nav
export const WalletButtons: FC<Props> = ({ queueInfo }) => {
  const { address, response } = queueInfo;

  if (address != null) {
    return <WalletAndQueue address={address} response={response} />;
  } else {
    return <ConnectWalletButton />;
  }
};

interface WalletAndQueueProps {
  address: string;
  response: PositionResponse | null;
}

/// The container for the state where a wallet is connected
const WalletAndQueue: FC<WalletAndQueueProps> = ({ address, response }) => (
  <WalletAndQueueContainer>
    {response && <ProfileButton address={address} response={response} />}
    <WalletButton />
  </WalletAndQueueContainer>
);

interface ProfileButtonProps {
  address: string;
  response: PositionResponse;
}

/// The profile button, which may also contain the queue position
const ProfileButton: FC<ProfileButtonProps> = ({ address, response }) => {
  const { name, profilePictureURL, queuePosition } = response;
  const navigate = useNavigate();
  return (
    <StyledProfileButton onClick={() => navigate(`/profile/${address}`)}>
      <ProfilePic name={name} profilePictureURL={profilePictureURL} width="24px" />
      {queuePosition && <p>#{queuePosition}</p>}
    </StyledProfileButton>
  );
};

/// The wallet button, which opens the wallet modal
const WalletButton: FC = () => {
  const { dispatch } = useContext(AppContext);
  const showWalletModal = () => dispatch(setShouldShowConnectWalletModal(true));
  return (
    <StyledWalletButton onClick={showWalletModal}>
      <img src={walletSVG} alt="Wallet" />
    </StyledWalletButton>
  );
};

/// The "Connect Wallet" button, which displays when a wallet isn't connected yet.
const ConnectWalletButton: FC = () => {
  const { dispatch } = useContext(AppContext);
  const showWalletModal = () => dispatch(setShouldShowConnectWalletModal(true));
  return <StyledConnectWalletButton onClick={showWalletModal}>Connect Wallet</StyledConnectWalletButton>;
};

// Styled components

const buttonCSS = css`
  border: 1px solid #e1e5e8;
  border-radius: 3px;
  background-color: #ffffff;
  min-width: 40px;

  &:hover {
    background-color: #f2f4f7;
    cursor: pointer;
  }
`;

const StyledConnectWalletButton = styled.button`
  ${buttonCSS}

  margin: 0;
  padding: 10px 12px;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #002106;
  white-space: nowrap;
`;

const WalletAndQueueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 8px;
`;

const StyledWalletButton = styled.button`
  ${buttonCSS}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledProfileButton = styled.button`
  ${buttonCSS}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 0px 8px;

  p {
    margin: 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #002106;
  }
`;
