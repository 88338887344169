import { FC, PropsWithChildren, useRef } from 'react';
import styled from 'styled-components';
import xButton from '../svg/x-button.svg';
import { CSSTransition } from 'react-transition-group';
import { effectiveMobileMaxWidth, padding } from '../shared/src/ui/Constants';

type Width = `${number}px`;

export interface ModalProps {
  isShown: boolean;
  title?: string;
  contentWidth?: Width;
  fullScreenOnMobile?: boolean;
  shouldClose(): void;
  willClose?(): void;
  didClose?(): void;
}

export const TRANSITION_DURATION = 300;

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  isShown,
  title,
  contentWidth,
  fullScreenOnMobile,
  shouldClose,
  willClose,
  didClose,
  children,
}) => {
  const nodeRef = useRef(null);
  // Border radius is smaller when a title is used
  const borderRadius: Width = title == null ? '20px' : '10px';

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={isShown}
      timeout={TRANSITION_DURATION}
      classNames="modal"
      unmountOnExit
      onExit={() => willClose && willClose()}
      onExited={() => didClose && didClose()}
    >
      <StyledModal ref={nodeRef} fullScreenOnMobile={fullScreenOnMobile}>
        <Background onClick={shouldClose} />
        <ModalContent contentWidth={contentWidth} borderRadius={borderRadius} fullScreenOnMobile={fullScreenOnMobile}>
          <Header>
            <Title>{title ?? ''}</Title>
            <CloseButton fullScreenOnMobile={fullScreenOnMobile} type="button" onClick={shouldClose}>
              <img src={xButton} alt="Close" />
            </CloseButton>
          </Header>
          {title && <Divider />}
          <Body fullScreenOnMobile={fullScreenOnMobile}>{children}</Body>
        </ModalContent>
      </StyledModal>
    </CSSTransition>
  );
};

interface ContentProps {
  contentWidth?: Width;
  borderRadius?: Width;
  fullScreenOnMobile?: boolean;
}

const ModalContent = styled.div<ContentProps>`
  position: relative;
  padding-bottom: 40px;
  width: 100%;
  max-width: ${(props) => props.contentWidth ?? '520px'};
  background-color: #ffffff;
  border-radius: ${(props) => props.borderRadius ?? '20px'};

  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    height: ${(props) => (props.fullScreenOnMobile ? '100%' : 'initial')};
    border-radius: ${(props) => (props.fullScreenOnMobile ? '0px' : props.borderRadius ?? '20px')};
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 6px 30px;
`;

const Title = styled.h1`
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #002106;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e1e5e8;
`;

interface BodyProps {
  fullScreenOnMobile?: boolean;
}

const Body = styled.div<BodyProps>`
  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    transform: ${(props) => (props.fullScreenOnMobile ? 'translateY(-24px)' : 'initial')};
  }
`;

interface CloseButtonProps {
  fullScreenOnMobile?: boolean;
}

const CloseButton = styled.button<CloseButtonProps>`
  border: none;
  background-color: transparent;
  width: 44px;
  height: 44px;
  padding: 0;
  z-index: 1;

  &:hover {
    opacity: 0.65;
    cursor: pointer;
  }

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    transform: ${(props) => (props.fullScreenOnMobile ? 'translateY(10px)' : 'initial')};
  }
`;

interface StyledModalProps {
  fullScreenOnMobile?: boolean;
}

const StyledModal = styled.div<StyledModalProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px ${padding}px;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;

  &.modal-enter {
    opacity: 0;
  }
  &.modal-enter-active {
    opacity: 1;
    transition: opacity ${TRANSITION_DURATION}ms;
  }
  &.modal-exit {
    opacity: 1;
  }
  &.modal-exit-active {
    opacity: 0;
    transition: opacity ${TRANSITION_DURATION}ms;
  }

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    padding: ${(props) => (props.fullScreenOnMobile ? '0px' : `0px ${padding}px`)};
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: -1;
`;
