import { FC } from 'react';
import styled from 'styled-components';

export interface SponsorInfo {
  name: string;
  iconURL: string;
}

export const SponsorInfoBox: FC<SponsorInfo> = ({ name, iconURL }) => (
  <Container>
    <img src={iconURL} alt="Sponsor icon" />
    <Labels>
      <p>Sponsored by</p>
      <h4>{name}</h4>
    </Labels>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;

  & > img {
    width: 32px;
    height: 32px;
    border-radius: 16px;
  }
`;

const Labels = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;

  & > p {
    margin: 0;
    font-weight: 400;
    font-size: 11px;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #ffffff;
  }

  & > h4 {
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #ffffff;
  }
`;
