import { createGlobalStyle } from 'styled-components';
import { effectiveMobileMaxWidth } from './Constants';

const GlobalStyle = createGlobalStyle`
    html, body {
        // not completely sure why this margin:0 is necessary since
        // I don't need to do this on the standard create-react-app
        margin: 0;
        background-color: #f2f2f2;
        height: 100%;
    }

    * {
        font-family: 'Inter';
        font-style: normal;
        box-sizing: border-box;
    }

    @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
        .hide-on-mobile {
            display: none;
        }
    }
`;

export default GlobalStyle;
