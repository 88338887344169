import { FC, useContext } from 'react';
import { Modal } from './Modal';
import styled from 'styled-components';
import { AppContext } from '../hooks/context';
import { ProfilePic } from './ProfilePic';
import { ShareCTAs } from './ShareCTAs';
import { sanitize } from '../helpers/sanitize';
import { setProfileModal } from '../hooks/state';
import { ShareContextType } from '../helpers/socialURLs';
import { useGAModalViewTracking } from '../hooks/analytics';

export const ProfileModal: FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const isShown = state.externalLink == null && state.profileModal != null && state.profileModal.type === 'nomination';
  let body: JSX.Element = <></>;
  let modalAnalyticName: string | null = null;
  switch (state.profileModal?.type) {
    case 'nomination':
      body = <NominationBody creatorName={state.profileModal?.creatorName} />;
      modalAnalyticName = 'nomination-success';
      break;
  }
  useGAModalViewTracking(isShown, modalAnalyticName);

  return (
    <Modal contentWidth="520px" isShown={isShown} shouldClose={() => dispatch(setProfileModal(null))}>
      <Content>
        <ProfilePic profilePictureURL={state.profileModal?.creatorProfilePicURL} />
        <TextContent>{body}</TextContent>
      </Content>
      <Divider />
      <ShareContent>
        <ShareText>SHARE</ShareText>
        {state.profileModal != null && (
          <ShareCTAs
            creatorName={state.profileModal?.creatorName}
            creatorAddress={state.profileModal?.creatorAddress}
            type={state.profileModal?.type as ShareContextType}
          />
        )}
      </ShareContent>
    </Modal>
  );
};

interface BodyProps {
  creatorName: string;
}

const NominationBody: FC<BodyProps> = ({ creatorName }) => (
  <>
    <h1>Congratulations {creatorName}. Your application was successful!</h1>
    <p>
      Be sure to go to {Link('https://discord.gg/EBdYkeX5Cu', 'Discord')} and {Link('https://t.me/ApolloInuPortal', 'Telegram')} to share
      your story and interact with the community!
    </p>
  </>
);

const Link = (website: string, label: string): JSX.Element => {
  return (
    <CommunityLink href={sanitize(website)} target="_blank" rel="noreferrer">
      {label}
    </CommunityLink>
  );
};

const CommunityLink = styled.a`
  color: #00c648;

  &:hover,
  &:focus {
    text-decoration: underline;
    cursor: pointer;
  }
`;
const ShareText = styled.p`
  width: auto;

  /* material-theme/overline */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  letter-spacing: 0.5px;
  text-transform: uppercase;

  /* material-theme/sys/light/on-secondary-container */
  color: #0f1f10;
`;

const ShareContent = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  position: static;
  height: 73px;
  left: 124px;
  top: 17px;
`;

const Divider = styled.hr`
  position: static;
  width: auto;
  height: 1px;

  /* black/black-20 */

  background: rgba(0, 0, 0, 0.2);

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 24px 40px 0px 40px;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  h1 {
    text-align: center;
    margin: 0;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #002106;
  }

  p {
    text-align: center;
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #526350;
    padding: 0px 25px;

    i {
      font-style: italic;
    }
  }
`;
