import { FC, useState } from 'react';
import styled from 'styled-components';
import Collapsable from 'react-collapsible';
import { PastContest, PastContestant } from '../../shared/src/PastContest';
import { effectiveMobileMaxWidth } from '../../shared/src/ui/Constants';
import { ProfilePic } from '../ProfilePic';
import { contestEndedDateString, getWinnerName, ValueLabelOrLink, winnerVoteCountString } from './PastContestRow';
import arrowSVG from '../../svg/arrow-down.svg';
import { AnalyticEvents, clickedIPFSEvent, clickedTXEvent } from '../../hooks/analytics';

const etherscanBaseURL = process.env.REACT_APP_ETHERSCAN_BASE_URL;

export const MobilePastContestRow: FC<PastContest> = (pastContest) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <HiderContainer>
      <ContentContainer>
        <Collapsable
          trigger={<RowTrigger isOpen={isOpen} contest={pastContest} />}
          easing="ease"
          onOpening={() => setIsOpen(true)}
          onClosing={() => setIsOpen(false)}
        >
          <ExpandedContent {...pastContest} />
        </Collapsable>
      </ContentContainer>
    </HiderContainer>
  );
};

interface RowTriggerProps {
  isOpen: boolean;
  contest: PastContest;
}

const RowTrigger: FC<RowTriggerProps> = ({ isOpen, contest }) => {
  const winner = getWinner(contest.contestants);
  const title = contest.name ?? `Contest #${contest.contestNumber}`;
  return (
    <StyledRowTrigger>
      <TitleAndPicContainer isOpen={isOpen}>
        <h1>{title}</h1>
        {winner && <ProfilePic name={winner.name} profilePictureURL={winner.profilePictureURL} width="24px" />}
      </TitleAndPicContainer>
      <Arrow src={arrowSVG} alt="Open" isOpen={isOpen} />
    </StyledRowTrigger>
  );
};

const ExpandedContent: FC<PastContest> = ({ cycleId, summaryIPFSPath, completeCycleTxHash, votersCount, contestants }) => (
  <StyledExpandedContent>
    <Divider />
    <TextColumnsContainer>
      <TextColumn>
        <SingleStatBox label="Ended" value={contestEndedDateString(cycleId)} />
        {summaryIPFSPath && (
          <SingleStatBox
            label="Voting Summary"
            value={summaryIPFSPath}
            caption="IPFS"
            action={{
              valueURL: `https://ipfs.io/ipfs/${summaryIPFSPath}`,
              analyticEvent: clickedIPFSEvent(),
            }}
          />
        )}
        <SingleStatBox
          label="Contest Cycle"
          value={completeCycleTxHash}
          caption="Ethereum"
          action={{
            valueURL: `${etherscanBaseURL}/tx/${completeCycleTxHash}`,
            analyticEvent: clickedTXEvent(),
          }}
        />
      </TextColumn>

      <TextColumn>
        <SingleStatBox label="Voters" value={votersCount.toLocaleString()} />
        <SingleStatBox
          label="Winner"
          value={getWinnerName(contestants)}
          caption={winnerVoteCountString(contestants)}
          profilePic={getProfilePicProps(contestants)}
        />
      </TextColumn>
    </TextColumnsContainer>
  </StyledExpandedContent>
);

interface SingleStatBoxProps {
  label: string;
  value: string;
  action?: StatAction;
  caption?: string;
  profilePic?: ProfilePicProps;
}

interface ProfilePicProps {
  name: string;
  profilePictureURL?: string;
}

interface StatAction {
  valueURL: string;
  analyticEvent: AnalyticEvents;
}

const SingleStatBox: FC<SingleStatBoxProps> = ({ label, value, action, caption, profilePic }) => (
  <StyledSingleStatBox>
    <h2>{label}</h2>
    <StatValueImageContainer>
      {profilePic && <ProfilePic {...profilePic} width="24px" />}
      <ValueLabelOrLink value={value} action={action} />
    </StatValueImageContainer>
    {caption && <h5>{caption}</h5>}
  </StyledSingleStatBox>
);

// Helper functions

const getWinner = (contestants: PastContestant[]) => (contestants.length > 0 ? contestants[0] : null);

const getProfilePicProps = (contestants: PastContestant[]): ProfilePicProps | undefined => {
  if (contestants.length > 0) {
    return {
      name: contestants[0].name,
      profilePictureURL: contestants[0].profilePictureURL,
    };
  } else {
    return undefined;
  }
};

// Styled components

const HiderContainer = styled.div`
  display: none;
  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    display: unset;
  }
`;

const ContentContainer = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
`;

const StyledRowTrigger = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

interface IsOpenProps {
  isOpen: boolean;
}

const TitleAndPicContainer = styled.div<IsOpenProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  h1 {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #002106;
  }

  img {
    opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
    transition: opacity 300ms ease;
  }
`;

const Arrow = styled.img<IsOpenProps>`
  transform: ${({ isOpen }) => (isOpen ? ' rotateZ(180deg)' : ' rotateZ(00deg)')};
  transition: transform 200ms ease;
`;

const StyledExpandedContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 4px;
  gap: 20px;
  padding: 0px 16px 16px 16px;
`;

const Divider = styled.div`
  height: 1px;
  background-color: #e1e5e8;
`;

const TextColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 36px;
`;

const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
  width: calc(50% - 18px);
`;

const StyledSingleStatBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 7px;

  a,
  h2,
  h4,
  h5 {
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  h2 {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.4);
  }

  a,
  h4 {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #002106;
  }

  h5 {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.4);
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StatValueImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
