import { FC, useContext } from 'react';
import { Modal } from './Modal';
import styled from 'styled-components';
import { AppContext } from '../hooks/context';
import { setExternalLink, setShouldShowLinkWarning } from '../hooks/state';
import { sanitize } from '../helpers/sanitize';
import { CancelConfirmButtonStack } from './CancelConfirmButtonStack';

export const ExternalLinkModal: FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const confirmHandler = () => {
    if (state.externalLink != null) {
      dispatch(setExternalLink(null));
      window.open(sanitize(state.externalLink));
    }
  };

  return (
    <Modal contentWidth="520px" isShown={state.externalLink != null} shouldClose={() => dispatch(setExternalLink(null))}>
      <ContentContainer>
        <Title>External Link</Title>
        <Body>
          You are about to open a new browser tab and visit: <BoldText>{state.externalLink}</BoldText>
        </Body>
        <Label>
          <Checkbox
            defaultChecked={!state.shouldShowLinkWarning}
            type="checkbox"
            name="external-link-warning"
            onChange={() => dispatch(setShouldShowLinkWarning(!state.shouldShowLinkWarning))}
          />
          Do not remind me again.
        </Label>
        <CancelConfirmButtonStack shouldClose={() => dispatch(setExternalLink(null))} onConfirm={confirmHandler} />
      </ContentContainer>
    </Modal>
  );
};

const BoldText = styled.span`
  font-weight: bold;
  display: inline-block;
  word-wrap: wrap;
  word-break: break-all;
  width: 100%;
`;
const ContentContainer = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  background: #ffffff;
  border-radius: 10px;
  width: 85%;
  margin: auto;
`;
const Title = styled.div`
  /* Title */

  position: static;

  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: flex-end;
  text-align: center;

  /* Apollo/Text Dark */
  color: #002106;
`;

const Body = styled.p`
  /* Text */
  position: static;
  left: 0px;
  top: 0px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Apollo/Text Medium */
  color: #526350;
`;

const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  padding-bottom: 16px;
`;

const Checkbox = styled.input`
  /* _Checkbox base */

  position: static;
  width: 20px;
  height: 20px;
  left: 0px;
  top: 0px;

  /* Base/White */
  background: #ffffff;
  /* Button Border Gray */
  border: 1px solid #e1e5e8;
  box-sizing: border-box;
  border-radius: 3px;

  margin: 0px 0px;
`;
