import axios, { AxiosResponse } from 'axios';
import { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useWeb3React } from '@web3-react/core';
import { APIResponse } from '../shared/src/APIResponse';
import { HeaderLabelStack } from './HeaderLabelStack';
import { Nominees } from './Nominees';
import { effectiveMaxWidth, padding, effectiveMobileMaxWidth } from '../shared/src/ui/Constants';
import { ContestantResponse, ContestResponse } from '../shared/src/Contestant';
import { PastContestant } from '../shared/src/PastContest';
import { VoteContext } from './Voting/VoteContextProvider';

const baseURL = process.env.REACT_APP_BASE_URL;

export const NominatedCreatorsSection: FC = () => {
  const { account } = useWeb3React();
  const { didUpdateVotes } = useContext(VoteContext);
  const [contestants, setContestants] = useState<ContestantResponse[]>([]);

  useEffect(() => {
    fetchContestants().then(setContestants);
  }, [account, didUpdateVotes]);
  const info = { context: 'current', contestants: contestants } as const;

  return (
    <StyledContestantsSection>
      <HeaderBar>
        <HeaderLabelStack title="Creators" subtitle={contestantsHeaderSubtitle(contestants)} />
      </HeaderBar>

      <Nominees info={info} />
    </StyledContestantsSection>
  );
};

const fetchContestants = async (): Promise<ContestantResponse[]> => {
  try {
    const validateStatus = (status: number): boolean => status === 200 || status === 404;
    const response: AxiosResponse<APIResponse<ContestResponse>, any> = await axios.get(`${baseURL}/contestants/`, { validateStatus });
    return response.status === 200 ? response.data.data.contestants : [];
  } catch (err) {
    console.error(`Error fetching contestants: ${err}`);
    toast.error('Failed to fetch contestants');
    return [];
  }
};

export const contestantsHeaderSubtitle = (contestants: ContestantResponse[] | PastContestant[]): string => {
  return contestants.length == 1 ? `${contestants.length} nominee` : `${contestants.length} nominees`;
};

export const StyledContestantsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;

  margin: auto;
  max-width: ${effectiveMaxWidth()}px;
  padding: 0px ${padding}px 64px ${padding}px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    padding: 40px ${padding}px;
  }
`;

const HeaderBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
