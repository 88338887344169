import { FC } from 'react';
import styled from 'styled-components';
import { effectiveMobileMaxWidth } from './Constants';
import { CountdownBox } from './CountdownBox';
import { SponsorInfo, SponsorInfoBox } from './SponsorInfoBox';

export interface ContestTitleBannerProps {
  contestName: string;
  sponsorInfo?: SponsorInfo;
  startDate: number;
  endDate: number;
  backgroundImageURL: string;
}

export const ContestTitleBanner: FC<ContestTitleBannerProps> = ({ contestName, sponsorInfo, startDate, endDate, backgroundImageURL }) => (
  <Container backgroundImageURL={backgroundImageURL}>
    <div>{sponsorInfo && <SponsorInfoBox {...sponsorInfo} />}</div>
    <LabelsAndCountdown>
      <TitleAndDateRangeLabels>
        <h1>{contestName}</h1>
        <h3>{contestTimeRangeString(startDate, endDate)}</h3>
      </TitleAndDateRangeLabels>
      <CountdownBox date={new Date(endDate * 1000)} />
    </LabelsAndCountdown>
  </Container>
);

// Helper functions

export const contestTimeRangeString = (startDate: number, endDate: number): string => {
  const _startDate = new Date(startDate * 1000);
  const _endDate = new Date(endDate * 1000);
  const startMonth = _startDate.toLocaleDateString('en-US', { month: 'short' });
  const endMonth = _endDate.toLocaleDateString('en-US', { month: 'short' });
  if (startMonth === endMonth) {
    const startDay = _startDate.toLocaleDateString('en-US', { day: 'numeric' });
    const endDay = _endDate.toLocaleDateString('en-US', { day: 'numeric' });
    return `${startMonth} ${startDay}–${endDay}`;
  } else {
    const startString = _startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    const endString = _endDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    return `${startString} – ${endString}`;
  }
};

// Styled components

interface ContainerProps {
  backgroundImageURL: string;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  flex-grow: 1;
  flex-shrink: 1;

  gap: 24px;
  padding: 40px;
  max-width: 809px;
  height: 400px;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0.4) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${(props) => props.backgroundImageURL}) center center / cover;
  border-radius: 12px;
`;

const TitleAndDateRangeLabels = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: min(487px, 100%);

  & > h1 {
    margin: 0;
    font-weight: 800;
    font-size: 64px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #ffffff;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  & > h3 {
    margin: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
    color: #ffffff;
  }

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    gap: 6px;
    & > h1 {
      font-size: 36px;
    }
    & > h3 {
      font-size: 16px;
      line-height: 165%;
    }
  }
`;

const LabelsAndCountdown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    gap: 12px;
  }
`;
