import { FC } from 'react';
import { QueueInfo } from '../../hooks/useQueuePosition';
import { ConnectWalletButton } from './ConnectWallet';
import { WalletProfileButton } from './WalletProfileButton';

interface Props {
  queueInfo: QueueInfo;
}

export const ConnectWalletOrProfileButton: FC<Props> = ({ queueInfo }) => {
  const { address, response } = queueInfo;
  if (address != null) {
    return <WalletProfileButton address={address} profileInfo={response} />;
  } else {
    return <ConnectWalletButton />;
  }
};
