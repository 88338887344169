import { useWeb3React } from '@web3-react/core';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { PositionResponse } from '../shared/src/Queue';

const apiBaseURL = process.env.REACT_APP_BASE_URL;

export interface QueueInfo {
  address: string | null;
  response: PositionResponse | null;
  /// Fetch the queue position again
  refreshQueuePosition(): void;
}

export const useQueuePosition = (): QueueInfo => {
  const { active, account } = useWeb3React();
  const [response, setResponse] = useState<PositionResponse | null>(null);

  // This state simply toggles a bool, which causes the effect to fire again
  const [toggle, setToggle] = useState(false);
  const refreshQueuePosition = () => setToggle(!toggle);

  useEffect(() => {
    if (active && account != null) {
      fetchQueuePosition(account).then(setResponse);
    } else {
      setResponse(null);
    }
  }, [active, account, toggle]);

  return {
    address: active && account != null ? account : null,
    response,
    refreshQueuePosition,
  };
};

const fetchQueuePosition = async (address: string): Promise<PositionResponse | null> => {
  try {
    const response = await axios.get(`${apiBaseURL}/queue/position/${address}`);
    return response.data.data;
  } catch (err) {
    console.error(`Error fetching queue position: ${err}`);
    return null;
  }
};
