import { FC } from 'react';
import styled from 'styled-components';
import { effectiveMobileMaxWidth } from '../shared/src/ui/Constants';

type Pixels = `${number}px`;

export interface ProfilePicProps {
  name?: string;
  profilePictureURL?: string;
  width?: Pixels;
  mobileWidth?: Pixels;
}

export const ProfilePic: FC<ProfilePicProps> = ({ name, profilePictureURL, ...props }) => {
  return <Image src={profilePictureURL ?? '/placeholder.png'} alt={name ?? 'Profile'} {...props} />;
};

const defaultWidth: Pixels = '88px';

const Image = styled.img<ProfilePicProps>`
  width: ${(props) => props.width ?? defaultWidth};
  height: ${(props) => props.width ?? defaultWidth};
  object-fit: cover;
  border-radius: 50%;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    width: ${(props) => props.mobileWidth ?? props.width ?? defaultWidth};
    height: ${(props) => props.mobileWidth ?? props.width ?? defaultWidth};
  }
`;
