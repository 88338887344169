import { ShareContextType } from '../helpers/socialURLs';

export type ProfileModalType = Omit<ShareContextType, 'contest'>;
export type ProfileModal = {
  creatorProfilePicURL?: string;
  creatorName: string;
  creatorAddress: string;
  type: ProfileModalType;
};

export type State = {
  externalLink: string | null;
  profileModal: ProfileModal | null;
  shouldShowLinkWarning: boolean;
  shouldShowInfoBanner: boolean;
  shouldShowConnectWalletModal: boolean;
};

export const DEFAULT_STATE: State = {
  externalLink: null,
  profileModal: null,
  shouldShowLinkWarning: true,
  shouldShowInfoBanner: true,
  shouldShowConnectWalletModal: false,
};

export const setExternalLink = (externalLink: string | null) =>
  ({
    type: 'SET_EXTERNAL_LINK',
    payload: {
      externalLink,
    },
  } as const);

export const setProfileModal = (profileModal: ProfileModal | null) =>
  ({
    type: 'SET_PROFILE_MODAL_LINK',
    payload: {
      profileModal,
    },
  } as const);

export const setShouldShowLinkWarning = (shouldShowLinkWarning: boolean) =>
  ({
    type: 'SET_SHOULD_SHOW_LINK_WARNING',
    payload: {
      shouldShowLinkWarning,
    },
  } as const);

export const setShouldShowInfoBanner = (shouldShowInfoBanner: boolean) =>
  ({
    type: 'SET_SHOULD_SHOW_INFO_BANNER',
    payload: {
      shouldShowInfoBanner,
    },
  } as const);

export const setShouldShowConnectWalletModal = (shouldShowConnectWalletModal: boolean) =>
  ({
    type: 'SET_SHOULD_SHOW_CONNECT_WALLET_MODAL',
    payload: {
      shouldShowConnectWalletModal,
    },
  } as const);

export type Action =
  | ReturnType<typeof setExternalLink>
  | ReturnType<typeof setShouldShowLinkWarning>
  | ReturnType<typeof setShouldShowInfoBanner>
  | ReturnType<typeof setProfileModal>
  | ReturnType<typeof setShouldShowConnectWalletModal>;

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_EXTERNAL_LINK':
      return {
        ...state,
        externalLink: action.payload.externalLink,
      };
    case 'SET_PROFILE_MODAL_LINK':
      return {
        ...state,
        profileModal: action.payload.profileModal,
      };
    case 'SET_SHOULD_SHOW_LINK_WARNING':
      return {
        ...state,
        shouldShowLinkWarning: action.payload.shouldShowLinkWarning,
      };
    case 'SET_SHOULD_SHOW_INFO_BANNER':
      return {
        ...state,
        shouldShowInfoBanner: action.payload.shouldShowInfoBanner,
      };
    case 'SET_SHOULD_SHOW_CONNECT_WALLET_MODAL':
      return {
        ...state,
        shouldShowConnectWalletModal: action.payload.shouldShowConnectWalletModal,
      };
  }
};
