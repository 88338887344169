import { FC } from 'react';
import styled from 'styled-components';
import { effectiveMobileMaxWidth } from '../shared/src/ui/Constants';

interface Props {
  title: string;
  subtitle: string;
}

export const HeaderLabelStack: FC<Props> = ({ title, subtitle }) => (
  <Container>
    <TitleLabel>{title}</TitleLabel>
    <SubtitleLabel>{subtitle}</SubtitleLabel>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    gap: 4px;
  }
`;

const TitleLabel = styled.h1`
  font-weight: 700;
  font-size: 24px;
  color: #002106;
  margin: 0px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    font-size: 16px;
  }
`;

const SubtitleLabel = styled.h1`
  font-weight: 400;
  font-size: 13px;
  color: #002106;
  margin: 0px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    font-size: 12px;
  }
`;
