import React, { FC, ReactElement } from 'react';

const HideOnMobile: FC<{
  children: ReactElement<any> | ReactElement<any>[];
}> = ({ children }) => {
  const childrenWithClassNames = React.Children.map(children, (child: React.ReactElement<any>) => {
    // html elements
    return React.cloneElement(child, {
      className: [child.props.className, 'hide-on-mobile'].filter(Boolean).join(' '),
    });
  });

  return <>{childrenWithClassNames}</>;
};

export default HideOnMobile;
