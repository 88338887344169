import { FC, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ProfileCard } from './ProfileCard';
import { effectiveMobileMaxWidth } from '../shared/src/ui/Constants';
import { ContestantResponse } from '../shared/src/Contestant';
import { PastContestant } from '../shared/src/PastContest';
import { CreatorProfileResponse } from '../shared/src/CreatorProfile';
import { VoteContext } from './Voting/VoteContextProvider';

interface CurrentContestInfo {
  context: 'current';
  contestants: ContestantResponse[];
}

interface PastContestInfo {
  context: 'past';
  contestants: PastContestant[];
}

interface NomineesProps {
  info: CurrentContestInfo | PastContestInfo;
}

export const Nominees: FC<NomineesProps> = ({ info }) => {
  const { hasVotedWithWallet, voteButtonPressed } = useContext(VoteContext);
  return (
    <NomineesContainer>
      {info.contestants.map((contestant, index) => (
        <NomineeCard
          key={profileDetails(contestant).address}
          contestant={contestant}
          canVote={info.context === 'current' && hasVotedWithWallet === false}
          index={index}
          voteButtonPressed={voteButtonPressed}
        />
      ))}
    </NomineesContainer>
  );
};

interface NomineeCardProps {
  contestant: ContestantResponse | PastContestant;
  canVote: boolean;
  index: number;
  voteButtonPressed(contestant: CreatorProfileResponse): void;
}

interface ProfileDetails {
  name: string;
  profilePictureURL?: string;
  creatorType: string;
  address: string;
}

export const NomineeCard: FC<NomineeCardProps> = ({ contestant, canVote, index, voteButtonPressed }) => {
  const navigate = useNavigate();
  const profile = profileDetails(contestant);

  return (
    <ProfileCard
      name={profile.name}
      profilePictureURL={profile.profilePictureURL}
      creatorType={profile.creatorType}
      votePoints={contestant.votePoints}
      hideVoteButton={canVote === false}
      onClickProfileLink={() => navigate(`/profile/${profile.address}`)}
      onClickVote={() => voteButtonPressed((contestant as ContestantResponse).profile)}
      pastContestRank={pastContestRank(contestant, index)}
    />
  );
};

const profileDetails = (contestant: ContestantResponse | PastContestant): ProfileDetails => {
  if ('profile' in contestant) {
    return contestant.profile;
  } else {
    return contestant;
  }
};

const pastContestRank = (contestant: ContestantResponse | PastContestant, index: number): number | undefined => {
  if ('profile' in contestant) {
    // This is not a past contestant
    return undefined;
  } else {
    // This is a past contestant. Index is zero-based and rank is 1-based.
    return index + 1;
  }
};

// Styled components

const NomineesContainer = styled.div`
  display: grid;
  // Three columns
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;

  @media screen and (max-width: 920px) {
    // Two columns
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    // One column
    grid-template-columns: 1fr;
    grid-gap: 4px;
  }
`;
