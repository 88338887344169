import { FC, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, NavigateFunction } from 'react-router-dom';
import apolloSVG from '../../svg/apollo.svg';
import menuSVG from '../../svg/menu.svg';
import closeSVG from '../../svg/close.svg';
import { WalletButtons } from '../Wallet/MobileWallet';
import { QueueInfo } from '../../hooks/useQueuePosition';

interface Props {
  queueInfo: QueueInfo;
}

export const MobileNavBar: FC<Props> = ({ queueInfo }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <Container>
      <LogoAndTitle />
      <NavButtons queueInfo={queueInfo} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      <NavMenu queueInfo={queueInfo} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
    </Container>
  );
};

const LogoAndTitle: FC = () => {
  const navigate = useNavigate();
  return (
    <LogoAndTitleContainer onClick={() => navigate('/')}>
      <Logo />
      <h1>
        Creator
        <br />
        Contest
      </h1>
    </LogoAndTitleContainer>
  );
};

const Logo: FC = () => <StyledLogo src={apolloSVG} alt="Logo" />;

interface NavButtonsProps {
  queueInfo: QueueInfo;
  isMenuOpen: boolean;
  setIsMenuOpen(isOpen: boolean): void;
}

const NavButtons: FC<NavButtonsProps> = ({ queueInfo, ...props }) => (
  <StyledNavButtons>
    <WalletButtons queueInfo={queueInfo} />
    <MenuButton {...props} />
  </StyledNavButtons>
);

type MenuButtonProps = Omit<NavButtonsProps, 'queueInfo'>;

const MenuButton: FC<MenuButtonProps> = ({ isMenuOpen, setIsMenuOpen }) => (
  <StyledMenuButton isOpen={isMenuOpen} onClick={() => setIsMenuOpen(!isMenuOpen)}>
    <img className="is-closed" src={menuSVG} alt="Open menu" />
    <img className="is-open" src={closeSVG} alt="Close menu" />
  </StyledMenuButton>
);

interface NavProps {
  isOpen: boolean;
}

interface MenuItem {
  title: string;
  action(navigate: NavigateFunction): void;
}

const openExternalHelpPage = () => window.open('https://www.apollocrypto.org/docs-creator-contest', '_blank');

const makeMenuItems = (queueInfo: QueueInfo): MenuItem[] => {
  // Only show edit button if user is queued
  const showEdit = queueInfo.response?.queuePosition != null;
  const applyTitle = showEdit ? 'Edit Profile' : 'Apply as a Creator';
  const applyRoute = showEdit ? '/edit-profile' : '/apply/main';
  return [
    {
      title: 'Vote',
      action: (navigate) => {
        navigate('/'); // Navigate to home page
      },
    },
    {
      title: 'Contests',
      action: (navigate) => {
        navigate('/contests'); // Navigate to contests page
      },
    },
    {
      title: 'Need Help?',
      action: openExternalHelpPage,
    },
    {
      title: applyTitle,
      action: (navigate) => {
        navigate(applyRoute); // Navigate to apply or edit page
      },
    },
  ];
};

interface NavMenuProps {
  queueInfo: QueueInfo;
  isMenuOpen: boolean;
  setIsMenuOpen(isOpen: boolean): void;
}

const NavMenu: FC<NavMenuProps> = ({ queueInfo, isMenuOpen, setIsMenuOpen }) => {
  const navigate = useNavigate();
  const menuItems = makeMenuItems(queueInfo);
  return (
    <NavMenuContainer onClick={() => setIsMenuOpen(false)} isOpen={isMenuOpen}>
      {menuItems.map((item, index) => (
        <NavMenuItemButton
          onClick={() => item.action(navigate)}
          key={index}
          itemIndex={index}
          isOpen={isMenuOpen}
          bottomBorder={index < menuItems.length - 1}
        >
          {item.title}
        </NavMenuItemButton>
      ))}
    </NavMenuContainer>
  );
};

// Styled components

const Container = styled.div`
  position: relative; // so the nav menu can be positioned relative to the container
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  padding: 8px;
  background-color: #ffffff;
  border-bottom: 1px solid #e1e5e8;
`;

const LogoAndTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  h1 {
    margin: 0;
    font-weight: 800;
    font-size: 14px;
    line-height: 90%;
    color: #002106;
  }

  &:hover {
    cursor: pointer;
  }
`;

const StyledLogo = styled.img`
  width: 40px;
  height: 40px;
`;

const StyledNavButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 8px;
`;

const StyledMenuButton = styled.button<NavProps>`
  position: relative; // position images absolutely inside
  width: 40px;
  height: 100%;

  border: 1px solid #e1e5e8;
  background-color: #ffffff;
  border-radius: 3px;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 300ms ease;
  }

  img.is-closed {
    opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
  }

  img.is-open {
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  }

  &:hover {
    cursor: pointer;
    background-color: #f2f4f7;
  }
`;

const TRANSITION_DURATION = 300;

const NavMenuContainer = styled.div<NavProps>`
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 100%;
  left: 0;
  height: calc(100vh - 100%); // Height of viewport minus height of nav menu
  background-color: rgba(0, 0, 0, 0.65);

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0px;

  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  transition: opacity ${TRANSITION_DURATION}ms ease;
`;

interface NavMenuItemButtonProps {
  isOpen: boolean;
  itemIndex: number;
  bottomBorder?: boolean;
}

const NavMenuItemButton = styled.button<NavMenuItemButtonProps>`
  border: none;
  background-color: #d5e8d0;
  margin: 0;
  padding: 24px;

  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  color: #002106;

  ${({ bottomBorder }) => (bottomBorder ? 'border-bottom: 1px solid #ffffff;' : '')}

  transform: translate(0, ${({ isOpen }) => (isOpen ? '0' : '-20px')});
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};

  // 10 is an arbitrary number here meant to invert the index order without going negative.
  // It wouldn't work if there were more than 10 items in the list
  z-index: ${({ itemIndex }) => `${10 - itemIndex}`};
  transition: opacity ${TRANSITION_DURATION}ms ease, transform ${TRANSITION_DURATION}ms ease;
  transition-delay: ${({ itemIndex }) => `${itemIndex * (TRANSITION_DURATION / 2)}ms`};

  // Don't cascade when closing the menu
  ${({ isOpen }) => (!isOpen ? 'transition-delay: unset;' : '')}

  &:hover {
    cursor: pointer;
    background-color: #e0eedd;
  }
`;
