import { FC } from 'react';
import styled from 'styled-components';
import { ShareButton } from './Share/ShareButton';
import { effectiveMaxWidth, effectiveMobileMaxWidth, padding } from '../shared/src/ui/Constants';
import { ContestShareContext } from '../helpers/socialURLs';

const siteBaseURL = process.env.REACT_APP_SITE_BASE_URL;

interface Props {
  // The text that appears above the title with less emphasis
  topText?: string;
  titleText: string;
  caption?: React.ReactNode | string;
  cta: React.ReactNode;
  share?: boolean;
}

export const PageInfoBanner: FC<Props> = ({ topText, titleText, caption, share, cta }) => {
  const shareContext: ContestShareContext = {
    type: 'contest',
    copyLinkURL: siteBaseURL as string,
  };
  return (
    <Background>
      <Content>
        <InfoBox>
          {topText && <TopLabel>{topText}</TopLabel>}
          <TitleLabel>{titleText}</TitleLabel>
          {caption && <CaptionContainer isAboveShare={share}>{caption}</CaptionContainer>}
          {share && <ShareButton context={shareContext} />}
        </InfoBox>

        {cta}
      </Content>
    </Background>
  );
};

const Background = styled.div`
  background-color: #ffffff;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  margin: auto;
  max-width: ${effectiveMaxWidth()}px;
  padding: 64px ${padding}px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    flex-direction: column;
    align-items: stretch;
    gap: 32px;
    padding: 40px ${padding}px;
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TopLabel = styled.h2`
  color: #26e45e;
  font-weight: 700;
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 6px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    font-size: 16px;
  }
`;

const TitleLabel = styled.h1`
  font-weight: 600;
  font-size: 48px;
  color: #002106;
  margin-top: 6px;
  margin-bottom: 12px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    font-size: 32px;
  }
`;

interface CaptionContainerProps {
  isAboveShare?: boolean;
}

const CaptionContainer = styled.h3<CaptionContainerProps>`
  font-weight: 400;
  font-size: 18px;
  margin-top: 12px;
  margin-bottom: ${(props) => (props.isAboveShare ? '40px' : '36px')};
  color: #72796f;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    font-size: 14px;
    margin-bottom: ${(props) => (props.isAboveShare ? '24px' : '0px')};
  }
`;
