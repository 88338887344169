import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Web3ReactProvider } from '@web3-react/core';
import Web3 from 'web3';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-227493903-1');

const getLibrary = (provider: any) => {
  return new Web3(provider);
};

const root = ReactDOM.createRoot(document.getElementById('root') as any);
root.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    {/* <React.StrictMode> */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
    {/* </React.StrictMode> */}
  </Web3ReactProvider>
);
