import { FC } from 'react';
import styled from 'styled-components';
import { PastContest } from '../../shared/src/PastContest';
import { effectiveMobileMaxWidth } from '../../shared/src/ui/Constants';
import { contestCycleStats, SingleStatBox, votingSummaryStats } from '../AllContests/PastContestRow';

export const PastContestCTA: FC<PastContest> = ({ summaryIPFSPath, completeCycleTxHash }) => (
  <Container>
    {summaryIPFSPath && <SingleStatBox {...votingSummaryStats(summaryIPFSPath)} />}
    <SingleStatBox {...contestCycleStats(completeCycleTxHash)} />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 32px;
  width: 400px;

  border: 2px solid #d5e8d0;
  border-radius: 8px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    width: unset;
  }
`;
