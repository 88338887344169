import { FC, PropsWithChildren, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useWeb3React } from '@web3-react/core';
import ImageUploading, { ImageListType, ImageType } from 'react-images-uploading';
import { CreatorProfileResponseDetails } from '../../shared/src/CreatorProfile';
import { effectiveMaxWidth, effectiveMobileMaxWidth, padding } from '../../shared/src/ui/Constants';
import { AppContext } from '../../hooks/context';
import { setExternalLink } from '../../hooks/state';
import { Account } from '../../Api/blockchain';
import { VoteButton } from '../VoteButton';
import { ShareButton } from '../Share/ShareButton';
import { ProfileShareContext } from '../../helpers/socialURLs';
import { WhiteBackground } from '../WhiteBackground';
import { youTubeEmbedURLFromURL } from '../../helpers/youTubeURL';
import { sanitize } from '../../helpers/sanitize';
import { QueueInfo } from '../../hooks/useQueuePosition';
import { ContestantQueueInfoBox } from '../ApplyToContest/ContestantQueueInfoBox';
import { ActionButton } from './ActionButton';
import { useCreatorProfile } from '../../hooks/useCreatorProfile';
import { ProfilePic } from '../ProfilePic';
import { showToastOnImageError } from '../ApplyToContest/ApplyToContest';
import { VoteContext } from '../Voting/VoteContextProvider';

const siteBaseURL = process.env.REACT_APP_SITE_BASE_URL;

// Social icons
import twitterSVG from '../../svg/text-fields/twitter.svg';
import facebookSVG from '../../svg/text-fields/facebook.svg';
import instagramSVG from '../../svg/text-fields/instagram.svg';
import youTubeSVG from '../../svg/text-fields/youtube.svg';
import openSeaSVG from '../../svg/text-fields/opensea.svg';
import redditSVG from '../../svg/text-fields/reddit.svg';
import linkedInSVG from '../../svg/text-fields/linkedin.svg';
import tikTokSVG from '../../svg/text-fields/tiktok.svg';
import otherSocialSVG from '../../svg/text-fields/other.svg';
import arrowUpRight from '../../svg/arrow-up-right.svg';

interface ProfileProps {
  queueInfo: QueueInfo;
}

// Todo: style the "not found" state
export const Profile: FC<ProfileProps> = ({ queueInfo }) => {
  document.title = 'Profile - Apollo DAO Creator Contest';
  const { address } = useParams();
  const { profile, updateProfilePicture } = useCreatorProfile(address ?? null);
  const { account } = useWeb3React();

  if (profile != null) {
    const isViewingOwnProfile = account != null && account.toLowerCase() === profile.address.toLowerCase();

    return (
      <ProfileContent
        creatorProfile={profile}
        voter={account}
        isViewingOwnProfile={isViewingOwnProfile}
        updateProfilePicture={updateProfilePicture}
        queueInfo={queueInfo}
      />
    );
  } else {
    return null;
  }
};
interface Props {
  creatorProfile: CreatorProfileResponseDetails;
  voter: Account;
  queueInfo: QueueInfo;
  /// Whether the current user is viewing their own profile
  isViewingOwnProfile: boolean;
  updateProfilePicture(picture: ImageType): void;
}

export const ProfileContent: FC<Props> = (props) => {
  const { creatorProfile, isViewingOwnProfile } = props;
  const socialLinks = allSocialLinkProps(creatorProfile);
  const showSocialSection = socialLinks.length > 0;

  return (
    <>
      <WhiteBackground />
      <HeaderSection>
        <CreatorHeader>
          <ProfilePicWithEdit
            creatorProfile={creatorProfile}
            isViewingOwnProfile={isViewingOwnProfile}
            updateProfilePicture={props.updateProfilePicture}
          />
          <NameAndCreatorType>
            <h1>{creatorProfile.name}</h1>
            <h2>{creatorProfile.creatorType}</h2>
          </NameAndCreatorType>
        </CreatorHeader>

        <ContestantQueueInfoBox context="profile" voter={props.voter} queueInfo={props.queueInfo} />
      </HeaderSection>

      <AllButtons {...props} />

      <Divider />

      <BottomContainer>
        <CreatorDetailsLeftColumn>
          <TitleAndTextView>
            <h1>Creator Statement</h1>
            <p>{creatorProfile.creatorStatement}</p>
          </TitleAndTextView>
          <TitleAndTextView>
            <h1>How do you plan to use the funding if you win?</h1>
            <p>{creatorProfile.fundsUsagePlan}</p>
          </TitleAndTextView>
          <YouTubeEmbed videoURL={creatorProfile.youTubeVideoURL} />

          <ImageEmbed imageURL={creatorProfile.creatorImageURL} />
        </CreatorDetailsLeftColumn>

        <CreatorLinksRightColumn>
          {creatorProfile.websiteURL && (
            <InfoBox title="WEBSITE">
              <WebsiteLink website={creatorProfile.websiteURL} />
            </InfoBox>
          )}

          <AllSocialLinks>
            {showSocialSection && (
              <InfoBox title="SOCIALS">
                {socialLinks.map((link) => (
                  <SocialLink key={link.url} {...link} />
                ))}
              </InfoBox>
            )}
          </AllSocialLinks>
        </CreatorLinksRightColumn>
      </BottomContainer>
    </>
  );
};

interface ProfilePicWithEditProps {
  creatorProfile: CreatorProfileResponseDetails;
  isViewingOwnProfile: boolean;
  updateProfilePicture(picture: ImageType): void;
}

const ProfilePicWithEdit: FC<ProfilePicWithEditProps> = ({ creatorProfile, isViewingOwnProfile, updateProfilePicture }) => {
  const [images, setImages] = useState<ImageType[]>([]);
  const onImageChange = (value: ImageListType) => {
    setImages(value);

    if (value.length > 0) {
      // Upload the new profile picture
      updateProfilePicture(value[0]);
    }
  };

  return (
    <ImageUploading
      value={images}
      onChange={onImageChange}
      onError={showToastOnImageError}
      acceptType={['jpg', 'jpeg', 'png']}
      maxFileSize={2 * 1024 * 1024} // 2MB
    >
      {({ onImageUpload, isDragging, dragProps }) => (
        <ProfilePicWithEditContainer>
          <ProfilePic name={creatorProfile.name} profilePictureURL={creatorProfile.profilePictureURL} width="128px" mobileWidth="100px" />
          {isViewingOwnProfile && (
            <EditPictureLabelBox onClick={onImageUpload} isDragging={isDragging} {...dragProps}>
              <h2>Edit Picture</h2>
            </EditPictureLabelBox>
          )}
        </ProfilePicWithEditContainer>
      )}
    </ImageUploading>
  );
};

const AllButtons: FC<Props> = ({ creatorProfile, queueInfo, isViewingOwnProfile }) => {
  const { voteButtonPressed, hasVotedWithWallet } = useContext(VoteContext);
  const navigate = useNavigate();
  const shareContext: ProfileShareContext = {
    type: 'profile',
    copyLinkURL: `${siteBaseURL}/profile/${creatorProfile.address}`,
    creatorAddress: creatorProfile.address,
    creatorName: creatorProfile.name,
  };

  const isInQueue = queueInfo.response?.queuePosition != null;
  const disabledReason = isInQueue ? 'You are already in the Contest Queue' : undefined;
  const canVote = hasVotedWithWallet === false;

  if (isViewingOwnProfile) {
    // This is my profile. Show edit profile button.
    return (
      <AllButtonsContainer>
        <ActionButton onClick={() => navigate('/edit-profile')}>Edit Profile</ActionButton>
        <ActionButton onClick={() => navigate('/apply')} disabledReason={disabledReason}>
          Enter Contest Queue
        </ActionButton>
      </AllButtonsContainer>
    );
  } else {
    return (
      <AllButtonsContainer>
        {canVote && creatorProfile.isInActiveContest && <VoteButton onClick={() => voteButtonPressed(creatorProfile)} />}
        <ShareButton context={shareContext} />
      </AllButtonsContainer>
    );
  }
};

interface InfoProps {
  title: string;
}

const InfoBox: FC<PropsWithChildren<InfoProps>> = ({ title, children }) => (
  <StyledInfoBox>
    <h1>{title}</h1>
    {children}
  </StyledInfoBox>
);

interface WebsiteProps {
  website: string;
}

const WebsiteLink: FC<WebsiteProps> = ({ website }) => {
  const { state, dispatch } = useContext(AppContext);
  const linkProps: React.ComponentPropsWithoutRef<typeof StyledWebsiteLink> =
    state.shouldShowLinkWarning === false
      ? { href: sanitize(website), target: '_blank', rel: 'noreferrer' }
      : { href: '#', onClick: () => dispatch(setExternalLink(website)) };

  return (
    <StyledWebsiteLink {...linkProps}>
      <p>{website}</p>
      <img src={arrowUpRight} alt="Arrow" />
    </StyledWebsiteLink>
  );
};

interface SocialProps {
  name: string;
  imageSource: string;
  url: string;
}

const SocialLink: FC<SocialProps> = ({ imageSource, name, url }) => {
  const { state, dispatch } = useContext(AppContext);
  const linkProps: React.ComponentPropsWithoutRef<typeof StyledSocialLink> =
    state.shouldShowLinkWarning == false
      ? { href: sanitize(url), target: '_blank', rel: 'noreferrer' }
      : { href: '#', onClick: () => dispatch(setExternalLink(url)) };

  return (
    <StyledSocialLink {...linkProps}>
      <img src={imageSource} alt={url} />
      <p>{name}</p>
    </StyledSocialLink>
  );
};

interface YouTubeEmbedProps {
  videoURL?: string;
}

const YouTubeEmbed: FC<YouTubeEmbedProps> = ({ videoURL }) => {
  if (videoURL == null) {
    return null;
  }
  const embedURL = youTubeEmbedURLFromURL(videoURL);
  if (embedURL == null) {
    return null;
  }

  return (
    <YouTubeContainer>
      <iframe
        width="690"
        height="388"
        src={embedURL}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </YouTubeContainer>
  );
};

interface ImageEmbedProps {
  imageURL?: string;
}

const ImageEmbed: FC<ImageEmbedProps> = ({ imageURL }) => {
  if (imageURL == null) {
    return null;
  }

  return <StyledImageEmbed src={imageURL} alt="Creator Image" />;
};

// Helper functions

interface SocialLinkKey {
  name: string;
  imageSource: string;
  urlKey: keyof CreatorProfileResponseDetails;
}

const allPossibleLinks: SocialLinkKey[] = [
  { name: 'Twitter', urlKey: 'twitterURL', imageSource: twitterSVG },
  { name: 'Facebook', urlKey: 'facebookURL', imageSource: facebookSVG },
  { name: 'Instagram', urlKey: 'instagramURL', imageSource: instagramSVG },
  { name: 'YouTube', urlKey: 'youTubeChannelURL', imageSource: youTubeSVG },
  { name: 'OpenSea', urlKey: 'openSeaURL', imageSource: openSeaSVG },
  { name: 'Reddit', urlKey: 'redditURL', imageSource: redditSVG },
  { name: 'LinkedIn', urlKey: 'linkedInURL', imageSource: linkedInSVG },
  { name: 'TikTok', urlKey: 'tikTokURL', imageSource: tikTokSVG },
  { name: 'Other', urlKey: 'otherSocialURL', imageSource: otherSocialSVG },
];

const allSocialLinkProps = (creatorProfile: CreatorProfileResponseDetails): SocialProps[] => {
  const allProps = allPossibleLinks.reduce((filtered, link) => {
    const url = creatorProfile[link.urlKey];
    if (url && typeof url === 'string') {
      // For the "other" link, the name is the URL itself
      const name = link.urlKey === 'otherSocialURL' ? url : link.name;
      filtered.push({ name: name, imageSource: link.imageSource, url });
    }
    return filtered;
  }, [] as SocialProps[]);
  return allProps;
};

// Styled components

// Top Section

const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;

  margin: auto;
  max-width: ${effectiveMaxWidth()}px;
  padding: 80px ${padding}px 44px ${padding}px;
  background-color: #ffffff;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    flex-direction: column;
    align-items: stretch;
    padding: 46px ${padding}px;
  }
`;

const Divider = styled.div`
  margin: auto;
  max-width: ${effectiveMaxWidth() - padding * 2}px;
  height: 1px;
  box-sizing: border-box;
  background-color: #e5e5e5;
`;

const CreatorHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`;

const ProfilePicWithEditContainer = styled.div`
  position: relative;
  width: 128px;
  height: 128px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    width: 100px;
    height: 100px;
  }
`;

interface EditPictureLabelBoxProps {
  isDragging: boolean;
}

const EditPictureLabelBox = styled.div<EditPictureLabelBoxProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;

  h2 {
    margin: 0;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    pointer-events: ${(props) => (props.isDragging ? 'none' : 'initial')};
  }

  transition: opacity 200ms ease;
  opacity: ${(props) => (props.isDragging ? '1' : '0')};
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

const NameAndCreatorType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  h1 {
    margin: 0px;
    font-weight: 400;
    font-size: 28px;
    color: #101828;
  }
  h2 {
    margin: 0px;
    font-weight: 500;
    font-size: 14px;
    color: #667085;
  }
`;

// Bottom Section

// Contains two columns. Left column is the text blocks, right column is links
const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
  margin: auto;
  max-width: ${effectiveMaxWidth()}px;
  padding: 64px ${padding}px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    flex-direction: column;
    padding: 44px ${padding}px;
  }
`;

const CreatorDetailsLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 690px;
  gap: 64px;
  flex-grow: 1;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    width: 100%;
  }
`;

const TitleAndTextView = styled.div`
  h1 {
    margin: 0px 0px 8px 0px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #002106;
  }
  p {
    margin: 0px;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #72796f;
  }
`;

const CreatorLinksRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
  width: 344px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    width: 100%;
  }
`;

const StyledInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 32px;
  border: 1px solid #e1e5e8;
  border-radius: 12px;
  width: 100%;

  h1 {
    margin: 0px 0px 24px 0px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #424940;
  }
`;

const StyledWebsiteLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  text-decoration: none;

  p {
    margin: 0px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #00a73a;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  svg {
    color: #00a73a;
  }

  &:hover {
    text-decoration: underline;
    text-decoration-color: #00a73a;
    cursor: pointer;
  }
`;

const AllSocialLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const StyledSocialLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  text-decoration: none;

  img {
    width: 24px;
    height: 24px;
    color: #002106;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #002106;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const YouTubeContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const AllButtonsContainer = styled.div`
  margin: 0px auto 38px auto;
  max-width: ${effectiveMaxWidth()}px;
  padding: 0px ${padding}px;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 6px;
`;

const StyledImageEmbed = styled.img`
  max-width: 100%;
  height: auto;
`;
