import axios from 'axios';
import { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import { useWeb3React } from '@web3-react/core';
import { CancelConfirmButtonStack } from '../CancelConfirmButtonStack';
import { Modal } from '../Modal';
import { ReportPayload } from '../../shared/src/ReportPayload';

const apiBaseURL = process.env.REACT_APP_BASE_URL;

interface Props {
  /// The name of the creator being reported
  creatorName: string;
  /// The address of the creator being reported.
  creatorAddress: string;
  isShown: boolean;
  shouldClose(): void;
}

export const ReportModal: FC<Props> = ({ creatorName, creatorAddress, isShown, shouldClose }) => {
  const { account } = useWeb3React();
  const textRef = useRef<HTMLTextAreaElement>(null);
  const [message, setMessage] = useState('');

  return (
    <Modal title="Report" isShown={isShown} shouldClose={shouldClose}>
      <Content>
        <h2>What would you like to report?</h2>
        <textarea ref={textRef} placeholder="Type your message..." onChange={() => setMessage(textRef.current?.value ?? '')}></textarea>
        <CancelConfirmButtonStack
          confirmText="Send"
          confirmButtonDisabled={message.length <= 0}
          shouldClose={shouldClose}
          onConfirm={() => {
            const payload: ReportPayload = {
              creatorName,
              creatorAddress,
              reporterAddress: account ?? undefined,
              message,
            };
            sendReport(payload);
          }}
        />
      </Content>
    </Modal>
  );
};

// Helper functions

const sendReport = async (payload: ReportPayload) => {
  try {
    await axios.post(`${apiBaseURL}/report/`, payload);
  } catch (err) {
    console.error(`Error sending report: ${err}`);
  }
};

// Styled components

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 40px 40px 0px 40px;

  h2 {
    margin-top: 0;
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #002106;
  }

  textarea {
    padding: 10px 14px;
    margin-bottom: 16px;
    border: 1px solid #e1e5e8;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    min-height: 102px;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #002106;

    &::placeholder {
      color: #526350;
    }
  }
`;
