import { useWeb3React } from '@web3-react/core';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { VoteRecordAndName } from '../shared/src/VoteRecord';

const baseURL = process.env.REACT_APP_BASE_URL;

export const useVoteRecord = (): VoteRecordAndName | null => {
  const { active, account } = useWeb3React();
  const [voteRecord, setVoteRecord] = useState<VoteRecordAndName | null>(null);

  useEffect(() => {
    if (active && account != null) {
      fetchVoteForCurrentCycle(account).then(setVoteRecord);
    }
  }, [active, account]);

  return voteRecord;
};

export const useHasVoted = (): boolean => {
  const voteRecord = useVoteRecord();
  return voteRecord != null;
};

// Helpers

const fetchVoteForCurrentCycle = async (account: string): Promise<VoteRecordAndName | null> => {
  try {
    const url = `${baseURL}/votes/vote-for-current-cycle?voter=${account}`;
    const res = await axios.get(url);
    return res.data;
  } catch (err) {
    console.log(`error when fetching profile: ${err}`);
    return null;
  }
};
