import { FC, PropsWithChildren, useState } from 'react';
import styled from 'styled-components';
import { DisabledButtonCallout } from './DisabledButtonCallout';

interface Props {
  disabledReason?: string;
  onClick?: () => void;
}

export const ActionButton: FC<PropsWithChildren<Props>> = ({ children, disabledReason, onClick }) => {
  const [isCalloutShown, setIsCalloutShown] = useState(false);
  const inactive = disabledReason != null;
  const activeOnClick = () => {
    if (!inactive && onClick != null) {
      onClick();
    }
  };

  return (
    <Container>
      <StyledActionButton
        inactive={inactive}
        onClick={activeOnClick}
        onMouseEnter={() => setIsCalloutShown(true)}
        onMouseLeave={() => setIsCalloutShown(false)}
      >
        {children}
      </StyledActionButton>

      {disabledReason && <DisabledButtonCallout isShown={isCalloutShown} reasonText={'You are already in the Contest Queue'} />}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

interface StyledActionButtonProps {
  inactive: boolean;
}

const StyledActionButton = styled.button<StyledActionButtonProps>`
  background: #ffffff;
  border: 1px solid #e1e5e8;
  border-radius: 3px;

  margin: 0;
  padding: 10px 12px;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #002106;

  opacity: ${({ inactive }) => (inactive ? '0.2' : '1.0')};

  ${({ inactive }) =>
    !inactive &&
    `
    &:hover {
      background-color: #f2f4f7;
      cursor: pointer;
    }
  `}
`;
