import { createContext, FC, useState } from 'react';
import { CreatorProfileResponse } from '../../shared/src/CreatorProfile';

type VoteButtonHandler = (contestant: CreatorProfileResponse) => void;

interface VoteState {
  /// An opaque token that can be used as a dependency. The value will change each
  /// time a vote is cast.
  didUpdateVotes: any;
  /// Whether the connected wallet has voted in the current contest
  hasVotedWithWallet: boolean;
  /// Called when any vote button is pressed to show the appropriate vote modal
  voteButtonPressed(contestant: CreatorProfileResponse): void;
  /// Called by the vote modal presenter to update the handler for the vote buttons
  setVoteButtonPressedHandler(handler: VoteButtonHandler): void;
  /// Called by the vote modal presenter after a vote has been cast
  toggleDidUpdateVotes(): void;
  /// Called by the vote modal presenter to update the `hasVotedWithWallet` state
  setHasVotedWithWallet(hasVoted: boolean): void;
}

/// Need to use the box type because `useState` behaves weirdly when the state is a function
interface HandlerBox {
  handler: VoteButtonHandler;
}

export const VoteContext = createContext<VoteState>(undefined as any);

export const VoteContextProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const [currentVoteHandler, setCurrentVoteHandler] = useState<HandlerBox | null>(null);
  const [didUpdateVotes, setDidUpdateVotes] = useState<boolean>(false);
  const [hasVotedWithWallet, setHasVotedWithWallet] = useState<boolean>(false);

  const setVoteButtonPressedHandler = (handler: VoteButtonHandler) => setCurrentVoteHandler({ handler });
  const voteButtonPressed: VoteButtonHandler = (contestant: CreatorProfileResponse) => {
    currentVoteHandler && currentVoteHandler.handler(contestant);
  };

  const state: VoteState = {
    didUpdateVotes,
    hasVotedWithWallet,
    voteButtonPressed,
    setVoteButtonPressedHandler,
    toggleDidUpdateVotes: () => setDidUpdateVotes(!didUpdateVotes),
    setHasVotedWithWallet,
  };

  return <VoteContext.Provider value={state}>{children}</VoteContext.Provider>;
};
