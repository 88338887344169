import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { WalletID, storeWalletID, wallets, resetWalletStorage, loadActiveWalletFromStorage } from '../helpers/wallets';
import { connectedWalletEvent, trackEvent } from './analytics';

interface Result {
  isWalletActive: boolean;
  activateAndStore(walletID: WalletID): void;
  deactivateWallet(): void;
}

/// Activate and deactivate a provided wallet ID, and store the activated
/// wallet ID in local storage if activation is successful.
export const useActivateAndStoreWallet = (): Result => {
  const { active, activate, deactivate } = useWeb3React();
  const [activatingWalletID, setActivatingWalletID] = useState<WalletID | null>(null);

  useEffect(() => {
    if (activatingWalletID != null && active) {
      // Activation success
      storeWalletID(activatingWalletID);
      setActivatingWalletID(null);
      trackEvent(connectedWalletEvent());
    }
  }, [activatingWalletID, active]);

  const activateAndStore = (walletID: WalletID) => {
    setActivatingWalletID(walletID);
    activate(wallets[walletID], (err) => {
      // Activation failed
      setActivatingWalletID(null);
      console.error(`Error activating wallet: ${err}`);
    });
  };

  const deactivateWallet = () => {
    resetWalletStorage();
    deactivate();
  };

  return {
    isWalletActive: active,
    activateAndStore,
    deactivateWallet,
  };
};

/// Activate a wallet ID that was previously stored in local storage
export const useActivateStoredWallet = (): void => {
  const { activate } = useWeb3React();

  // Activate Wallet if one was previously stored in storage
  useEffect(() => {
    const wallet = loadActiveWalletFromStorage();
    if (wallet) {
      activate(wallet, (err) => {
        console.error(`Error activating stored wallet: ${err}`);
        resetWalletStorage();
      });
    }
  }, []);
};
