import { FC } from 'react';
import styled from 'styled-components';
import { CreatorProfileResponse } from '../CreatorProfile';
import { effectiveMobileMaxWidth } from './Constants';

export interface VotingOptionsProps {
  creatorProfile: CreatorProfileResponse | null;
  didClickConnectWallet(): void;
  didClickContinueWithoutCrypto(profile: CreatorProfileResponse | null): void;
}

export const VotingOptionsContents: FC<VotingOptionsProps> = ({ creatorProfile, didClickConnectWallet, didClickContinueWithoutCrypto }) => (
  <Container>
    <TitleLabels>
      <h1>How to Vote</h1>
      <h2>Select which voting method you want to use.</h2>
    </TitleLabels>
    <VoteOptionsContainer>
      <VoteOptionBox
        titleText="Vote with APOLLO tokens"
        infoText="Requires a crypto wallet with $APOLLO tokens."
        pointsText="+10&nbsp;Vote&nbsp;Points &amp; Lottery&nbsp;Entry"
        buttonText="Connect Wallet"
        onClick={didClickConnectWallet}
      />
      <VoteOptionBox
        titleText="Vote without crypto"
        infoText="No crypto? No problem. Cast a free vote."
        pointsText="+1&nbsp;Vote&nbsp;Point"
        buttonText="Continue without Crypto"
        onClick={() => didClickContinueWithoutCrypto(creatorProfile)}
      />
    </VoteOptionsContainer>
    <FooterLabel>
      Want to buy APOLLO tokens but need help?{' '}
      <a href="https://docs.apollocrypto.org/apollo-docs/buying-apollo/buying-overview" target="_blank" rel="noreferrer">
        Learn how
      </a>
    </FooterLabel>
  </Container>
);

interface VoteOptionBoxProps {
  titleText: string;
  infoText: string;
  pointsText: string;
  buttonText: string;
  onClick(): void;
}

const VoteOptionBox: FC<VoteOptionBoxProps> = ({ titleText, infoText, pointsText, buttonText, onClick }) => (
  <StyledVoteOptionBox>
    <VoteOptionsLabels>
      <h2>{titleText}</h2>
      <h3>
        {infoText} <VotePointsText>{pointsText}</VotePointsText>
      </h3>
    </VoteOptionsLabels>
    <Button onClick={onClick}>{buttonText}</Button>
  </StyledVoteOptionBox>
);

// Styled components

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 32px;
  padding: 0px 40px;
  background-color: #ffffff;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    padding: 0px 16px;
  }
`;

const TitleLabels = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;

  & > h1 {
    margin: 0;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #002106;
  }

  & > h2 {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #72796f;
  }
`;

const VoteOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
`;

const StyledVoteOptionBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 24px;

  background: rgba(229, 238, 255, 0.4);
  border: 1px solid #e5eeff;
  border-radius: 8px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    padding: 20px 16px;
  }
`;

const VoteOptionsLabels = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;

  & > h2 {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    color: #002106;
  }

  & > h3 {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #72796f;
  }
`;

const VotePointsText = styled.span`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #00c648;
`;

const Button = styled.button`
  margin: 0;
  padding: 11px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #002106;

  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #e1e5e8;
  border-radius: 3px;

  &:hover {
    cursor: pointer;
    background-color: #f2f4f7;
  }
`;

const FooterLabel = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  letter-spacing: -0.01em;
  color: #000000;
  opacity: 0.8;

  & > a {
    opacity: 1;
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
  }
`;
