import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { infuraHTTPEndpoint } from './infura';

const infuraEndpoint = infuraHTTPEndpoint();
const chainId = parseInt(process.env.REACT_APP_CHAIN_ID as string);

const WalletConnect = new WalletConnectConnector({
  supportedChainIds: [chainId],
  rpcUrl: infuraEndpoint,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
} as any);

const MetaMask = new InjectedConnector({
  supportedChainIds: [chainId],
});

const Coinbase = new WalletLinkConnector({
  supportedChainIds: [chainId],
  appName: 'Apollo Inu',
  url: infuraEndpoint,
});

export type WalletID = 'walletConnect' | 'metaMask' | 'coinbase';
export type Wallets = {
  [walletID in WalletID]: AbstractConnector;
};

export const wallets: Wallets = {
  walletConnect: WalletConnect,
  metaMask: MetaMask,
  coinbase: Coinbase,
};

// Storage

const walletIDStorageKey = 'walletID';

export const storeWalletID = (walletID: WalletID): void => window.localStorage.setItem(walletIDStorageKey, walletID);

export const resetWalletStorage = (): void => window.localStorage.removeItem(walletIDStorageKey);

export const loadActiveWalletFromStorage = (): AbstractConnector | null => {
  const walletID = window.localStorage.getItem(walletIDStorageKey);
  if (walletID) {
    return wallets[walletID as WalletID];
  }
  return null;
};
