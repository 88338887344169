import { FC } from 'react';
import { observe } from '../../helpers/observe';
import { clickedWalletButtonFromVoteOptionsEvent, trackEvent, useGAModalViewTracking } from '../../hooks/analytics';
import { CreatorProfileResponse } from '../../shared/src/CreatorProfile';
import { VotingOptionsContents } from '../../shared/src/ui/VotingOptionsContents';
import { Modal } from '../Modal';

export interface VotingOptionsModalProps {
  creatorProfile: CreatorProfileResponse | null;
  isShown: boolean;
  shouldClose(): void;
  didClose(): void;
  didClickConnectWallet(): void;
  didClickContinueWithoutCrypto(profile: CreatorProfileResponse | null): void;
}

export const VotingOptionsModal: FC<VotingOptionsModalProps> = (props) => {
  useGAModalViewTracking(props.isShown, 'vote-options');

  const { didClickConnectWallet, ...rest } = props;
  const _didClickConnectWallet = observe(didClickConnectWallet, () => {
    trackEvent(clickedWalletButtonFromVoteOptionsEvent());
  });

  return (
    <Modal contentWidth="500px" fullScreenOnMobile {...props}>
      <VotingOptionsContents {...rest} didClickConnectWallet={_didClickConnectWallet} />
    </Modal>
  );
};
