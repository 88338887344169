import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { APIResponse } from '../shared/src/APIResponse';
import { Series } from '../shared/src/Series';
import { WithId } from '../shared/src/WithId';

const apiBaseURL = process.env.REACT_APP_BASE_URL as string;

/// Hook for fetching a series using it's application path
export const useSeriesByApplyPath = (isEditingProfile: boolean, path?: string): WithId<Series> | null => {
  const [series, setSeries] = useState<WithId<Series> | null>(null);
  useEffect(() => {
    /// Don't bother fetching if we're editing the profile
    if (isEditingProfile === false && path != null) {
      fetchSeries(path).then(setSeries);
    } else {
      setSeries(null);
    }
  }, [path, isEditingProfile]);
  return series;
};

/// Endpoint
const fetchSeries = async (applicationURLPath: string): Promise<WithId<Series> | null> => {
  try {
    const response: AxiosResponse<APIResponse<WithId<Series>[]>> = await axios.get(
      `${apiBaseURL}/series/?applicationURLPath=${applicationURLPath}`
    );
    const series = response.data.data;
    if (series.length === 1) {
      return series[0];
    } else {
      console.error(`Series response had invalid length. Expected 1. ${JSON.stringify(series, null, 2)}`);
      return null;
    }
  } catch (err) {
    console.error(`Failed to fetch series: ${err}`);
    return null;
  }
};
