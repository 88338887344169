import { FC } from 'react';
import styled from 'styled-components';
import { useWeb3React } from '@web3-react/core';
import { ContestWithCreators } from '../../shared/src/ContestSummary';
import { NominatedCreatorsSection } from '../NominatedCreatorsSection';
import { VotesSection } from '../VotesSection';
import { ContestInfoBanner } from '../ContestInfoBanner';
import { usePrizeDetails } from '../../hooks/usePrizeDetails';
import { ContextSpecificCTA } from '../ContextSpecificCTA';
import { effectiveMaxWidth, effectiveMobileMaxWidth, padding } from '../../shared/src/ui/Constants';
import { WithId } from '../../shared/src/WithId';
import { useHasVoted } from '../../hooks/useVoteRecord';
import { NewPrizeInfo } from '../../shared/src/ui/NewPrizeInfo';
import { ContestTitleBanner, ContestTitleBannerProps } from '../../shared/src/ui/ContestTitleBanner';
import { SponsorInfo } from '../../shared/src/ui/SponsorInfoBox';

interface Props {
  contest: WithId<ContestWithCreators>;
}

export const CurrentContest: FC<Props> = ({ contest }) => {
  document.title = 'Apollo DAO Creator Contest';
  const { active, account } = useWeb3React();
  const prizeDetails = usePrizeDetails();
  const hasVoted = useHasVoted();
  const bannerProps = contestBannerPropsFromContest(contest);

  return (
    <>
      <TopSection>
        <BannerAndPrizeInfo>
          <ContestTitleBanner {...bannerProps} />
          <NewPrizeInfo prizeDetails={prizeDetails} isWalletConnected={active && account != null} hasVoted={hasVoted} />
        </BannerAndPrizeInfo>
      </TopSection>

      <ContestInfoBanner />
      <CTAContainer>
        <ContextSpecificCTA context="voting" contest={contest} />
      </CTAContainer>
      <NominatedCreatorsSection />
      <VotesSection />
    </>
  );
};

const contestBannerPropsFromContest = (contest: WithId<ContestWithCreators>): ContestTitleBannerProps => {
  return {
    contestName: contest.name,
    startDate: contest.startDate,
    endDate: contest.endDate,
    backgroundImageURL: contest.bannerBackgroundImageURL ?? '/default-banner-background.jpg',
    sponsorInfo: sponsorInfoFromContest(contest),
  };
};

const sponsorInfoFromContest = (contest: WithId<ContestWithCreators>): SponsorInfo | undefined => {
  if (contest.sponsorName == null || contest.sponsorIconURL == null) {
    return undefined;
  }
  return {
    name: contest.sponsorName,
    iconURL: contest.sponsorIconURL,
  };
};

// Styled components

const TopSection = styled.div`
  background-color: #000000;
`;

const BannerAndPrizeInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;

  margin: auto;
  max-width: ${effectiveMaxWidth()}px;
  padding: 48px ${padding}px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const CTAContainer = styled.div`
  margin: auto;
  max-width: ${effectiveMaxWidth()}px;
  padding: 40px ${padding}px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    padding: 16px;
  }
`;
