import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { effectiveMobileMaxWidth } from '../../shared/src/ui/Constants';
import { QueueInfo } from '../../hooks/useQueuePosition';
import { WithId } from '../../shared/src/WithId';
import { Account } from '../Wallet/ConnectWalletModal';
import { Series } from '../../shared/src/Series';

const apiBaseURL = process.env.REACT_APP_BASE_URL;

type Context = 'applying' | 'profile';

interface Props {
  context: Context;
  voter?: Account;
  queueInfo?: QueueInfo;
  series?: WithId<Series>;
}

export const ContestantQueueInfoBox: FC<Props> = ({ context, voter, queueInfo, series }) => {
  const [countInQueue, setCountInQueue] = useState<number | null>(null);
  useEffect(() => {
    if (series != null) {
      fetchCountInQueue(series).then(setCountInQueue);
    } else {
      setCountInQueue(null);
    }
  }, [series]);

  if (context === 'applying') {
    return (
      <Container>
        <h2>Contestant Queue</h2>
        <p>{countInQueue != null && applicantsString(countInQueue)}</p>
      </Container>
    );
  } else if (context === 'profile' && voter != null && voter === queueInfo?.address && queueInfo?.response?.queuePosition != null) {
    // We are viewing the current user's profile, and they are already in the queue
    const seriesName = queueInfo.response.series?.name ?? 'Creator Series';
    return (
      <Container>
        <h2>Contestant Queue</h2>
        <p>
          You are number {queueInfo.response.queuePosition} in the {seriesName} queue.
        </p>
      </Container>
    );
  } else {
    return null;
  }
};

const fetchCountInQueue = async (series: WithId<Series>): Promise<number | null> => {
  try {
    const response = await axios.get(`${apiBaseURL}/queue/count/${series._id}`);
    return response.data.data;
  } catch (err) {
    console.error(`Error fetching count queued: ${err}`);
    return null;
  }
};

const applicantsString = (applicants: number): string => (applicants == 1 ? '1 applicant in queue.' : `${applicants} applicants in queue.`);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 32px 40px;
  max-width: 400px;

  background-color: #ffffff;
  border: 2px solid #d5e8d0;
  border-radius: 8px;

  h2 {
    margin: 0;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    color: #002106;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #0f1f10;
  }

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    max-width: unset;
  }
`;
