/// Takes a YouTube video URL and returns the embed URL for that video
///
/// Example URLs that are supported:
/// - https://youtu.be/00qfndKIJFM
/// - https://www.youtube.com/watch?v=M8SwF2qUtts
/// - http://www.youtube.com/watch?v=M8SwF2qUtts
/// - https://www.youtube.com/embed/M8SwF2qUtts
/// - http://www.youtube.com/v/-wtIMTCHWuI?version=3&autohide=1
export const youTubeEmbedURLFromURL = (videoURL: string): string | null => {
  const videoIDRegex = /https?:\/\/(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:v\/)?(?:embed\/)?(?:watch\?v=)?([^?\n]+)/;
  const results = videoIDRegex.exec(videoURL);
  if (results != null && results.length > 1) {
    const videoID = results[1]; // first capture group
    return `https://www.youtube.com/embed/${videoID}`;
  } else {
    return null;
  }
};
