import { FC, useContext } from 'react';
import styled from 'styled-components';
import { Modal } from '../Modal';
import { useGAModalViewTracking } from '../../hooks/analytics';
import { WalletID } from '../../helpers/wallets';
import { useActivateAndStoreWallet } from '../../hooks/useActivateAndStoreWallet';
import { AppContext } from '../../hooks/context';
import { setShouldShowConnectWalletModal } from '../../hooks/state';

export type Account = string | null | undefined;

export const ConnectWalletModal: FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const isShown = state.shouldShowConnectWalletModal;
  const shouldClose = () => dispatch(setShouldShowConnectWalletModal(false));

  useGAModalViewTracking(isShown, 'connect-wallet');

  const { isWalletActive, activateAndStore, deactivateWallet } = useActivateAndStoreWallet();
  const activateAndClose = (walletID: WalletID) => {
    activateAndStore(walletID);
    shouldClose();
  };

  return (
    <Modal isShown={isShown} shouldClose={shouldClose} contentWidth="360px">
      <ModalContent>
        <ProviderButton type="button" onClick={() => activateAndClose('metaMask')} disabled={isWalletActive}>
          <img src="/mm.png" />
          MetaMask
        </ProviderButton>
        <ProviderButton type="button" onClick={() => activateAndClose('walletConnect')} disabled={isWalletActive}>
          <img src="/wc.png" />
          WalletConnect
        </ProviderButton>
        <ProviderButton type="button" onClick={() => activateAndClose('coinbase')} disabled={isWalletActive}>
          <img src="/cbw.png" />
          Coinbase Wallet
        </ProviderButton>

        <ProviderButton type="button" onClick={deactivateWallet} disabled={!isWalletActive}>
          Disconnect
        </ProviderButton>
      </ModalContent>
    </Modal>
  );
};

// Styled components

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const ProviderButton = styled.button`
  width: 200px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-weight: 500;
  font-size: 15px;
  color: #002106;
  background-color: #ffffff;
  border: 1px solid #e1e5e8;
  border-radius: 4px;

  &:disabled {
    opacity: 0.6;
  }
  &:not(:disabled):hover {
    background-color: #f2f4f7;
    cursor: pointer;
  }

  img {
    border-radius: 3px;
    width: 25px;
    height: 25px;
  }
`;
