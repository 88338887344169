import { FC } from 'react';
import styled from 'styled-components';
import { SeriesState } from './SeriesCard';
import starSVG from '../svg/star.svg';

interface Props {
  /// Whether the little badge at the top says "Active" or "Complete"
  state: SeriesState;
  /// The number of contests in the series
  contestsCount: number;
  /// The number of contests in the series that have already completed
  contestsCompleted: number;
}

export const ContestCountIndicator: FC<Props> = (props) => {
  const isSeriesCompleted = props.state === 'complete';
  const numbersCount = Math.min(props.contestsCount, 6);
  const isTruncated = numbersCount < props.contestsCount;

  const elements: React.ReactNode[] = [];
  for (let index = 0; index < numbersCount; index++) {
    const isCompleted = index < props.contestsCompleted && !isSeriesCompleted;

    const isLast = index === numbersCount - 1;
    const isSecondToLast = index === numbersCount - 2;
    const isThirdToLast = index === numbersCount - 3;

    const contestNumber = isSecondToLast && isTruncated ? props.contestsCount - 1 : index + 1;
    const numberContents = isLast ? <img src={starSVG} alt="star" /> : <p>{contestNumber}</p>;

    elements.push(
      <NumberCircle key={index} completed={isCompleted}>
        {numberContents}
      </NumberCircle>
    );
    if (!isLast) {
      elements.push(<Line key={`${index}-line`} completed={isCompleted} dashedLine={isTruncated && isThirdToLast} />);
    }
  }

  return <StyledContestCountIndicator>{elements}</StyledContestCountIndicator>;
};

// Styled components

const StyledContestCountIndicator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0;

  height: 24px;
`;

interface NumberCircleProps {
  completed?: boolean;
}

const NumberCircle = styled.div<NumberCircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
  background-color: #000000;
  border: 0.75px solid ${(props) => (props.completed ? '#00DC55' : '#ffffff')};
  border-radius: 100px;

  p {
    transform: translate(0, 1px);
    margin: 0;
    font-weight: 700;
    font-size: 11.25px;
    line-height: 100%;
    color: ${(props) => (props.completed ? '#00DC55' : '#ffffff')};
  }
`;

interface LineProps {
  dashedLine?: boolean;
  completed?: boolean;
}

const Line = styled.div<LineProps>`
  width: 24px;
  height: 1px;
  border: none;

  border-top-width: 1px;
  border-top-color: ${(props) => (props.completed ? '#00DC55' : '#ffffff')};
  border-top-style: ${(props) => (props.dashedLine ? 'dashed' : 'solid')};
`;
