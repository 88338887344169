import { FC, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ProfilePic } from '../ProfilePic';
import { DropdownMenu, DropdownMenuContainer, DropdownMenuItem, DropdownMenuProps } from '../DropdownMenu';
import { useDropdownMenu } from '../../hooks/useDropdownMenu';
import arrowSVG from '../../svg/arrow-down.svg';
import walletSVG from '../../svg/wallet.svg';
import { AppContext } from '../../hooks/context';
import { setShouldShowConnectWalletModal } from '../../hooks/state';
import { effectiveMobileMaxWidth } from '../../shared/src/ui/Constants';

interface ProfileInfo {
  name: string;
  profilePictureURL?: string;
  queuePosition: number | null | undefined;
}

interface Props {
  address: string;
  profileInfo: ProfileInfo | null | undefined;
}

export const WalletProfileButton: FC<Props> = ({ address, profileInfo }) => {
  const { isDropdownShown, setIsDropdownShown, buttonRef, menuRef } = useDropdownMenu();
  const queuePosition = profileInfo?.queuePosition ?? null;
  const { dispatch } = useContext(AppContext);
  const showConnectWalletModal = () => dispatch(setShouldShowConnectWalletModal(true));

  return (
    <Container>
      <QueuePositionBanner isDisplayed={queuePosition != null}>
        <p>#{queuePosition ?? ''} in queue</p>
      </QueuePositionBanner>
      <DropdownMenuContainer>
        <StyledWalletProfileButton ref={buttonRef} onClick={() => setIsDropdownShown(true)}>
          <Image profileInfo={profileInfo} />
          <p>{accountText(address)}</p>
          <img className="arrow" src={arrowSVG} alt="Menu arrow" />
        </StyledWalletProfileButton>

        <WalletDropdownMenu
          forwardRef={menuRef}
          address={address}
          profileInfo={profileInfo}
          isShown={isDropdownShown}
          rightAlignment="0px"
          dismiss={() => setIsDropdownShown(false)}
          showWalletModal={showConnectWalletModal}
        />
      </DropdownMenuContainer>
    </Container>
  );
};

interface ImageProps {
  profileInfo: ProfileInfo | null | undefined;
}

const Image: FC<ImageProps> = ({ profileInfo }) => {
  if (profileInfo != null) {
    return <ProfilePic {...profileInfo} width="24px" />;
  } else {
    return <img src={walletSVG} alt="Wallet" />;
  }
};

type WalletDropdownMenuProps = DropdownMenuProps &
  React.ComponentPropsWithoutRef<typeof DropdownMenu> & {
    forwardRef: React.MutableRefObject<any>;
    address: string;
    profileInfo: ProfileInfo | null | undefined;
    dismiss(): void;
    showWalletModal(): void;
  };

const WalletDropdownMenu: FC<WalletDropdownMenuProps> = ({ forwardRef, address, profileInfo, dismiss, showWalletModal, ...props }) => {
  const navigate = useNavigate();
  return (
    <DropdownMenu ref={forwardRef} {...props}>
      {profileInfo && <DropdownMenuItem text="Profile" onClick={() => navigate(`/profile/${address}`)} dismiss={dismiss} />}
      <DropdownMenuItem text="Wallet" onClick={showWalletModal} dismiss={dismiss} />
    </DropdownMenu>
  );
};

// Helper functions

const accountText = (account: string): string => account.substring(0, 8).toLowerCase();

// Styled components

const Container = styled.div`
  position: relative;
`;

const StyledWalletProfileButton = styled.button`
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;

  background-color: #ffffff;
  border: 1px solid #e1e5e8;
  border-radius: 6px;

  p {
    margin: 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: #002106;
  }

  &:hover {
    background-color: #f2f4f7;
    cursor: pointer;
  }

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    p,
    img.arrow {
      display: none;
    }
  }
`;

interface QueuePositionBannerProps {
  isDisplayed: boolean;
}

const QueuePositionBanner = styled.div<QueuePositionBannerProps>`
  position: absolute;

  right: ${({ isDisplayed }) => (isDisplayed ? 'calc(100% - 16px)' : '0px')};
  transition: right 400ms ease;
  padding: 12px 32px 12px 16px;

  background-color: #ffffff;
  border: 1px solid #d5e8d0;
  border-radius: 6px;

  p {
    margin: 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    white-space: nowrap;
    color: #002106;
  }
`;
