type LocalStorageKeys = 'SHOULD_SHOW_LINK_WARNING' | 'SET_SHOULD_SHOW_INFO_BANNER';
const externalLinkKey: LocalStorageKeys = 'SHOULD_SHOW_LINK_WARNING';
const shouldShowInfoBannerKey: LocalStorageKeys = 'SET_SHOULD_SHOW_INFO_BANNER';

export const getExternalLinkDefault = (): boolean => {
  const value = window.localStorage.getItem(externalLinkKey);
  return value == null ? true : value === 'true';
};

export const setExternalLinkDefault = (value: boolean): void => {
  window.localStorage.setItem(externalLinkKey, value.toString());
};

export const getShouldShowInfoBannerDefault = (): boolean => {
  const value = window.localStorage.getItem(shouldShowInfoBannerKey);
  return value == null ? true : value === 'true';
};

export const setShouldShowInfoBannerDefault = (value: boolean): void => {
  window.localStorage.setItem(shouldShowInfoBannerKey, value.toString());
};
