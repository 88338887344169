import { useWeb3React } from '@web3-react/core';
import { FC, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import { AppContext } from '../../hooks/context';
import { setShouldShowConnectWalletModal } from '../../hooks/state';
import { useModal, UseModalResult } from '../../hooks/useModal';
import { CreatorProfileResponse } from '../../shared/src/CreatorProfile';
import { VoteRecord } from '../../shared/src/VoteRecord';
import { TRANSITION_DURATION } from '../Modal';
import { VoteModal } from '../VoteModal';
import { EmailVoteModal } from './EmailVoteModal';
import { useCurrentBalance } from './useCurrentBalance';
import { useCurrentCycleVoteRecord } from './useVoteRecord';
import { VoteContext } from './VoteContextProvider';
import { VotingOptionsModal, VotingOptionsModalProps } from './VotingOptionsModal';

export interface VotePayload {
  /// The wallet address of the person casting the vote
  voter: string;
  /// The wallet address of the vote recipient
  nominee: string;
  /// The current cycle ID of the contest
  cycleId: number;
  /// The voter's current balance of APOLLO
  apolloBalance: string;
  /// The creator profile of the vote recipient
  profile: CreatorProfileResponse;
}

export const VoteModalPresenter: FC = () => {
  const { account, active, library } = useWeb3React();
  const { currentCycleId, voteRecord, reloadVoteRecord } = useCurrentCycleVoteRecord(account ?? null);
  const { balance } = useCurrentBalance(account ?? null);

  const { setVoteButtonPressedHandler, toggleDidUpdateVotes, setHasVotedWithWallet } = useContext(VoteContext);
  const votingOptionsModal = useModal<CreatorProfileResponse>();
  const walletModal = useModal<VotePayload>();
  const emailModal = useModal<CreatorProfileResponse>();

  const { dispatch } = useContext(AppContext);
  const showWalletModal = () => dispatch(setShouldShowConnectWalletModal(true));

  // Update the handler that is called when someone clicks the vote button
  useEffect(() => {
    const votePressedHandler = makeVoteButtonPressedHandler(
      account ?? null,
      active,
      currentCycleId,
      voteRecord,
      balance,
      walletModal.showModal,
      votingOptionsModal.showModal
    );
    setVoteButtonPressedHandler(votePressedHandler);
  }, [account, active, library, currentCycleId, voteRecord, balance]);

  // Update the state of whether the user has voted with a wallet
  useEffect(() => {
    // If the vote record exists, the user has voted with this wallet
    setHasVotedWithWallet(voteRecord != null);
  }, [voteRecord]);

  const didUpdateVotes = () => {
    toggleDidUpdateVotes();
    reloadVoteRecord();
  };

  const votingOptionsProps = makeVotingOptionsModalProps(votingOptionsModal, emailModal, showWalletModal);

  return (
    <>
      <VotingOptionsModal {...votingOptionsProps} />
      <VoteModal votePayload={walletModal.value} web3={library as Web3} didUpdateVotes={didUpdateVotes} {...walletModal} />
      <EmailVoteModal {...emailModal} />
    </>
  );
};

/// Create the props used by the voting options modal
const makeVotingOptionsModalProps = (
  votingOptionsModal: UseModalResult<CreatorProfileResponse>,
  emailModal: UseModalResult<CreatorProfileResponse>,
  showWalletModal: () => void
): VotingOptionsModalProps => {
  return {
    creatorProfile: votingOptionsModal.value,
    isShown: votingOptionsModal.isShowingModal,
    shouldClose: votingOptionsModal.shouldClose,
    didClose: votingOptionsModal.didClose,
    didClickConnectWallet: () => {
      votingOptionsModal.shouldClose();
      showWalletModal();
    },
    didClickContinueWithoutCrypto: (creatorProfile) => {
      votingOptionsModal.shouldClose();
      if (creatorProfile != null) {
        setTimeout(() => emailModal.showModal(creatorProfile), TRANSITION_DURATION);
      }
    },
  };
};

/// The function that is called anytime a vote button is pressed in the app
const makeVoteButtonPressedHandler =
  (
    account: string | null,
    active: boolean,
    currentCycleId: number | null,
    voteRecord: VoteRecord | null,
    balance: string | null,
    showWalletModal: (payload: VotePayload) => void,
    showVotingOptionsModal: (contestant: CreatorProfileResponse) => void
  ) =>
  (contestant: CreatorProfileResponse) => {
    try {
      const currentTimestamp = Math.floor(Date.now() / 1000);
      if (voteRecord != null) {
        toast.error('You have already voted');
      } else if (!active || !account) {
        // Show the voting options modal
        showVotingOptionsModal(contestant);
      } else if (currentCycleId != null && currentCycleId > currentTimestamp) {
        // Show the wallet vote modal
        const payload: VotePayload = {
          voter: account,
          nominee: contestant.address,
          cycleId: currentCycleId,
          apolloBalance: balance ?? '0',
          profile: contestant,
        };
        showWalletModal(payload);
      } else {
        toast.error('The contest might already be over. Reload the page and try again.');
      }
    } catch (err) {
      console.error(`Failed to cast vote: ${err}`);
    }
  };
