import { useWeb3React } from '@web3-react/core';
import { FC } from 'react';
import styled from 'styled-components';
import { VoteRecordAndName } from '../shared/src/VoteRecord';
import { Contest } from '../shared/src/Contest';
import { ConnectWalletButton } from './Wallet/ConnectWallet';
import { ShareCTAs } from './ShareCTAs';
import { effectiveMobileMaxWidth } from '../shared/src/ui/Constants';
import { useMinimumNominationBalance } from '../hooks/useMinimumBalance';
import { minimumVoteBalanceStringFromContest } from '../Api/blockchain';
import { useVoteRecord } from '../hooks/useVoteRecord';

/// The context in which the CTA is displayed. Either applying as a creator
/// or voting for a creator.
export type CTAContext = 'applying' | 'voting' | 'contests' | 'pastContest';

interface Props {
  context: CTAContext;
  contest?: Contest;
}

export const CallToActionBox: FC<Props> = ({ context, contest }) => {
  const { active, account } = useWeb3React();
  const minimumBalance = useMinimumBalance(context, contest);

  if (active === false || account == null) {
    return <ConnectToAct context={context} minimumBalance={minimumBalance} />;
  } else if (context === 'voting') {
    return <VoteCTA minimumVoteBalance={minimumBalance} context={context} />;
  } else if (context === 'applying') {
    return <ApplyCTA minimumApplyBalance={minimumBalance} context={context} />;
  } else {
    return null;
  }
};

interface ConnectToActProps {
  context: CTAContext;
  minimumBalance: string | null;
}

const ConnectToAct: FC<ConnectToActProps> = ({ context, minimumBalance }) => {
  const action = capitalizedActionForContext(context);

  return (
    <Container context={context}>
      <h1>Connect Wallet to {action}</h1>
      <p>
        1. Connect your DeFi wallet
        <br />
        2. {minimumBalanceInstruction(minimumBalance, context)}
      </p>
      <ButtonAndHelp>
        <ConnectWalletButton fontSize="14px" />
        <a href="https://docs.apollocrypto.org/docs/v/apollo-creator-voting-system/" target="_blank" rel="noreferrer">
          Need Help?
        </a>
      </ButtonAndHelp>
    </Container>
  );
};

interface VoteCTAProps {
  minimumVoteBalance: string | null;
  context: CTAContext;
}

const VoteCTA: FC<VoteCTAProps> = ({ minimumVoteBalance, context }) => {
  const voteRecord = useVoteRecord();

  if (voteRecord != null) {
    return <YouVoted {...voteRecord} context={context} />;
  } else {
    return <PendingVote minimumVoteBalance={minimumVoteBalance} context={context} />;
  }
};

interface PendingVoteProps {
  minimumVoteBalance: string | null;
  context: CTAContext;
}

const PendingVote: FC<PendingVoteProps> = ({ minimumVoteBalance, context }) => (
  <Container context={context}>
    <h1>You have 1 vote to cast</h1>
    <p>
      1. Pick a Creator
      <br />
      2. Click “Cast Vote”
      <br />
      3. {minimumBalanceInstruction(minimumVoteBalance, 'voting')}
    </p>
  </Container>
);

type YouVotedProps = VoteRecordAndName & { context: CTAContext };

const YouVoted: FC<YouVotedProps> = ({ nomineeName, nominee, context }) => (
  <Container context={context}>
    <h1>You voted for {nomineeName}</h1>
    <ShareContainer>
      <h2>SHARE</h2>
      <ShareCTAs creatorName={nomineeName} creatorAddress={nominee} type={'profile'} />
    </ShareContainer>
  </Container>
);

interface ApplyProps {
  minimumApplyBalance: string | null;
  context: CTAContext;
}

const ApplyCTA: FC<ApplyProps> = ({ minimumApplyBalance, context }) => (
  <Container context={context}>
    <h1>Submit your application</h1>
    <p>
      1. Fill out the application
      <br />
      2. {minimumBalanceInstruction(minimumApplyBalance, 'applying')}
    </p>
  </Container>
);

// Helper functions

const capitalizedActionForContext = (context: CTAContext): string => {
  switch (context) {
    case 'voting':
    case 'contests':
      return 'Full Vote';
    case 'applying':
      return 'Apply';
    default:
      console.error(`Unsupported context type: ${context}`);
      return 'Act';
  }
};

const useMinimumBalance = (context: CTAContext, contest?: Contest): string | null => {
  switch (context) {
    case 'voting':
      return contest != null ? minimumVoteBalanceStringFromContest(contest) : null;
    case 'applying':
      return useMinimumNominationBalance();
    default:
      return null;
  }
};

const minimumBalanceInstruction = (balance: string | null, context: CTAContext): JSX.Element => {
  const actionText = capitalizedActionForContext(context).toLowerCase();
  return balance != null ? (
    <>
      You must hold <a href="#">{balance} $APOLLO</a> for a {actionText}
    </>
  ) : (
    <>
      You must hold <a href="#">$APOLLO</a> for a {actionText}
    </>
  );
};

// Styled components

interface ContainerProps {
  context: CTAContext;
}

const Container = styled.div<ContainerProps>`
  display: flex;

  flex-direction: ${(props) => (props.context === 'applying' ? 'column' : 'row')};
  align-items: ${(props) => (props.context === 'applying' ? 'flex-start' : 'center')};
  justify-content: ${(props) => (props.context === 'applying' ? 'unset' : 'center')};
  gap: ${(props) => (props.context === 'applying' ? '16px' : '56px')};
  padding: ${(props) => (props.context === 'applying' ? '32px 24px' : '16px')};

  border-radius: 8px;
  background-color: #edf5ea;
  border: 1px solid #b0c5ac;

  h1 {
    font-weight: 600;
    font-size: 17px;
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #002106;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
    color: #526350;
  }

  a {
    color: inherit;
  }

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: unset;
    gap: 16px;

    width: unset;
    padding: 32px 24px;
  }
`;

const ShareContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-top: 0px;

  h2 {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    color: #0f1f10;
  }

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    margin-top: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 9px;
  }
`;

const ButtonAndHelp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;

  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #3a5b21;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;
