import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { effectiveMaxWidth, effectiveMobileMaxWidth, padding } from '../../shared/src/ui/Constants';
import { PastContestRow } from './PastContestRow';
import { ActiveContestRow } from './ActiveContestRow';
import { MobilePastContestRow } from './MobilePastContestRow';
import { ContestsResponseWithId, useContests } from '../../hooks/useContests';
import { useSeries } from '../../hooks/useSeries';
import { SeriesCard, SeriesCardInfo } from '../../shared/src/ui/SeriesCard';
import { Series } from '../../shared/src/Series';

// Function components

interface Props {
  contests?: ContestsResponseWithId;
}

export const AllContests: FC<Props> = ({ contests }) => {
  document.title = 'Past Contests - Apollo DAO Creator Contest';
  const _contests = useContests(contests);
  const series = useSeries();

  return (
    <StyledAllContests>
      <PageSection title="Series">
        <SeriesContainer>
          {series.map((_series) => (
            <SeriesCard key={_series._id} {...seriesCardInfoFromSeries(_series)} />
          ))}
        </SeriesContainer>
      </PageSection>
      <PageSection title="Contests">
        <ContestsContainer>
          {_contests?.activeContest && <ActiveContestRow {..._contests.activeContest} />}
          {_contests?.pastContests.map((summary) => (
            <div key={summary.contestNumber}>
              <PastContestRow {...summary} />
              <MobilePastContestRow {...summary} />
            </div>
          ))}
        </ContestsContainer>
      </PageSection>
    </StyledAllContests>
  );
};

interface PageSectionProps {
  title: string;
}

const PageSection: FC<PropsWithChildren<PageSectionProps>> = ({ title, children }) => (
  <StyledPageSection>
    <h1>{title}</h1>
    {children}
  </StyledPageSection>
);

const seriesCardInfoFromSeries = (series: Series): SeriesCardInfo => {
  const isCompleted = series.endDate < Date.now() / 1000;
  return {
    ...series,
    state: isCompleted ? 'complete' : 'active',
  };
};

// Styled components

const StyledAllContests = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 104px;

  margin: auto;
  max-width: ${effectiveMaxWidth()}px;
  padding: 64px ${padding}px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    gap: 57px;
    padding: 40px ${padding}px;
  }
`;

const StyledPageSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 40px;

  & > h1 {
    margin: 0px;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #002106;
  }

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    gap: 25px;

    & > h1 {
      font-size: 24px;
      line-height: 29px;
    }
  }
`;

const ContestsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
`;

const SeriesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  overflow-x: auto;
  gap: 32px;
`;
