import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ContestWithCreators, CreatorSummary } from '../../shared/src/ContestSummary';
import { effectiveMobileMaxWidth } from '../../shared/src/ui/Constants';
import { ProfilePic } from '../ProfilePic';

export const ActiveContestRow: FC<ContestWithCreators> = ({ name, startDate, endDate, creators }) => {
  const navigate = useNavigate();
  return (
    <Container onClick={() => navigate('/')}>
      <LeadingColumn>
        <IndicatorAndTitleContainer>
          <ActiveIndicator />
          <h1>{name}</h1>
        </IndicatorAndTitleContainer>

        <DateAndInfoContainer>
          <h2>{contestTimeRangeString(startDate, endDate)}</h2>
          <p>Visit the current contest page to browse candidates and vote!</p>
        </DateAndInfoContainer>
      </LeadingColumn>

      <TrailingColumn>
        <h2>Creators</h2>
        <CreatorImages>
          {creators.map((creator) => (
            <CreatorGridItem key={creator.address} {...creator} />
          ))}
        </CreatorImages>
      </TrailingColumn>
    </Container>
  );
};

const ActiveIndicator: FC = () => (
  <StyledActiveIndicator>
    <IndicatorDot />
    <p>Active contest</p>
  </StyledActiveIndicator>
);

const CreatorGridItem: FC<CreatorSummary> = ({ address, name, profilePictureURL }) => (
  <a onClick={(event) => event.stopPropagation()} href={`/profile/${address}`}>
    <ProfilePic key={address} name={name} profilePictureURL={profilePictureURL} width="48px" />
  </a>
);

// Helper functions

export const contestTimeRangeString = (startDate: number | null | undefined, endDate: number): string => {
  // Some old records may not have a start date
  const adjustedStartDate = startDate ?? endDate - 600;

  const _startDate = new Date(adjustedStartDate * 1000);
  const _endDate = new Date(endDate * 1000);
  const startMonth = _startDate.toLocaleDateString('en-US', { month: 'long' });
  const endMonth = _endDate.toLocaleDateString('en-US', { month: 'long' });
  if (startMonth === endMonth) {
    const year = _startDate.toLocaleDateString('en-US', { year: 'numeric' });
    const startDay = _startDate.toLocaleDateString('en-US', { day: 'numeric' });
    const endDay = _endDate.toLocaleDateString('en-US', { day: 'numeric' });
    return `${startMonth} ${startDay}-${endDay}, ${year}`;
  } else {
    const startString = _startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    const endString = _endDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    return `${startString} - ${endString}`;
  }
};

// Styled components

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 24px;
  padding: 32px;

  background-color: #ffffff;
  border: 2px solid #00dc55;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    flex-direction: column;
    align-items: stretch;
    justify-content: unset;
    padding: 24px 16px;
  }
`;

const LeadingColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 4;
  gap: 8px;
  max-width: 454px;
`;

const IndicatorAndTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  h1 {
    margin: 0;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: #002106;
  }

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    flex-direction: row-reverse;
    align-items: center;

    h1 {
      font-size: 20px;
      line-height: 24px;
    }
  }
`;

const DateAndInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  h2 {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #002106;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;

    // Limit number of lines to 2
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    gap: 16px;

    p {
      font-size: 13px;
      line-height: 18px;
    }
  }
`;

const StyledActiveIndicator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
  padding: 2px 10px;
  background-color: #ecfdf3;
  border-radius: 16px;

  p {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    color: #027a48;
  }

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    p {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const IndicatorDot = styled.span`
  background-color: #12b76a;
  width: 6px;
  height: 6px;
  border-radius: 50%;
`;

const TrailingColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  min-width: 318px;
  max-width: 318px;

  h2 {
    margin: 0;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #002106;
  }

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    min-width: unset;
  }
`;

const CreatorImages = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 48px);
  gap: 6px;
  row-gap: 6px;

  a {
    width: 48px;
    height: 48px;
  }
`;
