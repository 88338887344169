import { EIP712SignerType } from './EIP712Signer';
import { VoteMessage } from './VotePayload';
import { ProfileSignedMessage } from './ProfilePayload';
import { AdminSignedMessage } from './Queue';

export const voteMessageTypeFields: EIP712SignerType<VoteMessage>[] = [
  { name: 'voter', type: 'address' },
  { name: 'nominee', type: 'address' },
  { name: 'cycleId', type: 'uint256' },
  { name: 'apolloBalance', type: 'uint256' },
  { name: 'voteDate', type: 'uint256' },
];

// Types used when signing and verifying the vote IPFS message
export const voteMessageTypes: { [key: string]: EIP712SignerType<VoteMessage>[] } = {
  Vote: voteMessageTypeFields,
};

export const profileMessageTypeFields: EIP712SignerType<ProfileSignedMessage>[] = [
  { name: 'address', type: 'address' },
  { name: 'name', type: 'string' },
  { name: 'date', type: 'uint256' },
];

// Types used when signing and verifying the Profile message
export const profileMessageTypes: { [key: string]: EIP712SignerType<ProfileSignedMessage>[] } = {
  Profile: profileMessageTypeFields,
};

export const adminMessageTypeFields: EIP712SignerType<AdminSignedMessage>[] = [{ name: 'address', type: 'address' }];

// Types used when signing and verifying the Admin message
export const adminMessageTypes: { [key: string]: EIP712SignerType<AdminSignedMessage>[] } = {
  Admin: adminMessageTypeFields,
};
