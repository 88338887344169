import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

// Track page views through Google Analytics
//
// Equivolent to:
//
// ga("send", {hitType: "pageview", page: "${pathname}"})
export const useGAPageViewTracking = (): void => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);
};

// Track modal views through Google Analytics
//
// Equivolent to:
//
// ga("send", "pageview", "/modal/${pathname}/${modal}")
export const useGAModalViewTracking = (isShown: boolean, modalName: string | null): void => {
  useEffect(() => {
    if (isShown && modalName != null) {
      ReactGA.modalview(modalName);
    }
  }, [isShown, modalName]);
};

// Typesafe approach to events inspired by this blog post:
// https://kellenfujimoto.com/posts/strongly-typed-google-analytics-events/
export const voteCastSuccessEvent = () =>
  ({
    category: 'Contest',
    action: 'Vote Success',
  } as const);

export const voteCastFailureEvent = () =>
  ({
    category: 'Contest',
    action: 'Vote Failure',
  } as const);

export const clickedTXEvent = () =>
  ({
    category: 'Contest',
    action: 'Clicked TX Event',
  } as const);

export const clickedIPFSEvent = () =>
  ({
    category: 'Contest',
    action: 'Clicked IPFS Event',
  } as const);

export const connectedWalletEvent = () =>
  ({
    category: 'Web3',
    action: 'Connected wallet',
  } as const);

export const nominationSuccessEvent = () =>
  ({
    category: 'Web3',
    action: 'Nomination Success',
  } as const);

export const nominationFailureEvent = () =>
  ({
    category: 'Web3',
    action: 'Nomination Failure',
  } as const);

// Voting events

export const clickedWalletButtonFromVoteOptionsEvent = () =>
  ({
    category: 'Voting',
    action: 'Clicked Wallet Button From Vote Options',
  } as const);

export const clickedSendLinkFromEmailModalEvent = () =>
  ({
    category: 'Voting',
    action: 'Clicked Send Email Link',
  } as const);

export const didWatchVideoToEndEvent = () =>
  ({
    category: 'Voting',
    action: 'Did Watch Video To End',
  } as const);

export const clickedContinueAfterWatchingVideoEvent = () =>
  ({
    category: 'Voting',
    action: 'Clicked Continue After Watching Video',
  } as const);

export type AnalyticEvents =
  | ReturnType<typeof voteCastSuccessEvent>
  | ReturnType<typeof voteCastFailureEvent>
  | ReturnType<typeof clickedTXEvent>
  | ReturnType<typeof clickedIPFSEvent>
  | ReturnType<typeof connectedWalletEvent>
  | ReturnType<typeof nominationSuccessEvent>
  | ReturnType<typeof nominationFailureEvent>
  | ReturnType<typeof clickedWalletButtonFromVoteOptionsEvent>
  | ReturnType<typeof clickedSendLinkFromEmailModalEvent>
  | ReturnType<typeof didWatchVideoToEndEvent>
  | ReturnType<typeof clickedContinueAfterWatchingVideoEvent>;

export const trackEvent = (event: AnalyticEvents): void => {
  ReactGA.event(event);
};
