import { FC } from 'react';
import styled from 'styled-components';
import trophySVG from '../svg/trophy.svg';
import lockSVG from '../svg/new-lock.svg';
import boltSVG from '../svg/new-bolt.svg';
import voteTicketSVG from '../svg/vote-ticket.svg';
import { PrizeDetails } from '../PrizeDetails';
import { isPrizeBonusLocked, DisplayValues, PrizeTier, convertNullablePrizeDetailsToDisplayValues } from './prizeViewModel';
import { effectiveMobileMaxWidth } from './Constants';

interface Props {
  prizeDetails: PrizeDetails | null;
  isWalletConnected: boolean;
  hasVoted: boolean;
}

export const NewPrizeInfo: FC<Props> = ({ prizeDetails, isWalletConnected, hasVoted }) => {
  const displayValues = convertNullablePrizeDetailsToDisplayValues(prizeDetails);
  const showBonus = prizeDetails?.usdPrizeOverrides == null || prizeDetails.usdPrizeOverrides.length == 0;
  return (
    <Container>
      <PrizeWalletBox>
        <h2>Prize Wallet</h2>
        <h1>{displayValues.totalUSDPrize}</h1>
        <h3>{displayValues.totalApolloPrize}</h3>
      </PrizeWalletBox>
      <PrizeDetailsBox>
        <PrizesSection prizeTiers={displayValues.prizeTiers} />

        {showBonus && (
          <>
            <LineSeparator />
            <BonusSection prizeDetails={prizeDetails} displayValues={displayValues} />
          </>
        )}

        <VoteSection isWalletConnected={isWalletConnected} hasVoted={hasVoted} />
      </PrizeDetailsBox>
    </Container>
  );
};

interface PrizesSectionProps {
  prizeTiers: PrizeTier[];
}

const PrizesSection: FC<PrizesSectionProps> = ({ prizeTiers }) => (
  <DetailsSectionBox>
    <img src={trophySVG} alt="Trophy" />
    <DetailsSectionTitleAndContent>
      <DetailsSectionTitleLabels>
        <h1>Prizes</h1>
      </DetailsSectionTitleLabels>

      <PrizesBreakdown>
        <PrizesBreakdownLeftColumn>
          {prizeTiers.map((tier) => (
            <p key={tier.label}>{tier.label}</p>
          ))}
        </PrizesBreakdownLeftColumn>
        <PrizesBreakdownRightColumn>
          {prizeTiers.map((tier) => (
            <p key={tier.label}>{tier.value}</p>
          ))}
        </PrizesBreakdownRightColumn>
      </PrizesBreakdown>
    </DetailsSectionTitleAndContent>
  </DetailsSectionBox>
);

interface BonusSectionProps {
  prizeDetails: PrizeDetails | null;
  displayValues: DisplayValues;
}

const BonusSection: FC<BonusSectionProps> = ({ prizeDetails, displayValues }) => {
  const isLocked = isPrizeBonusLocked(prizeDetails);
  const minVotesForBonus = prizeDetails?.minVotesForBonus;

  const imgSrc = isLocked ? lockSVG : boltSVG;
  const altText = isLocked ? 'Locked' : 'Activated';
  const unlockText = minVotesForBonus != null ? `Bonus unlocks at ${minVotesForBonus} votes` : 'Bonus unlocks soon';
  const captionText = isLocked ? unlockText : 'Bonus Activated';
  return (
    <DetailsSectionBox>
      <img src={imgSrc} alt={altText} />
      <DetailsSectionTitleAndContent>
        <DetailsSectionTitleLabels>
          <h1>Bonus</h1>
          <h2>{captionText}</h2>
        </DetailsSectionTitleLabels>
        <BonusInfoBox>
          <StyledProgress value={displayValues.progressValue} max={displayValues.progressMax} />
          <BonusLabelsContainer>
            <BonusLabels>
              <h3>Base Prize</h3>
              <h4>{displayValues.baseUSDPrize}</h4>
            </BonusLabels>
            <BonusLabels>
              <h3>Max bonus</h3>
              <h4>{displayValues.maxUSDPrize}</h4>
            </BonusLabels>
          </BonusLabelsContainer>
        </BonusInfoBox>
      </DetailsSectionTitleAndContent>
    </DetailsSectionBox>
  );
};

interface VoteSectionProps {
  isWalletConnected: boolean;
  hasVoted: boolean;
}

const VoteSection: FC<VoteSectionProps> = ({ isWalletConnected, hasVoted }) => {
  // Until the full design is implemented, this just returns null
  return null;

  if (isWalletConnected === false) {
    return (
      <>
        <LineSeparator />
        <ConnectToVoteBox />
      </>
    );
  } else if (hasVoted === false) {
    return (
      <>
        <LineSeparator />
        <VoteInfoBox />
      </>
    );
  } else {
    return null;
  }
};

const ConnectToVoteBox: FC = () => (
  <StyledConnectToVoteBox>
    <ConnectToVoteButton>Connect wallet to vote</ConnectToVoteButton>
    <p>
      Need help connecting wallet?{' '}
      <a href="https://docs.apollocrypto.org/apollo-docs/creator-contest/for-voters/how-to-vote" target="_blank" rel="noreferrer">
        Help docs
      </a>
    </p>
  </StyledConnectToVoteBox>
);

const VoteInfoBox: FC = () => (
  <StyledVoteInfoBox>
    <img src={voteTicketSVG} alt="Vote" />
    <p>You have 1 vote available</p>
  </StyledVoteInfoBox>
);

// Styled components

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  max-width: 375px;
  min-width: 293px;
  flex-grow: 1;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    max-width: unset;
  }
`;

const PrizeWalletBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 24px;
  gap: 0px;
  z-index: 1;

  border-radius: 8px;
  background-color: #ffffff;

  filter: drop-shadow(0px 33px 36px rgba(0, 0, 0, 0.07)) drop-shadow(0px 16.7062px 15.6938px rgba(0, 0, 0, 0.04725))
    drop-shadow(0px 6.6px 5.85px rgba(0, 0, 0, 0.035)) drop-shadow(0px 1.44375px 2.08125px rgba(0, 0, 0, 0.02275));

  // "Prize Wallet"
  h2 {
    margin: 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
    color: #000000;
  }

  // "$5,000"
  h1 {
    margin: 0;
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.03em;
    font-feature-settings: 'kern' off;
    color: #000000;
  }

  // "318.9M APOLLO"
  h3 {
    margin: 8px 0 0 0;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    font-feature-settings: 'salt' on;
    color: #000000;
    opacity: 0.5;
  }
`;

const PrizeDetailsBox = styled.div`
  position: absolute;
  top: calc(100% + 16px);
  left: 0px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
  padding: 24px 16px;
  z-index: 1;

  background: #ffffff;
  box-shadow: 32.9036px 94.4317px 80px rgba(0, 0, 0, 0.07), 21.3264px 61.2058px 46.8519px rgba(0, 0, 0, 0.0531481),
    12.674px 36.3737px 25.4815px rgba(0, 0, 0, 0.0425185), 6.58071px 18.8864px 13px rgba(0, 0, 0, 0.035),
    2.68103px 7.69444px 6.51852px rgba(0, 0, 0, 0.0274815), 0.609325px 1.74874px 3.14815px rgba(0, 0, 0, 0.0168519);
  border-radius: 8px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    position: initial;
  }
`;

// With icon, title, and content
const DetailsSectionBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`;

const DetailsSectionTitleAndContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  flex-grow: 1;

  // The default min-width for flex items is 'auto', so this fixes an issue where items can outgrow their container.
  // More info: https://stackoverflow.com/questions/36230944/prevent-flex-items-from-overflowing-a-container
  min-width: 0;
`;

const DetailsSectionTitleLabels = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  h1 {
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  h2 {
    margin: 0;
    font-weight: 500;
    font-size: 10px;
    line-height: 100%;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #000000;
    opacity: 0.5;
  }
`;

const LineSeparator = styled.div`
  height: 1px;
  background: #000000;
  opacity: 0.1;
`;

const PrizesBreakdown = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const PrizesBreakdownLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  p {
    margin: 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 130%;
    color: #000000;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const PrizesBreakdownRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  min-width: 0;

  p {
    margin: 0;
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    color: #000000;
    opacity: 0.7;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    max-width: 100%;
  }
`;

const BonusInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
`;

const BonusLabelsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
`;

const BonusLabels = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;

  h3 {
    margin: 0;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: #000000;
    opacity: 0.7;
  }

  h4 {
    margin: 0;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on, 'salt' on;
    color: #000000;
    opacity: 0.5;
  }
`;

const StyledProgress = styled.progress`
  -webkit-appearance: none;
  appearance: none;
  height: 6px;
  width: 100%;

  &[value]::-webkit-progress-bar {
    background: #f4f4f4;
    border-radius: 999px;
  }

  &[value]::-webkit-progress-value {
    background: #00dc55;
    border-radius: 999px;
  }
`;

const StyledConnectToVoteBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;

  p {
    margin: 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    letter-spacing: -0.01em;
    color: #000000;
    opacity: 0.8;
  }

  a {
    color: #000000;
    text-decoration: underline;
  }
`;

const ConnectToVoteButton = styled.button`
  background: #0185e3;
  border-radius: 4px;
  border: none;

  padding: 16px;
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #ffffff;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;

const StyledVoteInfoBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 24px;

  background: #eff6ec;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 6px;

  p {
    margin: 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 130%;
    color: #000000;
  }
`;
