import { FC } from 'react';
import styled from 'styled-components';
import twitterIcon from '../svg/twitter-button-icon.svg';
import { effectiveMobileMaxWidth } from './Constants';

interface Props {
  imageURL?: string;
  contestantName: string;
  contestantAddress: string;
}

export const YouVotedModalContent: FC<Props> = ({ imageURL, contestantName, contestantAddress }) => {
  const tweetText = `I voted for ${contestantName}. ${contestantName} is competing to win funds to support their creative work. Check out the profile below and give them a vote.\nhttps://app.apollocrypto.org/profile/${contestantAddress}`;
  const tweetURL = twitterURL(tweetText);
  return (
    <Container>
      <ProfileImage src={imageURL} alt={contestantName} />
      <TextAndButtonContainer>
        <h1>Nice! You voted for {contestantName}</h1>
        <p>Thanks for voting. Now give your contestant the upper hand by sharing your vote with the world.</p>
        <TwitterButton onClick={() => window.open(tweetURL, '_blank')}>
          <img src={twitterIcon} alt="Twitter" />
          <p>Share on Twitter</p>
        </TwitterButton>
      </TextAndButtonContainer>
    </Container>
  );
};

const twitterURL = (text: string): string => `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 8px 40px;
  background-color: #ffffff;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    padding: 8px 24px;
  }
`;

const ProfileImage = styled.img`
  width: 88px;
  height: 88px;
  border-radius: 44px;
`;

const TextAndButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  & > h1 {
    margin: 0;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #002106;
  }

  & > p {
    margin: 0;
    padding: 0px 40px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #72796f;
  }
`;

const TwitterButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 14px;

  border: 1px solid #e1e5e8;
  border-radius: 6px;

  & > p {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #002106;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;
